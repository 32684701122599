import Entity from '../Entity'
import User from './User'
import Stage from './pipeline/Stage'
import SubStage from './pipeline/SubStage'
export default class Activity extends Entity {
  constructor(data) {
    super(data.id)

    this.date_start = data.date_start
    this.description = data.description
    this.marked_as_done_time = data.marked_as_done_time
    this.name = data.name
    this.me_prevenir = data.me_prevenir
    this.terminer = data.terminer ? 1 : 0
    this.type = data.type
    this.object = 'Activity'
    if (data.created_by) {
      if (data.created_by.data) {
        this.created_by = User.create(data.created_by.data)
      } else {
        this.created_by = User.create(data.created_by)
      }
    }
    if (data.user) {
      if (data.user.data) {
        this.user = User.create(data.user.data)
      } else {
        this.user = User.create(data.user)
      }
    }
    if (data.stade_name && data.stade_id) {
      if (data.stade_name == null || data.stade_id == null) {
        this.cstage = Stage.create({ id: -1, name: 'auccun' })
      } else {
        this.cstage = Stage.create({ id: data.stade_id, name: data.stade_name })
      }
    } else {
      if (data.cstage) {
        this.cstage = Stage.create(data.cstage)
      } else {
        this.cstage = Stage.create({ id: -1, name: 'auccun' })
      }
    }
    if (data.sous_stade_name && data.sous_stade_id) {
      if (data.sous_stade_name == null || data.sous_stade_id == null) {
        this.csubstage = Stage.create({ id: -1, name: 'auccun' })
      } else {
        this.csubstage = SubStage.create({
          id: data.sous_stade_id,
          name: data.sous_stade_name
        })
      }
    } else {
      if (data.csubstage) {
        this.csubstage = SubStage.create(data.csubstage)
      } else {
        this.csubstage = SubStage.create({ id: -1, name: 'auccun' })
      }
    }
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Activity::create: "data" is undefined')
    }
    return new Activity(data)
  }
  updateActivity(data) {
    this.date_start = data.date_start
    this.description = data.description
    this.marked_as_done_time = data.marked_as_done_time
    this.name = data.name
    this.me_prevenir = data.me_prevenir
    this.terminer = data.terminer ? 1 : 0
    this.type = data.type
    if (data.created_by) {
      this.created_by = User.create(data.created_by.data)
    }
    if (data.user) {
      this.user = User.create(data.user.data)
    }
    if (data.stade_name && data.stade_id) {
      if (data.stade_name == null || data.stade_id == null) {
        this.cstage = Stage.create({ id: -1, name: 'auccun' })
      } else {
        this.cstage = Stage.create({ id: data.stade_id, name: data.stade_name })
      }
    } else {
      if (data.cstage) {
        this.cstage = Stage.create(data.cstage)
      } else {
        this.cstage = Stage.create({ id: -1, name: 'auccun' })
      }
    }
    if (data.sous_stade_name && data.sous_stade_id) {
      if (data.sous_stade_name == null || data.sous_stade_id == null) {
        this.csubstage = Stage.create({ id: -1, name: 'auccun' })
      } else {
        this.csubstage = SubStage.create({
          id: data.sous_stade_id,
          name: data.sous_stade_name
        })
      }
    } else {
      if (data.csubstage) {
        this.csubstage = SubStage.create(data.csubstage)
      } else {
        this.csubstage = SubStage.create({ id: -1, name: 'auccun' })
      }
    }
  }
}

import Entity from '../Entity'

export default class TypePixel extends Entity {
  constructor(data) {
    super(data.id)
    // this.id_subtype = data.id_subtype
    if (data.injected_to_pixel) {
      this.injected_to_pixel = data.injected_to_pixel
    }
    // this.name_subtype = data.name_subtype
    if (data.passerelle_status) {
      this.passerelle_status = data.passerelle_status
    }
    this.pixel_id = data.pixel_id
    this.name = data.name
    if (data.pixel_projet_id) {
      this.pixel_projet_id = data.pixel_projet_id
    }
    if (data.pixel_type) {
      this.pixel_type = data.pixel_type
    }
    if (data.pixel_type_id) {
      this.pixel_type_id = data.pixel_type_id
    }
    if (data && data.pixel_projet_id && data.pixel_id) {
      this.colorButton = true
    } else {
      this.colorButton = false
    }
    this.time = data.time
    this.user = data.user
    this.visible = false
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('TypePixel::create: "data" is undefined')
    }
    return new TypePixel(data)
  }
  update(data) {
    // this.id_subtype = data.id_subtype
    // this.name_subtype = data.name_subtype
    this.name = data.name
    this.pixel_id = data.pixel_id
    if (data.injected_to_pixel) {
      this.injected_to_pixel = data.injected_to_pixel
    }
    if (data.passerelle_status) {
      this.passerelle_status = data.passerelle_status
    }
    if (data.pixel_type) {
      this.pixel_type = data.pixel_type
    }
    if (data.pixel_projet_id) {
      this.pixel_projet_id = data.pixel_projet_id
    }
    if (data.pixel_type_id) {
      this.pixel_type_id = data.pixel_type_id
    }
    this.time = data.time
    this.user = data.user
  }
}

import Entity from '../../Entity'
import PixelType from '../PixelType'

export default class SubStage extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.order = data.order
    this.status = data.status
    this.color = data.color
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.is_pixel = data.is_pixel
    this.deleted_at = data.deleted_at == null ? 1 : 0
    this.abondan = data.abondan
    if (
      data.pixelTypes &&
      data.pixelTypes.data &&
      data.pixelTypes.data.length
    ) {
      this.substage_pixel_id = data.pixelTypes.data.map(pixel =>
        PixelType.create(pixel)
      )
      this.colorIdPixel = false
    } else {
      this.colorIdPixel = true
    }
    this.sms_template_id = null
    this.email_template_id = null
    this.csubstage_cible_id = null
    if (data.sms_template && data.sms_template.id) {
      this.sms_template_id = data.sms_template.id
    }
    if (data.email_template && data.email_template.id) {
      this.email_template_id = data.email_template.id
    }
    if (data.cible && data.cible.id) {
      this.csubstage_cible_id = data.cible.id
    }
  }

  /**
   * @param { id, name, order } data
   */
  static create(data) {
    if (!data) {
      throw new Error('SubStage::create: "data" is undefined')
    }
    return new SubStage(data)
  }

  update(data) {
    this.name = data.name
    this.order = data.order
    this.status = data.status
    this.color = data.color
    this.has_email = data.has_email
    this.has_sms = data.has_sms
    this.is_pixel = data.is_pixel
    this.deleted_at = data.deleted_at == null ? 1 : 0
    this.abondan = data.abondan
    if (
      data.pixelTypes &&
      data.pixelTypes.data &&
      data.pixelTypes.data.length
    ) {
      this.substage_pixel_id = data.pixelTypes.data.map(pixel =>
        PixelType.create(pixel)
      )
      this.colorIdPixel = false
    } else {
      this.colorIdPixel = true
    }
    this.projectsCount = data.projet_count
    if (data.sms_template && data.sms_template.id) {
      this.sms_template_id = data.sms_template.id
    }
    if (data.email_template && data.email_template.id) {
      this.email_template_id = data.email_template.id
    }
    if (data.cible && data.cible.id) {
      this.csubstage_cible_id = data.cible.id
    }
  }
}

import Axios from '@/axios'
import domain from '@/environment'
import TypePixel from '../../models/crm/TypePixel'

const state = {
  typePixelError: false,
  typePixelLoading: false,
  listTypePixel: []
}
const getters = {
  getTypePixelLoading: state => state.typePixelLoading,
  getTypePixelError: state => state.typePixelError,
  getListeTypePixel: state => state.listTypePixel
}
const mutations = {
  SET_TYPE_PIXEL_ERROR(state, payload = null) {
    state.typePixelError = payload
  },
  SET_TYPE_PIXEL_LOADING(state, payload = false) {
    state.typePixelLoading = payload
  },
  SET_TYPE_PIXEL(state, typePixel) {
    state.listTypePixel = typePixel.map(pixel => TypePixel.create(pixel))
  },
  DELETE_TYPE_PIXEL(state, payload) {
    state.listTypePixel = state.listTypePixel.filter(pixelType => {
      return pixelType.id != payload.id
    })
  },
  UPDATE_TYPE_PIXEL(state, { response, typePixel }) {
    typePixel.update(response)
  },
  ADD_TYPE_PIXEL(state, payload) {
    state.listTypePixel.push(TypePixel.create(payload.response))
  }
}
const actions = {
  async fetchListTypePixel({ commit }, payload) {
    commit('SET_TYPE_PIXEL_LOADING', true)
    commit('SET_TYPE_PIXEL_ERROR')
    try {
      const params = {}
      if (payload && payload.sort_by) {
        params.sort_by = payload.sort_by
      }
      if (payload && payload.sort_by_desc) {
        params.sort_by_desc = payload.sort_by_desc
      }
      if (payload && payload.full_name) {
        params.full_name = payload.full_name
      }
      const response = await Axios.get(domain + '/api/pixel-types', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_TYPE_PIXEL', response.data.data)
      commit('SET_TYPE_PIXEL_LOADING')
    } catch (error) {
      commit('SET_TYPE_PIXEL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_PIXEL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_PIXEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addTypePixel({ commit }, payload) {
    commit('SET_TYPE_PIXEL_LOADING', true)
    commit('SET_TYPE_PIXEL_ERROR')
    const body = {
      name: payload.name,
      pixel_id: payload.pixel_id
    }
    try {
      const response = await Axios.post(domain + '/api/pixel-types', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_TYPE_PIXEL', {
        typePixel: payload,
        response: response.data.data
      })
      commit('SET_TYPE_PIXEL_LOADING')
    } catch (error) {
      commit('SET_TYPE_PIXEL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_PIXEL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_PIXEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateTypePixel({ commit }, payload) {
    commit('SET_TYPE_PIXEL_LOADING', true)
    commit('SET_TYPE_PIXEL_ERROR')
    const body = {
      name: payload.name,
      pixel_id: payload.pixel_id
    }
    try {
      const response = await Axios.put(
        domain + '/api/pixel-types/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_TYPE_PIXEL', {
        typePixel: payload,
        response: response.data.data
      })
      commit('SET_TYPE_PIXEL_LOADING')
    } catch (error) {
      commit('SET_TYPE_PIXEL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_PIXEL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_PIXEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteTypePixel({ commit }, payload) {
    commit('SET_TYPE_PIXEL_LOADING', true)
    commit('SET_TYPE_PIXEL_ERROR')
    try {
      await Axios.delete(domain + '/api/pixel-types/' + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('DELETE_TYPE_PIXEL', payload)
      commit('SET_TYPE_PIXEL_LOADING')
    } catch (error) {
      commit('SET_TYPE_PIXEL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TYPE_PIXEL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TYPE_PIXEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorTypePixel({ commit }) {
    commit('SET_TYPE_PIXEL_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

import Axios from '@/axios'
import HistoryUsers from '../../models/crm/HistoryUsers'
import domain from '@/environment'
let request = null
const state = {
  historyUsersError: null,
  historyUsersLoading: false,
  listHistoryUsers: [],
  countPagination: 1
}
const getters = {
  getHistoryUsersError: state => state.historyUsersError,
  getHistoryUsersLoading: state => state.historyUsersLoading,
  getlistHistoryUsers: state => state.listHistoryUsers,
  countPaginationUser: state => state.countPagination
}
const mutations = {
  SET_HISTORY_USERS_EXCEL_LOADING(state, payload = false) {
    state.historyUsersLoading = payload
  },
  SET_HISTORY_USERS_EXCEL_ERROR(state, payload = null) {
    state.historyUsersError = payload
  },
  SET_LIST_HISTORY_USERS_EXCEL(state, payload) {
    state.listHistoryUsers = payload.data.map(item => HistoryUsers.create(item))
    state.countPagination = payload.total
  }
}
const actions = {
  async fetchListHistoryUsers({ commit }, payload) {
    commit('SET_HISTORY_USERS_EXCEL_LOADING', true)
    commit('SET_HISTORY_USERS_EXCEL_ERROR')
    const params = {
      page: payload.page,
      per_page: payload.per_page,
      sort_by_desc: 'timestamp'
    }
    if (payload.full_name) {
      params.full_name = payload.full_name
    }
    try {
      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(domain + '/api/user-historiques', {
        cancelToken: request.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_LIST_HISTORY_USERS_EXCEL', response.data)
      commit('SET_HISTORY_USERS_EXCEL_LOADING')
    } catch (error) {
      commit('SET_HISTORY_USERS_EXCEL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_HISTORY_USERS_EXCEL_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_HISTORY_USERS_EXCEL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

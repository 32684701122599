import Axios from '@/axios'
import HistoryPlanningPoseur from '../../models/crm/HistoryPlanningPoseur'
import domain from '@/environment'
let request = null
const state = {
  historyPlanningPoseurError: null,
  historyPlanningPoseurLoading: false,
  listHistoryPlanningPoseur: [],
  countPaginationPlanningPoseur: 1
}
const getters = {
  getHistoryPlanningPoseurError: state => state.historyPlanningPoseurError,
  getHistoryPlanningPoseurLoading: state => state.historyPlanningPoseurLoading,
  getListHistoryPlanningPoseur: state => state.listHistoryPlanningPoseur,
  countPaginationPlanningPoseur: state => state.countPaginationPlanningPoseur
}
const mutations = {
  SET_HISTORY_PLANNING_POSEUR_LOADING(state, payload = false) {
    state.historyPlanningPoseurLoading = payload
  },
  SET_HISTORY_PLANNING_POSEUR_ERROR(state, payload = null) {
    state.historyPlanningPoseurError = payload
  },
  SET_HISTORY_LIST_PLANNING_POSEUR(state, payload) {
    state.listHistoryPlanningPoseur = payload.data.map(item =>
      HistoryPlanningPoseur.create(item)
    )
    if (payload.meta) {
      state.countPaginationPlanningPoseur = payload.meta.pagination.total
    } else {
      state.countPaginationPlanningPoseur = 0
    }
  },
  RESET_HISTORY_PLANNING_POSEUR(state) {
    state.listHistoryPlanningPoseur = []
    state.countPaginationPlanningPoseur = 0
  }
}
const actions = {
  /** o93ed istanaha lin ijib resultat async */
  async fetchListHistoryPlanningPoseur({ commit }, payload) {
    commit('SET_HISTORY_PLANNING_POSEUR_LOADING', true)
    commit('SET_HISTORY_PLANNING_POSEUR_ERROR')
    const params = {
      optimised: payload.optimised,
      page: payload.page,
      per_page: payload.per_page
    }
    if (
      payload.technician_id &&
      payload.technician_id.value &&
      payload.technician_id.value !== 'vide'
    ) {
      params.technician_id = payload.technician_id.value
    }
    if (payload.row_index) {
      params.row_index = 'row_index_' + payload.row_index + '_on'
    }
    if (payload.day) {
      params.day = payload.day
    }
    try {
      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/historique-planning-tech',
        {
          params: params,

          cancelToken: request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_HISTORY_LIST_PLANNING_POSEUR', response.data)
      commit('SET_HISTORY_PLANNING_POSEUR_LOADING')
    } catch (error) {
      commit('SET_HISTORY_PLANNING_POSEUR_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit(
          'SET_HISTORY_PLANNING_POSEUR_ERROR',
          error.response.data.error.messages
        )
      } else {
        commit('SET_HISTORY_PLANNING_POSEUR_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetHistoryPlanningPoseur({ commit }) {
    commit('RESET_HISTORY_PLANNING_POSEUR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

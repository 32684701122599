import Entity from '../../Entity'
import ProjectType from '../ProjectType'
import Stage from './Stage'

export default class Pipeline extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.linked_to_pixel = data.linked_to_pixel
    this.categorie_name = data.categorie_name
    this.category_id = data.category_id
    this.dtype_name = data.dtype_name
    this.is_principal = data.is_principal
    this.table_category = []
    if (data.dtype) {
      let t = data.dtype
      if (!data.dtype.id) {
        t = { id: data.dtype }
      }
      this.type = ProjectType.create(t)
    } else {
      let t = {
        fields: [],
        name: undefined,
        projectsCount: null,
        subType: []
      }
      this.type = ProjectType.create(t)
    }
    this.stages = []
    if (data.cstages && data.cstages.data) {
      this.stages = data.cstages.data.map(stage => Stage.create(stage))
    }
  }

  /**
   * @param { id, name, dtype, cstages } data
   */
  static create(data) {
    if (!data) {
      throw new Error('Pipeline::create: "data" is undefined')
    }
    return new Pipeline(data)
  }

  update(data) {
    this.name = data.name
    this.linked_to_pixel = data.linked_to_pixel
    this.categorie_name = data.categorie_name
    this.category_id = data.category_id
    this.dtype_name = data.dtype_name
    this.is_principal = data.is_principal
    if (data.dtype) {
      let t = data.dtype
      if (!data.dtype.id) {
        t = { id: data.dtype }
      }
      this.type = ProjectType.create(t)
    } else {
      let t = {
        fields: [],
        name: undefined,
        projectsCount: null,
        subType: []
      }
      this.type = ProjectType.create(t)
    }
    if (data.cstages && data.cstages.data) {
      this.stages = data.cstages.data.map(stage => Stage.create(stage))
    }
  }
}

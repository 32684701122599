import Axios from 'axios'
import Categorie from '../../models/crm/categorie/Categorie'
import CategorieFile from '../../models/crm/categorie/CategorieFile'
import SubCategorie from '../../models/crm/categorie/SubCategorie'
import CategoriePJ from '../../models/crm/categorie/CategoriePJ'
import SubCategoriePJ from '../../models/crm/categorie/SubCategoriePJ'
import CustomField from '../../models/crm/CustomField'
import domain from '@/environment'
let request = null
const state = {
  categories: null,
  categoriesLoading: false,
  categoriesError: false,
  listSubType: [],
  categoriesContacts: [],
  categoriesProjects: [],
  categorieSelected: null,
  customFieldsCategorie: [],
  categoriesPieceJointeFile: [],
  subCategoriesPieceJointeFile: [],
  tableTeamsTypes: [],
  counterFilesSubcategories: []
}
const getters = {
  getCategoriesCategorie: state => state.categories,
  getCategorieLoading: state => state.categoriesLoading,
  getCategorieError: state => state.categoriesError,
  getListSubType: state => state.listSubType,
  getCategoriesContacts: state => state.categoriesContacts,
  getCategoriesProjects: state => state.categoriesProjects,
  getCustomFieldsCategorie: state => state.customFieldsCategorie,
  getCategoriesPieceJointeFile: state => state.categoriesPieceJointeFile,
  getSubCategoriesPieceJointeFile: state => state.subCategoriesPieceJointeFile,
  getTableTeamsTypes: state => state.tableTeamsTypes,
  getCounterFilesSubCategoriesPieceJointe: state =>
    state.counterFilesSubcategories
}
const mutations = {
  SET_CATEGORIE_LOADING(state, payload = false) {
    state.categoriesLoading = payload
  },
  SET_CATEGORIES_ERROR(state, payload = null) {
    state.categoriesError = payload
  },
  // fetch categorie mutations
  SET_CATEGORIES_CATEGORIES(state, { categorie }) {
    state.categories = Categorie.create(categorie)
  },
  //fetch categories contacts
  SET_CATEGORIES_CONTACTS(state, { categorie }) {
    state.categoriesContacts = categorie.map(categorie =>
      Categorie.create(categorie)
    )
  },
  //fetch categorie projects
  SET_CATEGORIES_PROJECTS(state, { categorie }) {
    state.categoriesProjects = categorie.map(categorie =>
      Categorie.create(categorie)
    )
  },
  // Categorie mutations
  ADD_CATEGORIES__CATEGORIES(state, { categorie, table_id }) {
    if (table_id.name === 'projets') {
      state.categoriesProjects.push(Categorie.create(categorie))
    } else if (table_id.name === 'contacts') {
      state.categoriesContacts.push(Categorie.create(categorie))
    }
  },
  REMOVE_CATEGORIES_CATEGORIES(state, { categorieToDelete, type }) {
    if (type === 'contacts') {
      state.categoriesContacts = state.categoriesContacts
        .filter(c => c.id != categorieToDelete.id)
        .map(c => {
          if (c.order > categorieToDelete.order) {
            c.order--
          }
          return c
        })
    }
    if (type === 'projects') {
      state.categoriesProjects = state.categoriesProjects
        .filter(c => c.id != categorieToDelete.id)
        .map(c => {
          if (c.order > categorieToDelete.order) {
            c.order--
          }
          return c
        })
    }
  },
  UPDATE_CATEGORIES_CATEGORIES(state, { categorie, data }) {
    categorie.update(data)
  },
  // subCategorie mutations
  ADD_SUB_CATEGORIES(state, { categorie, sousCategorie }) {
    categorie.subCategorie.push(SubCategorie.create(sousCategorie))
  },
  REMOVE_SUB_CATEGORIES(state, { categorie, sousCategorie }) {
    categorie.subCategorie = categorie.subCategorie
      .filter(c => c.id != sousCategorie.id)
      .map(c => {
        if (c.order > sousCategorie.order) {
          c.order--
        }
        return c
      })
  },
  UPDATE_SUB_CATEGORIES(state, { sousCategorie, data }) {
    sousCategorie.update(data)
  },
  SET_CATEGORIE_SUB_CATEGORIE(state, { categorie, sousCategories }) {
    categorie.subCategorie = sousCategories.map(c => SubCategorie.create(c))
  },
  SET_PROJECTS_SUBTYPES_LIST(state, { sousCategorie, ListSubType }) {
    sousCategorie.listSubType = ListSubType
  },
  SET_PROJECTS_SUBTYPES_LISTS(state, { ListSubType }) {
    state.listSubType = ListSubType
  },
  UPDATE_ORDER_CATEGORIE_PROJECTS(state, payload) {
    state.categoriesProjects = payload
  },
  UPDATE_ORDER_CATEGORIE_CONTACTS(state, payload) {
    state.categoriesContacts = payload
  },
  SET_ALL_CUSTOM_FIELDS_CATEGORIE(state, payload) {
    state.customFieldsCategorie = payload
  },
  UPDATE_SUB_CATEGORIES_CUSTEM_FIELD(state, { champ, data }) {
    if (data.other_header_field) {
      for (let index = 0; index < state.categories.fields.length; index++) {
        if (state.categories.fields[index].id === data.other_header_field.id) {
          state.categories.fields[index].subcategory_header = 0
        }
      }
      delete data.other_header_field
    }
    champ.update(data)
  },
  REMOVE_SUB_CATEGORIES_CUSTEM_FIELD(state, { champ }) {
    state.categories.fields = state.categories.fields
      .filter(c => c.id != champ.id)
      .map(c => {
        if (c.order > champ.order) {
          c.order--
        }
        return c
      })
  },
  SET_CUSTEM_FIELDS_SUB_CATEGORIE(state, { fields }) {
    state.categories.fields = fields.map(item => CustomField.create(item))
  },
  SET_CATEGORIES_SUB_CATEGORIE(state, { subCategorie }) {
    state.categories = SubCategorie.create(subCategorie)
  },
  UPDATE_ORDER_CUSTEM_FIELDS_SUB_CATEGORIE(state, payload) {
    state.categories.fields = payload
  },
  ADD_CUSTEM_FIELDS_SUB_CATEGORIE(state, { fields }) {
    for (let index = 0; index < fields.length; index++) {
      state.categories.fields.push(CustomField.create(fields[index]))
    }
  },
  // categories fichiers
  // fetch categorie fichiers
  ADD_CATEGORIES_FICHIERS(state, { categoriesFichiers, categorie }) {
    categoriesFichiers.push(CategorieFile.create(categorie))
  },
  REMOVE_CATEGORIES_FICHIERS(state, { categorieToDelete }) {
    if (
      this.state &&
      this.state.projects &&
      this.state.projects.categoriesFichiers &&
      this.state.projects.categoriesFichiers.length
    ) {
      this.state.projects.categoriesFichiers = this.state.projects.categoriesFichiers
        .filter(c => c.id != categorieToDelete.id)
        .map(c => {
          if (c.order > categorieToDelete.order) {
            c.order--
          }
          return c
        })
    }
  },
  UPDATE_CATEGORIES_FICHIERS(state, payload) {
    if (
      this.state &&
      this.state.projects &&
      this.state.projects.categoriesFichiers &&
      this.state.projects.categoriesFichiers.length
    ) {
      this.state.projects.categoriesFichiers = this.state.projects.categoriesFichiers.map(
        item => {
          return item.id == payload.id ? CategorieFile.create(payload) : item
        }
      )
    }
  },
  UPDATE_ORDER_CATEGORIE_FICHIERS(state, payload) {
    if (
      this.state &&
      this.state.projects &&
      this.state.projects.categoriesFichiers &&
      this.state.projects.categoriesFichiers.length
    ) {
      this.state.projects.categoriesFichiers = payload
    }
  },
  // CATEGORIES PIECE JOINTE
  SET_CATEGORIES_PIECE_JOINTE_FILE(state, categorie) {
    state.categoriesPieceJointeFile =
      categorie && categorie.length
        ? categorie.map(categorie => CategoriePJ.create(categorie))
        : []
  },
  SET_CATEGORIES_PIECE_JOINTE_ONE_FILE(state, categorie) {
    state.subCategoriesPieceJointeFile = CategoriePJ.create(categorie)
  },
  SET_NUMBERS_SUB_CATEGORIES_PIECE_JOINTE_FILE(state, payload) {
    state.counterFilesSubcategories = payload
  },
  SET_TYPES_TEAMS(state, payload) {
    state.tableTeamsTypes = payload
  },
  ADD_CATEGORIES_FILE_PJ(state, { categorie }) {
    state.categoriesPieceJointeFile.push(CategoriePJ.create(categorie))
  },
  UPDATE_ORDER_CATEGORIE_FILE_PJ(state, payload) {
    state.categoriesPieceJointeFile = payload
  },
  UPDATE_CATEGORIES_FILE_PJ(state, { categorie, data }) {
    categorie.update(data)
  },
  REMOVE_CATEGORIES_FILE_PJ(state, { categorieToDelete }) {
    state.categoriesPieceJointeFile = state.categoriesPieceJointeFile
      .filter(c => c.id != categorieToDelete.id)
      .map(c => {
        if (c.order > categorieToDelete.order) {
          c.order--
        }
        return c
      })
  },
  SET_CATEGORIE_PJ_SUB_CATEGORIE(state, { categoriePJ, subcategoriesFilePJ }) {
    categoriePJ.subCategorie = subcategoriesFilePJ.map(c =>
      SubCategoriePJ.create(c)
    )
  },
  ADD_SUB_CATEGORIES_FILE_PJ(state, { categoriePJ, subcategoriesFilePJ }) {
    categoriePJ.subCategorie.push(SubCategoriePJ.create(subcategoriesFilePJ))
  },
  REMOVE_SUB_CATEGORIES_PJ(state, { categorie, subCategorieToDelete }) {
    categorie.subCategorie = categorie.subCategorie
      .filter(c => c.id != subCategorieToDelete.id)
      .map(c => {
        if (c.order > subCategorieToDelete.order) {
          c.order--
        }
        return c
      })
  },
  UPDATE_SUB_CATEGORIES_FILE_PJ(state, payload) {
    state.subCategoriesPieceJointeFile.subCategorie = state.subCategoriesPieceJointeFile.subCategorie.map(
      item =>
        item.id == payload.data.id ? SubCategoriePJ.create(payload.data) : item
    )
  },
  MOVE_SUB_CATEGORIES_FILE_PJ(state, { categoriePJ, subcategoriesFilePJ }) {
    categoriePJ.subCategorie = subcategoriesFilePJ.sousCategories.map(c =>
      SubCategoriePJ.create(c)
    )
  }
}
const actions = {
  // fetch Categories
  async fetchCategoriesCategories({ commit }, payload) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const params = {}
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      const response = await Axios.get(domain + '/api/categories', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_CATEGORIES_CATEGORIES', { categorie: response.data.data })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOneCategorie({ commit }, idCategorie) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/categories/' + idCategorie,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CATEGORIES_CATEGORIES', { categorie: response.data.data })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Categories
  async addCategoriesCategorie({ commit }, { categorie, table_id }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    const body = {
      name: categorie.name,
      table_id: table_id.id,
      dtypes: categorie.dtypes.map(item => {
        return item.id
      })
    }
    try {
      const response = await Axios.post(
        domain + '/api/categories?include=dtypes',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_CATEGORIES__CATEGORIES', {
        categorie: response.data.data,
        table_id: table_id
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCategoriesCategorie({ commit }, { categorieToDelete, type }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      await Axios.delete(domain + '/api/categories/' + categorieToDelete.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_CATEGORIES_CATEGORIES', { categorieToDelete, type })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateCategoriesCategorie({ commit }, { categorie, data, type }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/categories/' + categorie.id + '?include=dtypes',
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('UPDATE_CATEGORIES_CATEGORIES', {
        categorie: categorie,
        data: response.data.data,
        type: type
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  //fetch Sub Categorie For Categorie
  async fetchCategoriesSubCategorie({ commit }, { selectedCategorie }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/categories/' +
          selectedCategorie.id +
          '/subcategories?include=subtype.type',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CATEGORIE_SUB_CATEGORIE', {
        categorie: selectedCategorie,
        sousCategories: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // SubCtegorie
  async addSubCategorie({ commit }, { categorie, sousCategorie }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')

    try {
      const response = await Axios.post(
        domain +
          '/api/categories/' +
          categorie.id +
          '/subcategories?include=subtype.type',
        sousCategorie,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_SUB_CATEGORIES', {
        categorie: categorie,
        sousCategorie: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteSubCategorie({ commit }, { categorie, sousCategorie }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      await Axios.delete(
        domain +
          '/api/categories/' +
          categorie.id +
          '/subcategories/' +
          sousCategorie.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_SUB_CATEGORIES', { categorie, sousCategorie })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateSubCategorie({ commit }, { categorie, sousCategorie, data }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.put(
        domain +
          '/api/categories/' +
          categorie.id +
          '/subcategories/' +
          sousCategorie.id +
          '?include=subtype',
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_SUB_CATEGORIES', {
        sousCategorie,
        data: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchFrojectSubType({ commit }, { id, sousCategorie, categorie }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/types/' + id + '/subtypes',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (sousCategorie) {
        commit('SET_PROJECTS_SUBTYPES_LIST', {
          sousCategorie: sousCategorie,
          ListSubType: response.data.data,
          categorie: categorie
        })
      } else {
        commit('SET_PROJECTS_SUBTYPES_LISTS', {
          ListSubType: response.data.data
        })
      }

      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrderCategorieProject({ commit }, payload) {
    commit('UPDATE_ORDER_CATEGORIE_PROJECTS', payload)
  },
  updateOrderCategorieContacts({ commit }, payload) {
    commit('UPDATE_ORDER_CATEGORIE_CONTACTS', payload)
  },
  async fetchCategoriesContacts({ commit }, payload) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/tables/' + payload + '/categories?include=dtypes',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CATEGORIES_CONTACTS', { categorie: response.data.data })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchCategoriesProjects({ commit }, payload) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/tables/' + payload + '/categories?include=dtypes',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CATEGORIES_PROJECTS', { categorie: response.data.data })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListChampforSubCategorie({ commit }, { type, idSubCat }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    commit('SET_ALL_CUSTOM_FIELDS_CATEGORIE', [])
    let table = []
    try {
      for (let i = 0; i < type.length; i++) {
        const response = await Axios.get(
          domain +
            '/api/subcategories/' +
            idSubCat +
            '/tables/' +
            type[i] +
            '/available-fields',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        table = table.concat(response.data)
      }

      commit('SET_ALL_CUSTOM_FIELDS_CATEGORIE', table)
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetCategorieListCustemFields({ commit }) {
    commit('SET_ALL_CUSTOM_FIELDS_CATEGORIE', [])
  },
  resetErrorCategorie({ commit }) {
    commit('SET_CATEGORIES_ERROR')
  },
  async updateCustemFieldSubCategorie(
    { commit },
    { idCategorie, champ, data }
  ) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/subcategories/' + idCategorie + '/fields/' + champ.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('UPDATE_SUB_CATEGORIES_CUSTEM_FIELD', {
        champ,
        data: response.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCustemFieldSubCategorie({ commit }, { idCategorie, champ }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      await Axios.delete(
        domain + '/api/subcategories/' + idCategorie + '/fields/' + champ.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_SUB_CATEGORIES_CUSTEM_FIELD', { champ })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fechlistFieldSubCategorie({ commit }, payload) {
    commit('SET_CUSTEM_FIELDS_SUB_CATEGORIE', { fields: [] })
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/subcategories/' + payload + '/fields',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CUSTEM_FIELDS_SUB_CATEGORIE', { fields: response.data })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOneSubCategorie(
    { commit },
    { idCategorie, idSubCategorie, include }
  ) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const params = {}

      if (include != null) {
        params.include = include
      }
      const response = await Axios.get(
        domain +
          '/api/categories/' +
          idCategorie +
          '/subcategories/' +
          idSubCategorie,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_CATEGORIES_SUB_CATEGORIE', {
        subCategorie: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addCutemFieldsToSubCategorie({ commit }, { idSubCat, ChampsToAdd }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const params = new URLSearchParams()
      for (let index = 0; index < ChampsToAdd.length; index++) {
        params.append('fields[' + index + '][field_id]', ChampsToAdd[index].id)
        params.append('fields[' + index + '][name]', ChampsToAdd[index].name)
      }

      const response = await Axios.post(
        domain + '/api/subcategories/' + idSubCat + '/fields',
        params,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      commit('ADD_CUSTEM_FIELDS_SUB_CATEGORIE', { fields: response.data })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrderCustemFieldsSubCategorie({ commit }, payload) {
    commit('UPDATE_ORDER_CUSTEM_FIELDS_SUB_CATEGORIE', payload)
  },
  //  categories fichiers
  async addCategoriesFiles({ commit }, { categoriesFichiers, categorie }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    const body = {
      name: categorie.name,
      order: categorie.order
    }
    try {
      const response = await Axios.post(
        domain + '/api/crm/category-files',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_CATEGORIES_FICHIERS', {
        categoriesFichiers: categoriesFichiers,
        categorie: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCategoriesFiles({ commit }, { categorieToDelete }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      await Axios.delete(
        domain + '/api/crm/category-files/' + categorieToDelete.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_CATEGORIES_FICHIERS', { categorieToDelete })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateCategoriesFiles({ commit }, { categorie, data }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/category-files/' + categorie.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_CATEGORIES_FICHIERS', response.data.data)
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrderCategorieFiles({ commit }, payload) {
    commit('UPDATE_ORDER_CATEGORIE_FICHIERS', payload)
  },
  // CATEGORY PIECE JOINTE FILE
  async fetchTypesEquipes({ commit }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(domain + '/api/types-teams', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_TYPES_TEAMS', response.data)
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchCategoriesPieceJointeFile({ commit }, { loading, projet_id }) {
    commit('SET_CATEGORIE_LOADING', loading)
    commit('SET_CATEGORIES_ERROR')
    try {
      const params = {
        projet_id: projet_id
      }
      const response = await Axios.get(domain + '/api/parent-category-files', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_CATEGORIES_PIECE_JOINTE_FILE', response.data.data)
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addCategoriesFilesPJ({ commit }, { categorie }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/parent-category-files',
        categorie,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_CATEGORIES_FILE_PJ', {
        categorie: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateCategoriesFilesPJ({ commit }, { categorie, data }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/parent-category-files/' + categorie.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('UPDATE_CATEGORIES_FILE_PJ', {
        categorie: categorie,
        data: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCategoriesFilePJ(
    { commit },
    { categorieToDelete, categorieToDeleteDefintive }
  ) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      await Axios.delete(
        domain +
          '/api/delete-parent-category-files/' +
          categorieToDelete.id +
          '/' +
          categorieToDeleteDefintive,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_CATEGORIES_FILE_PJ', { categorieToDelete })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOneCategoriePJ({ commit }, idCategorie) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/parent-category-files/' + idCategorie,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CATEGORIES_PIECE_JOINTE_ONE_FILE', response.data.data)
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchNumbersFilesSubCategoriePJ({ commit }, payload) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const params = {
        projet_id: payload
      }
      const response = await Axios.get(domain + '/api/parent-files', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_NUMBERS_SUB_CATEGORIES_PIECE_JOINTE_FILE', response.data)
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // SUB CATEGORIES PIECE JOINTE
  async fetchCategoriesPJSubCategorie(
    { commit },
    { selectedCategorie, loading }
  ) {
    commit('SET_CATEGORIE_LOADING', loading)
    commit('SET_CATEGORIES_ERROR')
    try {
      if (request) {
        request.cancel('Optional message')
      }
      request = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/categories-of-parent-category/' + selectedCategorie.id,
        {
          cancelToken: request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CATEGORIE_PJ_SUB_CATEGORIE', {
        categoriePJ: selectedCategorie,
        subcategoriesFilePJ: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addSubCategoriesFilesPJ(
    { commit },
    { categoriesFichiers, sousCategorie }
  ) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    const body = {
      name: sousCategorie.name,
      parent_id: sousCategorie.parent_id
      // order: sousCategorie.order
    }
    try {
      const response = await Axios.post(
        domain + '/api/crm/category-files',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_SUB_CATEGORIES_FILE_PJ', {
        categoriePJ: categoriesFichiers,
        subcategoriesFilePJ: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteSubCategoriePJ({ commit }, { categorie, subCategorieToDelete }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      await Axios.delete(
        domain + '/api/crm/category-files/' + subCategorieToDelete.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_SUB_CATEGORIES_PJ', { categorie, subCategorieToDelete })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateSubCategoriesFilesPJ({ commit }, { sousCategorie, data }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/category-files/' + sousCategorie.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_SUB_CATEGORIES_FILE_PJ', {
        sousCategorie,
        data: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async moveSubCategoriesFilesPJ({ commit }, { categoriePJ, data }) {
    commit('SET_CATEGORIE_LOADING', true)
    commit('SET_CATEGORIES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/affect-categories/' + categoriePJ.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('MOVE_SUB_CATEGORIES_FILE_PJ', {
        categoriePJ: categoriePJ,
        subcategoriesFilePJ: response.data.data
      })
      commit('SET_CATEGORIE_LOADING')
    } catch (error) {
      commit('SET_CATEGORIE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CATEGORIES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CATEGORIES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  updateOrderCategorieFilePJ({ commit }, payload) {
    commit('UPDATE_ORDER_CATEGORIE_FILE_PJ', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

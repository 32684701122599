import Axios from 'axios'
import domain from '@/environment'
const state = {
  error: null,
  processing: false,
  contacts: []
}
const getters = {
  getContactsError: state => state.error,
  getContactsProcessing: state => state.processing,
  getContactsList: state => state.contacts
}
const mutations = {
  SET_CONTACTS_PROCESSING(state, payload = false) {
    state.processing = payload
  },
  SET_CONTACTS_ERROR(state, payload = null) {
    state.error = payload
  },
  SET_CONTACTS_LIST(state, payload) {
    state.contacts = payload
  },
  CONTACTS_COMMIT_NOTHING() {},
  UPDATE_CONTACTS_CONTACT(state, payload) {
    payload.entity.update(payload.response)
    payload.entity.checked = true
    setTimeout(() => {
      for (
        let index = 0;
        index < this.state.projects.projects.length;
        index++
      ) {
        const projet = this.state.projects.projects[index]
        if (projet.id === payload.entity.id) {
          this.state.projects.projects[index].update(payload.response)
        }
      }
    })
  }
}
const actions = {
  // TODO : add filters by name
  async searchContacts({ commit }, payload) {
    commit('SET_CONTACTS_PROCESSING', true)
    commit('SET_CONTACTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/crm/contacts', {
        params: {
          full_name: payload,
          minimal: true
        },
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_CONTACTS_LIST', response.data)
      commit('SET_CONTACTS_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateContact({ commit }, payload) {
    let data = {}
    if (payload && payload.formLeadContact) {
      data = {
        first_name: payload.formLeadContact.first_name,
        last_name: payload.formLeadContact.last_name,
        emails: payload.formLeadContact.emails,
        phones: payload.formLeadContact.phones,
        zipCode: payload.formLeadContact.zipCode,
        city: payload.formLeadContact.city,
        adresse: payload.formLeadContact.adresse,
        nom_naissance: payload.formLeadContact.nom_naissance,
        city_naissance: payload.formLeadContact.city_naissance,
        date_naissance: payload.formLeadContact.date_naissance
      }
    } else {
      if (payload && payload.field) {
        data = { [payload.field]: payload.value }
      }
    }
    commit('SET_CONTACTS_PROCESSING', true)
    commit('SET_CONTACTS_ERROR')
    try {
      const response = await Axios.put(
        domain +
          `/api/crm/contacts/${payload.entity.contact_id}?project_id=${payload.entity.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_CONTACTS_CONTACT', {
        entity: payload.entity,
        response: response.data
      })

      commit('SET_CONTACTS_PROCESSING')
    } catch (error) {
      commit('SET_CONTACTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CONTACTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CONTACTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetSearchContacts({ commit }) {
    commit('SET_CONTACTS_LIST', [])
  },
  async getContactPhone({ commit }, id) {
    try {
      const response = await Axios.get(
        `${domain}/api/crm/contacts/${id}/phones`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('CONTACTS_COMMIT_NOTHING')
      if (response && response.data && response.data.data) {
        return response.data.data.map(p => p.number)
      }
      return []
    } catch (error) {
      return []
    }
  },
  resetErrorContact({ commit }) {
    commit('SET_CONTACTS_ERROR')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

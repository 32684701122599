import Axios from 'axios'
import Project from '../../models/crm/Project'
import Activity from '../../models/crm/Activity'
import History from '../../models/crm/History'
import Comment from '../../models/crm/Comment'
import TypePixel from '../../models/crm/TypePixel'
import File from '../../models/crm/File'
import CategorieFile from '../../models/crm/categorie/CategorieFile'
import CategorieFilesLength from '../../models/crm/categorie/CategorieFilesLength'
import User from '../../models/crm/User'
import domain from '@/environment'
import * as moment from 'moment'
moment.locale('fr')

const requests = {
  projects: null,
  projectDependencies: {
    activities: null,
    history: null,
    journal: null,
    files: null,
    comments: null
  },
  exportExcel: null
}

const state = {
  error: null,
  warning: null,
  errorWarningMultiModal: null,
  createError: null,
  processing: false,
  processingFiles: false,
  projects: [],
  projectsContact: [],
  extraProject: [],
  lastCreatedProject: null,
  projectFields: [],
  contactFields: [],
  // users of project
  usersCommercialSedentaire: [],
  usersCommercialSedentaireLoading: false,
  usersVvisiteurTechnique: [],
  usersVisiteurTechniqueLoading: false,
  usersCommercialsIte: [],
  usersCommercialsIteLoading: false,
  usersRespPlan: [],
  usersRespPlanLoading: false,
  usersTechniciens: [],
  usersTechniciensLoading: false,

  suggestionsProcessing: false,
  comments: [],
  projectsCommentstotalRows: 1,
  projectsActivitytotalRows: 1,
  projectsJournaltotalRows: 1,
  projectsHistoriquetotalRows: 1,
  files: [],
  filesProcessing: false,
  processingHistory: false,
  sourcesProcessing: false,
  source: [],
  campagneProcessing: false,
  campagne: [],
  campagneSource: [],
  categoriesFichiers: [],
  categoriesFichiersParCount: [],
  projectstotalRowsType: 1,
  projectstotalRowsStade: 1,
  projectstotalRows: 1,
  projectstotalRowsTH: 1,
  projectCountProcessing: true,
  depots: [],
  agence: [],
  filialeAgence: [],
  depotsCommercialTerrain: [],
  depotsTechnicien: [],
  depotsPrevisiteur: [],
  depotsProcessing: false,
  filters: [],
  routeDynamic: [],
  routeDynamicProcessing: false,
  routeDynamicProjectTH: [],
  routeDynamicProcessingProjectTH: false,
  commSed: [],
  commSedProcessing: false,
  amoCommIte: [],
  techTravaux: [],
  previsiteur: [],
  previsiteurProcessing: false,
  poseur: [],
  poseurProcessing: false,
  sources: [],
  sourceProcessing: false,
  ingenieur: [],
  ingenieurProcessing: false,
  commercial: [],
  commercialProcessing: false,
  respPlanning: [],
  respPlanningProcessing: false,
  typePixel: [],
  typePixelLoading: false,
  historyInjectionPixel: [],
  historyInjectionPixelLoading: false,
  sousTypes: [],
  commentDynamicProjectTh: [],
  changePageValue: false,
  objectPrj: null,
  fieldValueSubcategory: [],
  responseUpdateProject: false,
  responseUpdateProjectPlanning: false,
  checkDoubleProject: null,
  usersComments: [],
  types: [],
  typesRenovationLoading: false
}
const getters = {
  getProjectsError: state => state.error,
  getProjectsWarning: state => state.warning,
  getProjectsErrorWarningMultiModal: state => state.errorWarningMultiModal,
  getProjectsCreateError: state => state.createError,
  getProjectsProcessing: state => state.processing,
  getProjectCountProcessing: state => state.projectCountProcessing,
  getProjectsProcessingHistory: state => state.processingHistory,
  getProjectResponseUpdate: state => state.responseUpdateProject,
  getResponseUpdateProjectPlanning: state =>
    state.responseUpdateProjectPlanning,
  // clean
  getProjectsProjects: state => state.projects,
  getProjectsProjectsContact: state => state.projectsContact,
  getProjectsExtraProject: state => state.extraProject,
  getProjectsLastCreated: state => state.lastCreatedProject,
  getProjectsProjectFields: state => state.projectFields,
  getProjectsContactFields: state => state.contactFields,
  getProjectsComments: state => state.comments,
  getProjectsCommentsUsers: state => state.usersComments,
  getProjectsCommentstotalRows: state => state.projectsCommentstotalRows,
  getProjectsActivitytotalRows: state => state.projectsActivitytotalRows,
  getProjectsJournaltotalRows: state => state.projectsJournaltotalRows,
  getProjectsHistoriquetotalRows: state => state.projectsHistoriquetotalRows,
  getProjectsFiles: state => state.files,
  getProjectsFilesProcessing: state =>
    state.filesProcessinfieldValueSubcategoryg,
  getFieldValueSubcategory: state => state.fieldValueSubcategory,
  // Projects users getters
  getProjectsUsersCommercialSedentaireLoading: state =>
    state.usersCommercialSedentaireLoading,
  getProjectsUsersVisiteurTechnique: state => state.usersVvisiteurTechnique,
  getProjectsUsersVisiteurTechniqueLoading: state =>
    state.usersVisiteurTechniqueLoading,
  getProjectsUsersCommercialsIte: state => state.usersCommercialsIte,
  getProjectsUsersCommercialsIteLoading: state =>
    state.usersCommercialsIteLoading,
  getProjectsUsersRespPlan: state => state.usersRespPlan,
  getProjectsUsersRespPlanLoading: state => state.usersRespPlanLoading,
  getProjectsUsersTechniciens: state => state.usersTechniciens,
  getProjectsUsersTechniciensLoading: state => state.usersTechniciensLoading,

  // users types combined [userAdmin and userAgent and userAdministration]
  getProjectsUsersCommercialsSedentaires: state =>
    state.usersCommercialSedentaire,
  getProjectsUsers: state =>
    state.usersCommercialSedentaire.concat(
      state.usersCommercialsIte,
      state.usersRespPlan,
      state.usersTechniciens
    ),
  getProjectsUsersLoading: state =>
    state.usersCommercialSedentaireLoading ||
    state.usersCommercialsIteLoading ||
    state.usersRespPlanLoading ||
    state.usersTechniciensLoading,

  getProjectsSuggestionsProcessing: state => state.suggestionsProcessing,
  getProjectsSources: state => state.source,
  getProjectsSourcesProcessing: state => state.sourcesProcessing,
  getProjectsCampagnes: state => state.campagne,
  getProjectsCampagnesSources: state => state.campagneSource,
  getProjectsCampagnesProcessing: state => state.campagneProcessing,
  getProjectsCategoriesFichiers: state => state.categoriesFichiers,
  getProjectsCategoriesFichiersParCount: state =>
    state.categoriesFichiersParCount,
  getProjectsDepots: state => state.depots,
  getProjectsAgence: state => state.agence,
  getProjectsFilialeAgence: state => state.filialeAgence,
  getProjectsDepotsCommercialTerrain: state => state.depotsCommercialTerrain,
  getProjectsDepotsTechnicien: state => state.depotsTechnicien,
  getProjectsDepotsPrevisiteur: state => state.depotsPrevisiteur,
  getProjectsDepotsProcessing: state => state.depotsProcessing,
  getProjectstotalRows: state => state.projectstotalRows,
  getProjectstotalRowsTH: state => state.projectstotalRowsTH,
  getProjectstotalRowsType: state => state.projectstotalRowsType,
  getProjectstotalRowsStade: state => state.projectstotalRowsStade,
  getSaveFilters: state => state.filters,
  getProjectRouteDynamic: state => state.routeDynamic,
  getRouteDynamicProcessing: state => state.routeDynamicProcessing,
  getProjectsUsersCommercialSed: state => state.commSed,
  getProjectsUsersCommercialSedProcessing: state => state.commSedProcessing,
  getProjectsUsersAmoCommIte: state => state.amoCommIte,
  getProjectsUsersTechTravaux: state => state.techTravaux,
  getProjectsUsersPrevisiteur: state => state.previsiteur,
  getProjectsUsersPrevisiteurLoading: state => state.previsiteurProcessing,
  getProjectsUsersPoseurs: state => state.poseur,
  getProjectsUsersPoseursProcessing: state => state.poseurProcessing,
  getProjectsUsersSources: state => state.sources,
  getProjectsUsersSourcesProcessing: state => state.sourceProcessing,
  getProjectsUsersIngenieur: state => state.ingenieur,
  getProjectsUsersIngenieurProcessing: state => state.ingenieurProcessing,
  getProjectsUsersCommercial: state => state.commercial,
  getProjectsUsersCommercialProcessing: state => state.commercialProcessing,
  getProjectsUsersRespPlanning: state => state.respPlanning,
  getProjectsUsersRespPlanningLoading: state => state.respPlanningProcessing,
  getProjectsTypesPixels: state => state.typePixel,
  getProjectsTypesPixelsLoading: state => state.typePixelLoading,
  getProjectshistoryInjectionPixel: state => state.historyInjectionPixel,
  getProjectshistoryInjectionPixelLoading: state =>
    state.historyInjectionPixelLoading,
  getProjectsSousTypes: state => state.sousTypes,
  //
  getProjectRouteDynamicProjectTH: state => state.routeDynamicProjectTH,
  getRouteDynamicProcessingProjectTH: state =>
    state.routeDynamicProcessingProjectTH,
  getRouteDynamicCommentProjectTH: state => state.commentDynamicProjectTh,
  getChangePageValue: state => state.changePageValue,
  getObjectPrj: state => state.objectPrj,
  getcheckDoubleProject: state => state.checkDoubleProject,
  getProjectProcessingFiles: state => state.processingFiles,
  getProjectTypes: state => state.types,
  getProjectsTypesRenovationLoading: state => state.typesRenovationLoading
}
const mutations = {
  SET_PROJECTS_PROCESSING(state, payload = false) {
    state.processing = payload
  },
  SET_PROJECTS_FILES_PROCESSING(state, payload = false) {
    state.processingFiles = payload
  },
  SET_PROJECTS_COUNT_PROCESSING(state, payload = false) {
    state.projectCountProcessing = payload
  },
  SET_PROJECTS_HISTORY_PROCESSING(state, payload = false) {
    state.processingHistory = payload
  },
  SET_PROJECTS_WARNNING(state, payload) {
    state.warning =
      payload && payload.warning && payload.warning.messages
        ? payload.warning.messages
        : null
  },
  SET_PROJECTS_ERROR(state, payload = null) {
    state.error = payload
  },
  REMOVE_PROJECTS_WARNING(state) {
    state.warning = null
  },
  SET_PROJECTS_ERROR_WARNING_MULTI_MODAL(state, payload = null) {
    state.errorWarningMultiModal = payload
  },

  SET_PROJECTS_CREATE_ERROR(state, payload = null) {
    state.createError = payload
  },
  SET_PROJECTS_PROJECTS(state, payload) {
    state.projects = payload.map(p =>
      Project.create(p, {
        projectFields: state.projectFields,
        contactFields: state.contactFields
      })
    )
  },
  SET_CONTACT_PROJECTS(state, payload) {
    state.projectsContact = payload.map(p =>
      Project.create(p, {
        projectFields: state.projectFields,
        contactFields: state.contactFields
      })
    )
  },
  ADD_PROJECTS_EXTRA(state, { data, checked, id }) {
    if (data.header) {
      data.header.checked = checked
    } else {
      data.checked = checked
    }
    data.id = id
    state.extraProject = Project.create(data)
  },
  ADD_PROJECTS_PROJECT(state, payload) {
    const objet = new Project(payload.response)
    // if (payload.currentType.id != 112) {
    //   objet.formatedObjet(payload.response)
    // }
    if (payload.id_dtype === payload.currentType.id) {
      state.projects.unshift(objet)
    }
    state.lastCreatedProject = objet
  },
  CHECK_DOUBLE_PROJECTS_PROJECT(state, payload) {
    state.checkDoubleProject = payload
  },
  UPDATE_PROJECTS_PROJECT(state, payload) {
    if (payload && payload.response) {
      state.responseUpdateProject = true
    } else {
      state.responseUpdateProject = false
    }
    state.responseUpdateProjectPlanning = true
    if (!payload.commit) {
      payload.entity.update(payload.response, {
        projectFields: state.projectFields,
        contactFields: state.contactFields
      })
      for (
        let index = 0;
        index < this.state.projects.projects.length;
        index++
      ) {
        const projet = this.state.projects.projects[index]
        if (projet.id === payload.entity.id) {
          this.state.projects.projects[index].update(payload.response)
        }
      }
      if (payload.checked === false) {
        payload.entity.checked = false
      } else {
        payload.entity.checked = true
      }
    }
  },
  UPDATE_PROJECTS_MULTIPLE(state, payload) {
    payload.projects.forEach(item => {
      const project = payload.response.find(el => {
        return el.static.id == item.id
      })
      if (project) {
        item.update(project, {
          projectFields: state.projectFields,
          contactFields: state.contactFields
        })
      }
    })
  },
  DELETE_PROJECTS_MULTIPLE(state, payload) {
    payload.projects.forEach(project => {
      state.projects = state.projects.filter(item => item.id != project.id)
    })
  },
  REFRECH_PROJECTS_PROJECT(state, payload) {
    payload.project.update(payload.response)
  },
  REMOVE_PROJECTS_PROJECT(state, payload) {
    payload.checked = false
    if (state.extraProject && state.extraProject.id == payload.id) {
      state.extraProject = null
    }
    state.projects = state.projects.filter(item => item.id != payload.id)
  },
  // PROJECT TH
  REMOVE_PROJECT_TH(state, payload) {
    state.projects = state.projects.filter(item => item.id != payload.id)
  },
  UPDATE_PROJECT_TH(state, payload) {
    payload.entity.update(payload.response)
  },
  SET_COMMENT_DYNAMIC_PROJECT_TH(state, payload) {
    state.commentDynamicProjectTh = payload.response
  },
  SET_ADD_COMMENT_PROJECT_TH(state, payload) {
    state.commentDynamicProjectTh.unshift(payload.response)
    payload.project.ecs_comments =
      payload.response.ecs_comments == 1 ? 1 : payload.project.ecs_comments
    payload.project.rr_comments =
      payload.response.rr_comments == 1 ? 1 : payload.project.rr_comments
    payload.project.ro_comments =
      payload.response.ro_comments == 1 ? 1 : payload.project.ro_comments
    payload.project.s_comments =
      payload.response.s_comments == 1 ? 1 : payload.project.s_comments
    payload.project.d_comments =
      payload.response.d_comments == 1 ? 1 : payload.project.d_comments
    payload.project.p_comments =
      payload.response.p_comments == 1 ? 1 : payload.project.p_comments
    payload.project.r_comments =
      payload.response.r_comments == 1 ? 1 : payload.project.r_comments
    payload.project.m_comments =
      payload.response.m_comments == 1 ? 1 : payload.project.m_comments
    payload.project.ite_comments =
      payload.response.ite_comments == 1 ? 1 : payload.project.ite_comments
    payload.project.vmc_comments =
      payload.response.vmc_comments == 1 ? 1 : payload.project.vmc_comments
    payload.project.cofrac_comments =
      payload.response.cofrac_comments == 1
        ? 1
        : payload.project.cofrac_comments
    payload.project.nom_client_comments =
      payload.response.nom_client_comments == 1
        ? 1
        : payload.project.nom_client_comments
  },
  REMOVE_COMMENT_PROJECT_TH(state, payload) {
    state.commentDynamicProjectTh = state.commentDynamicProjectTh.filter(
      item => item.id != payload.response.id
    )
    payload.project.ecs_comments =
      payload.response.ecs_comments == 0 ? 0 : payload.project.ecs_comments

    payload.project.rr_comments =
      payload.response.rr_comments == 0 ? 0 : payload.project.rr_comments

    payload.project.ro_comments =
      payload.response.ro_comments == 0 ? 0 : payload.project.ro_comments

    payload.project.s_comments =
      payload.response.s_comments == 0 ? 0 : payload.project.s_comments

    payload.project.d_comments =
      payload.response.d_comments == 0 ? 0 : payload.project.d_comments

    payload.project.p_comments =
      payload.response.p_comments == 0 ? 0 : payload.project.p_comments

    payload.project.r_comments =
      payload.response.r_comments == 0 ? 0 : payload.project.r_comments

    payload.project.m_comments =
      payload.response.m_comments == 0 ? 0 : payload.project.m_comments

    payload.project.ite_comments =
      payload.response.ite_comments == 0 ? 0 : payload.project.ite_comments

    payload.project.vmc_comments =
      payload.response.vmc_comments == 0 ? 0 : payload.project.vmc_comments

    payload.project.cofrac_comments =
      payload.response.cofrac_comments == 0
        ? 0
        : payload.project.cofrac_comments

    payload.project.nom_client_comments =
      payload.response.nom_client_comments == 0
        ? 0
        : payload.project.nom_client_comments
  },
  // Project users
  SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE(state, payload) {
    state.usersCommercialSedentaire = payload
  },
  SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING(state, payload = false) {
    state.usersCommercialSedentaireLoading = payload
  },
  SET_PROJECTS_USERS_VISITEUR_TECHNIQUE(state, payload) {
    state.usersVvisiteurTechnique = payload
  },
  SET_PROJECTS_USERS_VISITEUR_TECHNIQUE_LOADING(state, payload = false) {
    state.usersVisiteurTechniqueLoading = payload
  },
  SET_PROJECTS_USERS_COMMERCIALS_ITE(state, payload) {
    state.usersCommercialsIte = payload
  },
  SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING(state, payload = false) {
    state.usersCommercialsIteLoading = payload
  },
  SET_PROJECTS_USERS_RESP_PLAN(state, payload) {
    state.usersRespPlan = payload
  },
  SET_PROJECTS_USERS_RESP_PLAN_LOADING(state, payload = false) {
    state.usersRespPlanLoading = payload
  },
  SET_PROJECTS_USERS_TECHNICIENS(state, payload) {
    state.usersTechniciens = payload
  },
  SET_PROJECTS_USERS_TECHNICIENS_LOADING(state, payload = false) {
    state.usersTechniciensLoading = payload
  },

  SET_PROJECTS_SOURCE_PROCESSING(state, payload = false) {
    state.sourcesProcessing = payload
  },
  SET_PROJECTS_CAMPAGNE_PROCESSING(state, payload = false) {
    state.campagneProcessing = payload
  },
  SET_PROJECTS_CONTACT_FIELDS(state, payload) {
    state.contactFields = payload
  },
  SET_PROJECTS_PROJECT_FIELDS(state, payload) {
    state.projectFields = payload
  },
  SET_PROJECTS_SUGGESTIONS_PROCESSING(state, payload = false) {
    state.suggestionsProcessing = payload
  },
  ADD_PROJECT_ACTIVITY(state, payload) {
    if (!payload.project.activities) {
      payload.project.activities = []
    }
    payload.project.activities.unshift(Activity.create(payload.payload))
    if (payload.payload.terminer) {
      payload.project.timeline.unshift(Activity.create(payload.payload))
    }
  },
  SET_PROJECT_ACTIVITY(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.activity.activities = []
    }
    if (payload.activity.activities && payload.activity.activities.length) {
      payload.activity.activities = payload.activity.activities.filter(t => {
        return t.terminer == 1
      })
      payload.response.map(item =>
        payload.activity.activities.push(Activity.create(item))
      )
    } else {
      payload.activity.activities = payload.response.map(item =>
        Activity.create(item)
      )
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsActivitytotalRows = payload.pagination.total
    }
  },
  UPDATE_PROJECT_ACTIVITY(state, payload) {
    //activities
    const index = payload.project.activities.findIndex(e => {
      return e.id == payload.activity.id
    })
    if (index > -1) {
      payload.project.activities[index].updateActivity(payload.response)
    }
    //timeline
    if (payload.project) {
      const index = payload.project.timeline.findIndex(e => {
        return e.object == 'Activity' && e.id == payload.activity.id
      })
      if (index > -1) {
        payload.project.timeline[index].updateActivity(payload.response)
      }
      //
      if (payload.toggle) {
        if (payload.response.terminer) {
          payload.project.timeline.unshift(Activity.create(payload.response))
        } else {
          const index = payload.project.timeline.findIndex(e => {
            return e.object == 'Activity' && e.id == payload.activity.id
          })
          if (index > -1) {
            payload.project.timeline.splice(index, 1)
          }
        }
      }
    }
  },
  REMOVE_PROJECT_ACTIVITY(state, payload) {
    payload.project.activities = payload.project.activities.filter(activity => {
      return activity.id != payload.activity.id
    })
    if (payload.project && payload.project.timeline) {
      payload.project.timeline = payload.project.timeline.filter(item => {
        return item.object != 'Activity' || item.id != payload.activity.id
      })
    }
  },
  SET_PROJECT_JOURNAL(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.project.journals = []
    }
    if (
      payload &&
      payload.response &&
      payload.response.length &&
      payload.project &&
      payload.project.journals
    ) {
      payload.response.map(item =>
        payload.project.journals.push(History.create(item))
      )
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsJournaltotalRows = payload.pagination.total
    }
  },
  SET_PROJECT_COMMENTS(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.project.comments = []
    }
    if (
      payload &&
      payload.response &&
      payload.response.length &&
      payload.project &&
      payload.project.comments
    ) {
      payload.response.map(item =>
        payload.project.comments.push(Comment.create(item))
      )
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsCommentstotalRows = payload.pagination.total
    }
  },
  SET_PROJECT_COMMENTS_USERS(state, payload) {
    state.usersComments = payload.map(item => User.create(item))
  },
  ADD_PROJECTS_COMMENTS(state, payload) {
    payload.project.comments.unshift(Comment.create(payload.response))
    payload.project.timeline.unshift(Comment.create(payload.response))
  },
  SET_PROJECT_ADD_FILES(state, payload) {
    if (payload.response && Array.isArray(payload.response)) {
      payload.response.forEach(file => {
        payload.project.files.unshift(File.create(file))
        payload.project.timeline.unshift(File.create(file))
      })
      if (state.projects && Array.isArray(state.projects)) {
        for (let index = 0; index < state.projects.length; index++) {
          for (let index_1 = 0; index_1 < payload.response.length; index_1++) {
            const payloadResponseProjet = payload.response[index_1]
            if (
              payloadResponseProjet &&
              payloadResponseProjet.projet &&
              payloadResponseProjet.projet.data &&
              payloadResponseProjet.projet.data.id &&
              state.projects[index].id == payloadResponseProjet.projet.data.id
            ) {
              state.projects[index].file_cat_color =
                payloadResponseProjet.projet.data.file_cat_color
            }
          }
        }
      }
    }
  },
  SET_PROJECT_HISTORIQUE(state, payload) {
    if (payload.reset && payload.reset === 1) {
      payload.project.timeline = []
    }
    if (
      payload &&
      payload.response &&
      payload.response.length &&
      payload.project.timeline &&
      payload.project
    ) {
      payload.response.map(item => {
        if (item.object == 'ChangeProjet') {
          payload.project.timeline.push(History.create(item))
        }
        if (item.object == 'Fichier') {
          payload.project.timeline.push(File.create(item))
        }
        if (item.object == 'Comment') {
          payload.project.timeline.push(Comment.create(item))
        }
        if (item.object == 'Activity') {
          payload.project.timeline.push(Activity.create(item))
        }
      })
    }
    if (payload.pagination && payload.pagination.total) {
      state.projectsHistoriquetotalRows = payload.pagination.total
    }
  },
  SET_PROJECT_FILES(state, payload) {
    payload.project.files = payload.response.map(item => File.create(item))
  },
  SET_PROJECT_FILES_PROCESSING(state, payload = false) {
    state.filesProcessing = payload
  },
  UPDATE_PROJECT_FILE(state, payload) {
    // update fichier dans onglet Tous
    // files
    const index = payload.project.files.findIndex(e => {
      return e.id == payload.file.id
    })
    if (index > -1) {
      payload.project.files[index].updateFile(payload.response)
    }

    // timeline
    const indexTimeline = payload.project.timeline.findIndex(e => {
      return e.object == 'Fichier' && e.id == payload.file.id
    })
    if (indexTimeline > -1) {
      payload.project.timeline[indexTimeline].updateFile(payload.response)
    }
    // update table category fichier par count
    if (
      state.categoriesFichiersParCount &&
      Array.isArray(state.categoriesFichiersParCount)
    ) {
      for (
        let index = 0;
        index < state.categoriesFichiersParCount.length;
        index++
      ) {
        if (
          state.categoriesFichiersParCount[index].files &&
          state.categoriesFichiersParCount[index].files.length
        ) {
          for (
            let j = 0;
            j < state.categoriesFichiersParCount[index].files.length;
            j++
          ) {
            if (
              state.categoriesFichiersParCount[index].files[j].id ==
              payload.response.id
            )
              state.categoriesFichiersParCount[index].files[j].updateFile(
                payload.response
              )
          }
        }
      }
    }
  },
  REMOVE_PROJECTS_TOUS_FILE(state, payload) {
    let file_id = payload.file.map(item => item.id)
    payload.project.files = payload.project.files.filter(file => {
      if (payload.file && payload.file.length) {
        return !file_id.includes(file.id)
      } else {
        return file.id != payload.file.id
      }
    })
    if (payload.project.timeline) {
      payload.project.timeline = payload.project.timeline.filter(item => {
        if (payload.file && payload.file.length) {
          return !file_id.includes(item.id)
        } else {
          return item.id != payload.file.id
        }
      })
    }
    if (state.projects && Array.isArray(state.projects)) {
      for (let index = 0; index < state.projects.length; index++) {
        if (payload.project && state.projects[index].id == payload.project.id) {
          state.projects[index].file_cat_color = 'red'
        }
      }
    }
    // deleted table category fichier par count
    if (
      state.categoriesFichiersParCount &&
      Array.isArray(state.categoriesFichiersParCount)
    ) {
      for (
        let index = 0;
        index < state.categoriesFichiersParCount.length;
        index++
      ) {
        if (
          state.categoriesFichiersParCount[index].files &&
          state.categoriesFichiersParCount[index].files.length
        ) {
          state.categoriesFichiersParCount[
            index
          ].files = state.categoriesFichiersParCount[index].files.filter(
            file => {
              if (payload.file && payload.file.length) {
                return !file_id.includes(file.id)
              } else {
                return file.id != payload.file.id
              }
            }
          )
          // REFRESH COUNT CATEGORY FILE
          state.categoriesFichiersParCount[index].count_files =
            state.categoriesFichiersParCount[index].files.length
        }
      }
    }
  },
  REMOVE_PROJECTS_FILE(state, payload) {
    // deleted fichier dans onglet Tous
    payload.project.files = payload.project.files.filter(file => {
      return file.id != payload.file.id
    })
    if (payload.project.timeline) {
      payload.project.timeline = payload.project.timeline.filter(item => {
        return item.id != payload.file.id
      })
    }
    if (state.projects && Array.isArray(state.projects)) {
      for (let index = 0; index < state.projects.length; index++) {
        if (payload.project && state.projects[index].id == payload.project.id) {
          state.projects[index].file_cat_color = 'red'
        }
      }
    }
    // deleted table category fichier par count
    if (
      state.categoriesFichiersParCount &&
      Array.isArray(state.categoriesFichiersParCount)
    ) {
      for (
        let index = 0;
        index < state.categoriesFichiersParCount.length;
        index++
      ) {
        if (
          state.categoriesFichiersParCount[index].files &&
          state.categoriesFichiersParCount[index].files.length
        ) {
          state.categoriesFichiersParCount[
            index
          ].files = state.categoriesFichiersParCount[index].files.filter(
            file => {
              return file.id != payload.file.id
            }
          )
          // REFRESH COUNT CATEGORY FILE
          state.categoriesFichiersParCount[index].count_files =
            state.categoriesFichiersParCount[index].files.length
        }
      }
    }
  },
  SET_PROJECT_FILES_CATEGORIES(state, categorie) {
    state.categoriesFichiers = categorie.map(categorie =>
      CategorieFile.create(categorie)
    )
  },
  SET_PROJECT_FICHIERS_LENGTH(state, payload) {
    state.categoriesFichiersParCount = payload.map(item =>
      CategorieFilesLength.create(item)
    )
  },
  UPDATE_PROJECT_NOTE(state, payload) {
    // comments
    const index = payload.project.comments.findIndex(e => {
      return e.id == payload.comment.id
    })
    if (index > -1) {
      payload.project.comments[index].updateNote(payload.response)
    }

    // timeline
    const indexTimeline = payload.project.timeline.findIndex(e => {
      return e.object == 'Comment' && e.id == payload.comment.id
    })
    if (indexTimeline > -1 && index > -1) {
      payload.project.timeline[indexTimeline].updateNote(payload.response)
    }
  },
  REMOVE_PROJECT_NOTE(state, payload) {
    payload.project.comments = payload.project.comments.filter(comment => {
      return comment.id != payload.comment.id
    })

    if (payload.project.timeline) {
      payload.project.timeline = payload.project.timeline.filter(item => {
        return item.object != 'Comment' || item.id != payload.comment.id
      })
    }
  },
  SET_PROJECTS_SOURCE(state, payload) {
    state.source = payload
  },
  SET_PROJECTS_CAMPAGNE(state, payload) {
    state.campagne = payload
  },
  SET_PROJECTS_CAMPAGNE_SOURCES(state, payload) {
    state.campagneSource[payload.source.text] = payload.response.map(u => {
      return { text: u.full_name, value: u.id }
    })
  },
  SET_PROJECTS_DEPOTS(state, payload) {
    state.depots = payload
  },
  SET_PROJECTS_AGENCE(state, payload) {
    state.agence = payload
  },
  SET_PROJECTS_FILIALE_AGENCES(state, payload) {
    state.filialeAgence = payload.sort(function compare(a, b) {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  },
  SET_PROJECTS_DEPOTS_COMMERCIAL_TERRAIN(state, payload) {
    state.depotsCommercialTerrain[payload.depot.text] = payload.response.map(
      u => {
        return { text: u.full_name, value: u.id }
      }
    )
  },
  SET_PROJECTS_DEPOTS_TECHNICIEN(state, payload) {
    state.depotsTechnicien[payload.depot.text] = payload.response.map(u => {
      return { text: u.full_name, value: u.id }
    })
  },
  SET_PROJECTS_DEPOTS_PREVISITEUR(state, payload) {
    state.depotsPrevisiteur[payload.depot.text] = payload.response.map(u => {
      return { text: u.full_name, value: u.id }
    })
  },

  SET_PROJECTS_DEPOTS_PROCESSING(state, payload = false) {
    state.depotsProcessing = payload
  },
  SET_PROJECTS_META(state, payload) {
    if (
      payload.response &&
      payload.response.pagination &&
      payload.response.pagination.total
    ) {
      if (!payload.conditions) {
        state.projectstotalRowsType = payload.response.pagination.total
        state.projectstotalRows = payload.response.pagination.total
        state.projectstotalRowsStade = 0
      } else {
        state.projectstotalRowsStade = payload.response.pagination.total
        state.projectstotalRows = payload.response.pagination.total
        state.projectstotalRowsType = 0
      }
      state.projectstotalRowsTH = payload.response.pagination.total
    } else {
      state.projectstotalRowsStade = 0
      state.projectstotalRows = 0
      state.projectstotalRowsTH = 0
      state.projectstotalRowsType = 0
    }
  },
  DO_NOTHING() {},
  SAVE_CUSTOM_FILTERS(state, payload) {
    state.filters.push(payload)
  },
  SET_CUSTOM_FILTERS(state, payload) {
    state.filters = payload
  },
  DELETE_CUSTOM_FILTERS(state, payload) {
    state.filters = state.filters.filter(filter => {
      return filter.id != payload.id
    })
  },
  SET_ETAT_SWITCH(state, payload) {
    if (payload.response.subtype) {
      for (
        let index = 0;
        index < state.extraProject.categoriesProjets.length;
        index++
      ) {
        if (
          state.extraProject.categoriesProjets[index].id ===
          payload.payload.categorie.id
        ) {
          for (
            let indx = 0;
            indx < state.extraProject.categoriesProjets[index].subtypes.length;
            indx++
          ) {
            if (
              state.extraProject.categoriesProjets[index].subtypes[indx].id ===
              payload.response.subtype.id
            ) {
              state.extraProject.categoriesProjets[index].subtypes[
                indx
              ].is_tagged = payload.response.subtype.is_tagged
            }
          }
        }
      }
    }
  },

  SET_ROUTE_DYNAMIC(state, payload) {
    state.routeDynamic = payload
  },
  SET_ROUTE_DYNAMIC_PROCESSING(state, payload = false) {
    state.routeDynamicProcessing = payload
  },
  SET_ROUTE_DYNAMIC_PROJECT_TH(state, payload) {
    const value = 'table' + payload.route.name
    payload.project[value] = payload.response
  },
  SET_ADD_CHAMP_SELECT_PROJECT_TH(state, payload) {
    const value = 'table' + payload.route.name
    payload.project[value].unshift(payload.response)
  },
  SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH(state, payload = false) {
    state.routeDynamicProcessingProjectTH = payload
  },
  SET_COMM_SED(state, payload) {
    state.commSed = payload
  },
  SET_COMM_SED_PROCESSING(state, payload = false) {
    state.commSedProcessing = payload
  },
  SET_AMO_COMMERCIAL_ITE(state, payload) {
    state.amoCommIte = payload
  },
  SET_TECH_TRAVAUX(state, payload) {
    state.techTravaux = payload
  },
  SET_PREVISITEUR(state, payload) {
    state.previsiteur = payload
  },
  SET_PREVISITEUR_PROCESSING(state, payload = false) {
    state.previsiteurProcessing = payload
  },
  SET_POSEUR(state, payload) {
    state.poseur = payload
  },
  SET_POSEUR_PROCESSING(state, payload = false) {
    state.poseurProcessing = payload
  },
  SET_SOURCES(state, payload) {
    state.sources = payload.sort(function compare(a, b) {
      if (a.name < b.name) return -1
      if (a.name > b.name) return 1
      return 0
    })
  },
  SET_SOURCE_PROCESSING(state, payload = false) {
    state.sourceProcessing = payload
  },
  SET_INGENIEUR(state, payload) {
    state.ingenieur = payload
  },
  SET_INGENIEUR_PROCESSING(state, payload = false) {
    state.ingenieurProcessing = payload
  },
  SET_COMMERCIAL(state, payload) {
    state.commercial = payload
  },
  SET_COMMERCIAL_PROCESSING(state, payload = false) {
    state.commercialProcessing = payload
  },
  SET_RESP_PLANNNING(state, payload) {
    state.respPlanning = payload
  },
  SET_RESP_PLANNNING_PROCESSING(state, payload = false) {
    state.respPlanningProcessing = payload
  },
  SET_PROJECT_TYPES_PIXEL(state, payload) {
    state.typePixel = payload.map(item => TypePixel.create(item))
  },
  SET_PROJECT_TYPES_PIXELS(state, payload) {
    state.sousTypes = payload.map(item => TypePixel.create(item))
  },
  SET_PROJECT_TYPES_PIXEL_PROCESSING(state, payload = false) {
    state.typePixelLoading = payload
  },
  SET_PROJECT_INJECTION_PIXEL(state, payload) {
    payload.pixel.update(payload.response)
    state.historyInjectionPixel.unshift({
      pixel_type: payload.response.name,
      time: payload.response.time,
      user: payload.response.user
      // name_subtype: payload.response.name_subtype
    })
  },
  SET_PROJECT_HISTORY_PIXEL_PROCESSING(state, payload = false) {
    state.historyInjectionPixelLoading = payload
  },
  SET_PROJECT_HISTORY_PIXEL(state, payload) {
    state.historyInjectionPixel = payload
  },
  RESET_PAGE_VALUE(state, payload) {
    state.objectPrj = payload
    state.changePageValue = !state.changePageValue
  },
  SET_FIELDS_VALUE_SUBCATEGORY(state, payload) {
    state.fieldValueSubcategory = payload
  },
  SET_PROJECT_TYPES(state, payload) {
    state.types = payload
  },
  SET_PROJECTS_TYPES_RENOVATION_LOADING(state, payload = false) {
    state.typesRenovationLoading = payload
  }
}
const actions = {
  // Project
  async fetchProjects(
    { commit },
    {
      refresh,
      conditions,
      page,
      pagination,
      sort_by,
      sort_by_desc,
      type_id,
      filter_id,
      categories,
      keyVue,
      per_page,
      display_all,
      has_filiales_isolations,
      has_type_travaux,
      has_cumac_type_renovation
    }
  ) {
    commit('SET_PROJECTS_PROCESSING', true)
    if (!pagination) {
      commit('SET_PROJECTS_COUNT_PROCESSING', true)
    }
    commit('SET_PROJECTS_ERROR')
    if (!refresh) {
      commit('SET_PROJECTS_PROJECTS', [])
    }
    try {
      const params = {
        page: page,
        sort_by_asc: sort_by,
        type_id: type_id
      }
      if (has_filiales_isolations) {
        params.has_filiales_isolations = has_filiales_isolations
      }
      if (has_cumac_type_renovation) {
        params.has_cumac_type_renovation = has_cumac_type_renovation
      }
      if (has_type_travaux) {
        params.has_type_travaux = has_type_travaux
      }
      if (display_all) {
        params.display_all = display_all
      }
      if (per_page) {
        params.per_page = per_page
      }
      if (keyVue) {
        params.key = keyVue
      }
      if (categories) {
        params.categories = categories
      }
      if (filter_id) {
        params.filter_id = filter_id
      }
      if (sort_by_desc) {
        params.sort_by_desc = sort_by_desc
      }
      if (requests.projects) {
        requests.projects.cancel('override-request')
      }
      requests.projects = Axios.CancelToken.source()
      const response = await Axios.post(
        domain + '/api/crm/projets/search',
        {
          conditions: conditions
        },
        {
          cancelToken: requests.projects.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECTS_PROJECTS', response.data.data)
      if (!pagination) {
        commit('SET_PROJECTS_COUNT_PROCESSING')
      }
      commit('SET_PROJECTS_META', {
        conditions: conditions,
        response: response.data.meta
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },
  async fetchSingleProject({ commit }, { id, checked }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(`${domain}/api/crm/projets/${id}`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_PROJECTS_EXTRA', {
        data: response.data,
        checked: checked,
        id: id
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },
  async createProject({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_CREATE_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/projets',
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECTS_PROJECT', {
        id_dtype: payload.data.dtype_id,
        currentType: payload.currentType,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_CREATE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_CREATE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async checkDoubleProject({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_CREATE_ERROR')
    try {
      const params = {
        first_name: payload.first_name,
        last_name: payload.last_name,
        type_id: payload.type_id
      }
      const response = await Axios.get(domain + '/api/crm/double-projets', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('CHECK_DOUBLE_PROJECTS_PROJECT', response.data.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_CREATE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_CREATE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProject({ commit, dispatch }, payload) {
    let data = {}
    let dataGroupe = []
    if (payload && payload.field_group) {
      for (let index = 0; index < payload.field.length; index++) {
        dataGroupe.push({
          [payload.field[index].key]: payload.value[index].value
        })
      }
      if (dataGroupe && dataGroupe.length && dataGroupe.length == 3) {
        data = { ...dataGroupe[0], ...dataGroupe[1], ...dataGroupe[2], ...data }
      } else if (dataGroupe && dataGroupe.length && dataGroupe.length == 9) {
        data = {
          ...dataGroupe[0],
          ...dataGroupe[1],
          ...dataGroupe[2],
          ...dataGroupe[3],
          ...dataGroupe[4],
          ...dataGroupe[5],
          ...dataGroupe[6],
          ...dataGroupe[7],
          ...dataGroupe[8],
          ...data
        }
      } else {
        data = {
          ...dataGroupe[0],
          ...dataGroupe[1],
          ...dataGroupe[2],
          ...dataGroupe[3],
          ...dataGroupe[4],
          ...dataGroupe[5],
          ...dataGroupe[6],
          ...dataGroupe[7],
          ...data
        }
      }
    } else {
      data = { [payload.field]: payload.value }
    }
    if (payload && payload.formLeadProject) {
      data = {
        // Informations Logement
        occupation: payload.formLeadProject.occupation,
        housing_type: payload.formLeadProject.housing_type,
        cadastral_plot: payload.formLeadProject.cadastral_plot,
        type_heating: payload.formLeadProject.type_heating,
        type_energie: payload.formLeadProject.type_energie,
        profite_iso: payload.formLeadProject.profite_iso
          ? payload.formLeadProject.profite_iso
          : null,
        changer_chauff: payload.formLeadProject.changer_chauff
          ? payload.formLeadProject.changer_chauff
          : null,
        // RDV - Relevé Technique AMO
        team_comm_ite_id: payload.formLeadProject.team_comm_ite_id,
        depot_comm_ite_id: payload.formLeadProject.depot_comm_ite_id,
        commercial_itinerant: payload.formLeadProject.commercial_itinerant,
        date_previsite: payload.formLeadProject.date_previsite,
        hour_previsite: payload.formLeadProject.hour_previsite,
        statut_releve_technique:
          payload.formLeadProject.statut_releve_technique,
        // Coordonnées
        zipCode: payload.formLeadProject.zipCode,
        source_id: payload.formLeadProject.source_id
      }
      // surface
      data['814c739850155e4e6776b33e5c2db929c243257cd8a6eb3d3d'] =
        payload.formLeadProject[
          '814c739850155e4e6776b33e5c2db929c243257cd8a6eb3d3d'
        ]
    }
    if (payload && !payload.field && !payload.formLeadProject) {
      data = payload.value
    }
    if (payload && payload.substage) {
      data[payload.substage.field] = payload.substage.value
    }
    if (payload.logistique_travaux) {
      data.logistique_travaux = payload.logistique_travaux
    }
    if (payload.type_travaux) {
      data.type_travaux = payload.type_travaux
    }
    if (payload.type_isolation) {
      data.type_isolation = payload.type_isolation
    }
    commit('SET_PROJECTS_PROCESSING', true)
    commit('REMOVE_PROJECTS_WARNING')
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        domain +
          `/api/crm/projets/${
            payload.entity && payload.entity.id
              ? payload.entity.id
              : payload.entity
          }`,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (
        payload &&
        payload.field &&
        (payload.field == 'cstage_id' || payload.field == 'csubstage_id') &&
        !payload.planningAmo
      ) {
        dispatch('fetchProjectHistoriques', {
          project: payload.entity,
          reset: 1,
          page: 1
        })
      }
      if (!payload.planningAmo) {
        dispatch('fetchJournalDesModifications', {
          project: payload.entity,
          reset: 1,
          page: 1
        })
      }
      commit('UPDATE_PROJECTS_PROJECT', {
        checked: payload.checked,
        entity: payload.entity,
        commit: payload.planningAmo,
        response: response.data
      })
      commit('SET_PROJECTS_WARNNING', response.data.warning)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      state.responseUpdateProject = false
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjectBash({ commit, dispatch }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        domain + `/api/crm/projets/${payload.entity.id}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      dispatch('fetchProjectHistoriques', { project: payload.entity, page: 1 })
      dispatch('fetchJournalDesModifications', {
        project: payload.entity,
        page: 1
      })
      commit('UPDATE_PROJECTS_PROJECT', {
        entity: payload.entity,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
      // state.responseUpdateProject = true
    } catch (error) {
      // state.responseUpdateProject = false
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjectsMultiple({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        `${domain}/api/crm/mass-projets`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECTS_MULTIPLE', {
        response: response.data,
        projects: payload.projects
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (error.response && error.response.data) {
        commit('SET_PROJECTS_ERROR_WARNING_MULTI_MODAL', error.response.data)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteProjectsMultiple({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        `${domain}/api/crm/delete-mass-projets`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          data: {
            projets: payload.projects.map(p => {
              return p.id
            }),
            is_deleted: 1
          }
        }
      )
      commit('DELETE_PROJECTS_MULTIPLE', {
        response: response.data.data,
        projects: payload.projects
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async refrechProject({ commit, dispatch }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_CREATE_ERROR')
    try {
      dispatch('fetchJournalDesModifications', {
        project: payload.project,
        reset: payload.reset,
        page: 1
      })
      const response = await Axios.get(
        domain + '/api/crm/projets/' + payload.project.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REFRECH_PROJECTS_PROJECT', {
        project: payload.project,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_CREATE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_CREATE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteProject({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.delete(domain + `/api/crm/projets/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PROJECTS_PROJECT', payload)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectsByContact({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/crm/contact/' + payload + '/projets',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CONTACT_PROJECTS', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async exportProjectFile(
    { commit },
    { conditions, type_id, filter_id, sort_by, sort_by_desc }
  ) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        sort_by: sort_by,
        type_id: type_id
      }
      if (sort_by_desc) {
        params.sort_by_desc = sort_by_desc
      }
      if (filter_id != 'null') {
        params.filter_id = filter_id
      }
      if (requests.exportExcel) {
        requests.exportExcel.cancel('override-request')
      }
      requests.exportExcel = Axios.CancelToken.source()
      const response = await Axios.post(
        domain + '/api/export-projects',
        {
          conditions: conditions
        },
        {
          cancelToken: requests.exportExcel.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      const a = document.createElement('a')
      a.href = response.data.data.url
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },
  // Custom fields
  async fetchContactFields({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/fields?table_id=3&default=0',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_CONTACT_FIELDS', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectFields({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/fields?dtype_id=' +
          payload.id_dtype +
          '&table_id=42&default=0 ',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_PROJECT_FIELDS', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // Users of projet
  async fetchUsersCommercialSedentaire({ commit }) {
    commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-sed', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE', response.data.data)
      commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIAL_SEDENTAIRE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersVisiteurTechnique({ commit }) {
    commit('SET_PROJECTS_USERS_VISITEUR_TECHNIQUE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/visiteur-tech-without-deleted',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_USERS_VISITEUR_TECHNIQUE', response.data.data)
      commit('SET_PROJECTS_USERS_VISITEUR_TECHNIQUE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_VISITEUR_TECHNIQUE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersCommercialsIte({ commit }) {
    commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-itenerant', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE', response.data.data)
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepotPrevisiteur({ commit }, payload) {
    commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload.value + '/coms-itenerant',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_DEPOTS_PREVISITEUR', {
        response: response.data.data,
        depot: payload
      })
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersRespPlan({ commit }) {
    commit('SET_PROJECTS_USERS_RESP_PLAN_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/resp-plan', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_RESP_PLAN', response.data.data)
      commit('SET_PROJECTS_USERS_RESP_PLAN_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_RESP_PLAN_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersTechniciens({ commit }) {
    commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/poseurs', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_USERS_TECHNICIENS', response.data.data)
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepotTechnicien({ commit }, payload) {
    commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload.value + '/poseurs',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_DEPOTS_TECHNICIEN', {
        response: response.data.data,
        depot: payload
      })
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_TECHNICIENS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Planning suggestion
  async fetchPlanningDatesSuggestions({ commit }, payload) {
    commit('SET_PROJECTS_SUGGESTIONS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    let suggestions = []

    try {
      const response = await Axios.post(
        domain + '/api/dates-suggestions',
        payload,
        {}
      )
      suggestions = response.data
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
    }
    return suggestions
  },
  async fetchPreVisitDatesSuggestions({ commit }) {
    commit('SET_PROJECTS_SUGGESTIONS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    let suggestions = []

    try {
      // api call
      suggestions = []
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_SUGGESTIONS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
    }
    return suggestions
  },
  // Activities
  async createActivity({ commit }, { payload, project }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      const response = await Axios.post(
        domain + '/api/crm/activities',
        payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECT_ACTIVITY', {
        payload: response.data.data,
        project: project
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchAllActivities({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      if (
        requests.projectDependencies &&
        requests.projectDependencies.activities
      ) {
        requests.projectDependencies.activities.cancel('override-request')
      }
      requests.projectDependencies.activities = Axios.CancelToken.source()

      const responseNonTerminer = await Axios.get(
        domain + '/api/crm/activities?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.activities.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: {
            terminer: 0
          }
        }
      )

      const responseTerminer = await Axios.get(
        domain + '/api/crm/activities?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.activities.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: {
            per_page: 10,
            page: payload.page,
            terminer: 1
          }
        }
      )
      const response = responseNonTerminer.data.data.concat(
        responseTerminer.data.data
      )
      commit('SET_PROJECT_ACTIVITY', {
        activity: payload.project,
        response: response,
        pagination:
          responseTerminer.data.meta && responseTerminer.data.meta.pagination
            ? responseTerminer.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateActivity({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    const body = {
      date_start: payload.activity.date_start,
      terminer: payload.activity.terminer ? 1 : 0,
      description: payload.activity.description,
      name: payload.activity.name,
      user_id: payload.activity.user.value,
      cstage_id:
        payload.activity.cstage &&
        payload.activity.cstage.id &&
        payload.activity.cstage.id != -1
          ? payload.activity.cstage.id
          : null,
      csubstage_id:
        payload.activity.csubstage &&
        payload.activity.csubstage.id &&
        payload.activity.csubstage.id != -1
          ? payload.activity.csubstage.id
          : null
    }
    if (!payload.activity.terminer) {
      body.me_prevenir = payload.activity.me_prevenir
    }
    try {
      const response = await Axios.put(
        domain + '/api/crm/activities/' + payload.activity.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_ACTIVITY', {
        toggle: payload.toggle,
        activity: payload.entity,
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
      return { succes: true }
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        return {
          succes: false,
          error: error.response.data.error.messages
        }
        // commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        return {
          succes: false,
          error: ['Une erreur est survenue']
        }
        // commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
    }
  },
  async toggleActivity({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      const response = await Axios.put(
        domain + '/api/crm/activities/' + payload.activity.id,
        { terminer: payload.value },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_ACTIVITY', {
        toggle: true,
        project: payload.project,
        activity: payload.activity,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteActivity({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain + `/api/crm/activities/` + payload.activity.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_PROJECT_ACTIVITY', {
        activity: payload.activity,
        project: payload.project,
        response: response.data.data
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // History
  async fetchProjectHistoriques({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        per_page: 10,
        page: payload.page
      }
      if (
        requests.projectDependencies &&
        requests.projectDependencies.history
      ) {
        requests.projectDependencies.history.cancel('override-request')
      }
      requests.projectDependencies.history = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/crm/filter-tous?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.history.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECT_HISTORIQUE', {
        project: payload.project,
        response: response.data.data,
        pagination:
          response.data.meta && response.data.meta.pagination
            ? response.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchJournalDesModifications({ commit }, payload) {
    commit('SET_PROJECTS_HISTORY_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        per_page: 10,
        page: payload.page
      }
      if (
        requests.projectDependencies &&
        requests.projectDependencies.journal
      ) {
        requests.projectDependencies.journal.cancel('override-request')
      }
      requests.projectDependencies.journal = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/crm/historiques?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.journal.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECT_JOURNAL', {
        project: payload.project,
        response: response.data.data,
        pagination:
          response.data.meta && response.data.meta.pagination
            ? response.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_HISTORY_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_HISTORY_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Files
  async addFichier({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/files',
        payload.data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_ADD_FILES', {
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchFiles({ commit }, { project, categorie }) {
    commit('SET_PROJECT_FILES_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    const params = {}
    if (categorie && categorie.id) {
      params.parent_id = categorie.id
      params.projet_id = project.id
    }
    try {
      if (requests.projectDependencies.files) {
        requests.projectDependencies.files.cancel('override-request')
      }
      requests.projectDependencies.files = Axios.CancelToken.source()
      const response = await Axios.get(domain + '/api/crm/files', {
        cancelToken: requests.projectDependencies.files.token,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_PROJECT_FILES', {
        project: project,
        response: response.data.data
      })
      commit('SET_PROJECT_FILES_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECT_FILES_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async downloadtousFiles({ commit }, payload) {
    commit('SET_PROJECTS_FILES_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const body = {
        fichiers: payload.fichiers
      }
      const response = await Axios.post(domain + '/api/download-files', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        responseType: 'blob'
      })
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.setAttribute('download', 'Fichiers' + '.zip') //or any other extension
      link.href = url
      document.body.appendChild(link)
      link.click()
      commit('DO_NOTHING')
      commit('SET_PROJECTS_FILES_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_FILES_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedTousFiles({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(domain + '/api/delete-files', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      if (response) {
        commit('REMOVE_PROJECTS_TOUS_FILE', {
          file: payload.file,
          project: payload.project
        })
      }
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async UpdateFile({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/files/' + payload.file.id,
        { description: payload.data },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_FILE', {
        file: payload.file,
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteFile({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain + `/api/crm/files/` + payload.file.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      if (response) {
        commit('REMOVE_PROJECTS_FILE', {
          file: payload.file,
          project: payload.project
        })
      }
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async downloadFile({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/crm/files/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          responseType: 'blob'
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', payload.title) //or any other extension
      document.body.appendChild(link)
      link.click()
      commit('DO_NOTHING')
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchCategorieFiles({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/categories-files-type',
        payload ? payload : {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_FILES_CATEGORIES', response.data.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchCategorieFilesParLength({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        projet_id: payload.projet_id,
        parent_id: payload.parent_id
      }
      const response = await Axios.get(domain + '/api/crm/category-files', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_PROJECT_FICHIERS_LENGTH', response.data.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Comments
  async addComment({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_CREATE_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/crm/pcomments',
        {
          body: payload.comment,
          projet_id: payload.project.id,
          category_id: payload.category_id
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECTS_COMMENTS', {
        response: response.data.data,
        project: payload.project
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_CREATE_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_CREATE_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchComments({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {
        per_page: 10,
        page: payload.page
      }
      if (payload.category_id) {
        params.category_id = payload.category_id
      }
      if (
        requests.projectDependencies &&
        requests.projectDependencies.comments
      ) {
        requests.projectDependencies.comments.cancel('override-request')
      }
      requests.projectDependencies.comments = Axios.CancelToken.source()
      const response = await Axios.get(
        domain + '/api/crm/pcomments?projet_id=' + payload.project.id,
        {
          cancelToken: requests.projectDependencies.comments.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECT_COMMENTS', {
        project: payload.project,
        response: response.data.data,
        pagination:
          response.data.meta && response.data.meta.pagination
            ? response.data.meta.pagination
            : { total: 1 },
        reset: payload.reset
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateNote({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      const response = await Axios.put(
        domain + '/api/crm/pcomments/' + payload.entity.id,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_NOTE', {
        comment: payload.entity,
        project: payload.project,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteNote({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain + `/api/crm/pcomments/` + payload.comment.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_PROJECT_NOTE', {
        comment: payload.comment,
        project: payload.project,
        response: response.data.data
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersComments({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/comment-users', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECT_COMMENTS_USERS', response.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      if (error.message === 'override-request') {
        return true
      }
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorNoteProject({ commit }) {
    commit('SET_PROJECTS_CREATE_ERROR')
  },
  // information marketing - source
  async fetchProjectSource({ commit }) {
    commit('SET_PROJECTS_SOURCE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/crm/sources?active=1', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_SOURCE', response.data.data)

      commit('SET_PROJECTS_SOURCE_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_SOURCE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectCampagne({ commit }) {
    commit('SET_PROJECTS_CAMPAGNE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-regie', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_CAMPAGNE', response.data.data)

      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectsCampagnesSources({ commit }, payload) {
    commit('SET_PROJECTS_CAMPAGNE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/team/' + payload.team_id + '/coms-regie',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_CAMPAGNE_SOURCES', {
        response: response.data.data,
        source: payload
      })

      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_CAMPAGNE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectAgenceIDName({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/all-depots-ids-names', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECTS_AGENCE', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectFilialeIDName({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    const params = {
      subcategory_id: 12
    }
    try {
      const response = await Axios.get(domain + '/api/all-teams-ids-names', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })
      commit('SET_PROJECTS_FILIALE_AGENCES', response.data.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepot({ commit }, payload) {
    commit('SET_PROJECTS_DEPOTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const params = {}
      if (payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      const response = await Axios.get(domain + '/api/all-depots', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_PROJECTS_DEPOTS', response.data.data)

      commit('SET_PROJECTS_DEPOTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_DEPOTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectDepotCommercialTerrain({ commit }, payload) {
    commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload.value + '/coms-terrain',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_DEPOTS_COMMERCIAL_TERRAIN', {
        response: response.data.data,
        depot: payload
      })

      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_USERS_COMMERCIALS_ITE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetProjectError({ commit }) {
    commit('SET_PROJECTS_CREATE_ERROR')
  },
  // custom Filters
  async saveFilter({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(domain + '/api/pfilters', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SAVE_CUSTOM_FILTERS', response.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchSaveFilters({ commit }) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    const params = { exclude: 'conditions', for_visibility: 0 }
    try {
      const response = await Axios.get(domain + '/api/pfilters', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_CUSTOM_FILTERS', response.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteSaveFilters({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.delete(domain + '/api/pfilters/' + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('DELETE_CUSTOM_FILTERS', payload)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async activeDesactiveSwitchSousCategory({ commit }, payload) {
    commit('SET_PROJECTS_DEPOTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/projets/' +
          payload.project_id +
          '/subcategories/' +
          payload.sousCategorie.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ETAT_SWITCH', { payload, response: response.data.data })

      commit('SET_PROJECTS_DEPOTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_DEPOTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectRouteDynamic({ commit }, route) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + route, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_ROUTE_DYNAMIC', response.data.data)

      commit('SET_ROUTE_DYNAMIC_PROCESSING')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectCommSed({ commit }) {
    commit('SET_COMM_SED_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-sed-not-deleted', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_COMM_SED', response.data.data)

      commit('SET_COMM_SED_PROCESSING')
    } catch (error) {
      commit('SET_COMM_SED_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectPrevisiteur({ commit }) {
    commit('SET_PREVISITEUR_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/coms-itenerant-not-deleted',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PREVISITEUR', response.data.data)

      commit('SET_PREVISITEUR_PROCESSING')
    } catch (error) {
      commit('SET_PREVISITEUR_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectAmoCommercialIte({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload + '/coms-itenerant-not-deleted',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_AMO_COMMERCIAL_ITE', response.data.data)

      commit('SET_ROUTE_DYNAMIC_PROCESSING')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectTechniciensTravaux({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/depots/' + payload + '/tech-travaux-not-deleted',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_TECH_TRAVAUX', response.data.data)
      commit('SET_ROUTE_DYNAMIC_PROCESSING')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectPoseur({ commit }) {
    commit('SET_POSEUR_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/coms-terrain-not-deleted',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_POSEUR', response.data.data)

      commit('SET_POSEUR_PROCESSING')
    } catch (error) {
      commit('SET_POSEUR_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectSources({ commit }) {
    commit('SET_SOURCE_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/sources-ids-names', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_SOURCES', response.data.data)

      commit('SET_SOURCE_PROCESSING')
    } catch (error) {
      commit('SET_SOURCE_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectIngenieur({ commit }) {
    commit('SET_INGENIEUR_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/ingenieur-not-deleted', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_INGENIEUR', response.data.data)

      commit('SET_INGENIEUR_PROCESSING')
    } catch (error) {
      commit('SET_INGENIEUR_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectCommercial({ commit }) {
    commit('SET_COMMERCIAL_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/coms-regie-not-deleted', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_COMMERCIAL', response.data.data)

      commit('SET_COMMERCIAL_PROCESSING')
    } catch (error) {
      commit('SET_COMMERCIAL_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchUsersRespPlanning({ commit }) {
    commit('SET_RESP_PLANNNING_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/resp-plan-not-deleted', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_RESP_PLANNNING', response.data.data)

      commit('SET_RESP_PLANNNING_PROCESSING')
    } catch (error) {
      commit('SET_RESP_PLANNNING_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectTypesPixel({ commit }) {
    commit('SET_PROJECT_TYPES_PIXEL_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/pixel-types', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECT_TYPES_PIXEL', response.data.data)

      commit('SET_PROJECT_TYPES_PIXEL_PROCESSING')
    } catch (error) {
      commit('SET_PROJECT_TYPES_PIXEL_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectsTypePixel({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/projets/' + payload + '/pixel-types',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_TYPES_PIXELS', response.data)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async injectionPixel({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/projets/' +
          payload.project.id +
          '/pixel-types/' +
          payload.pixel.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_INJECTION_PIXEL', {
        pixel: payload.pixel,
        response: response.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectsHistoryPixel({ commit }, payload) {
    commit('SET_PROJECT_HISTORY_PIXEL_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/projets/' + payload + '/pixel-history',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECT_HISTORY_PIXEL', response.data.data)

      commit('SET_PROJECT_HISTORY_PIXEL_PROCESSING')
    } catch (error) {
      commit('SET_PROJECT_HISTORY_PIXEL_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorProject({ commit }) {
    commit('SET_PROJECTS_ERROR')
  },

  async fetchRouteDynamicProjectTH({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + payload.route.link, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_ROUTE_DYNAMIC_PROJECT_TH', {
        response: response.data.data,
        project: payload.project,
        route: payload.route
      })

      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async ajouterChampsSelectDynamic({ commit }, payload) {
    commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + payload.link.link,
        { name: payload.name },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ADD_CHAMP_SELECT_PROJECT_TH', {
        response: response.data.data,
        project: payload.project,
        route: payload.link
      })
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
    } catch (error) {
      commit('SET_ROUTE_DYNAMIC_PROCESSING_PROJECT_TH')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjectTH({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')

    try {
      let data = { [payload.field]: payload.value }
      const response = await Axios.put(
        domain + '/api/projets-th/' + payload.entity.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECT_TH', {
        entity: payload.entity,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async createProjectTh({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/projets-th',
        {},
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_PROJECTS_PROJECT', {
        id_dtype: 112,
        currentType: payload.currentType,
        response: response.data.data
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async uploadFileExcel({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    var bodyFormData = new FormData()
    bodyFormData.append('file', payload.files)
    bodyFormData.append('type_id', payload.type_id)
    try {
      await Axios.post(
        domain + '/api/import-reno-glob-projects',
        bodyFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteProjectTh({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      await Axios.delete(domain + `/api/projets-th/` + payload.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PROJECT_TH', payload)

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async listCommentColumnDynamique({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        `${domain}/api/thprojets/${payload.project.id}/comments?column=${payload.column}`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_COMMENT_DYNAMIC_PROJECT_TH', {
        response: response.data.data,
        project: payload.project
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async ajouterCommentDynamique({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.post(
        domain + '/api/thprojets/' + payload.project.id + '/comments',
        { body: payload.body, column: payload.column },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ADD_COMMENT_PROJECT_TH', {
        response: response.data.data,
        project: payload.project
      })
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCommentDynamique({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.delete(
        domain +
          '/api/thprojets/' +
          payload.comment.thprojet_id +
          '/comments/' +
          payload.comment.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_COMMENT_PROJECT_TH', {
        response: response.data.data,
        project: payload.project
      })

      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetPageValue({ commit }, payload) {
    commit('RESET_PAGE_VALUE', { object: payload.object })
  },
  async fetchFieldValueSubcategories({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain +
          '/api/projets/' +
          payload.project_id +
          '/subcategories-fields?dash_field=' +
          payload.field,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_FIELDS_VALUE_SUBCATEGORY', response.data)
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', error)
      }
      return false
    }
    return true
  },
  async downloadPdfRecapTravaux({ commit }, payload) {
    commit('SET_PROJECTS_PROCESSING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/download-recap-travaux/' + payload.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          responseType: 'blob'
        }
      )
      let today = new Date().toLocaleDateString()
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        'Recap-travaux-' +
          moment(today, 'DD/MM/YYYY').format('DD-MM-YYYY') +
          '-' +
          payload.id +
          '.pdf'
      ) //or any other extension
      document.body.appendChild(link)
      link.click()
      commit('DO_NOTHING')
      commit('SET_PROJECTS_PROCESSING')
    } catch (error) {
      commit('SET_PROJECTS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectTypes({ commit }) {
    commit('SET_PROJECTS_TYPES_RENOVATION_LOADING', true)
    commit('SET_PROJECTS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/renovation-options', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_PROJECT_TYPES', response.data.data)
      commit('SET_PROJECTS_TYPES_RENOVATION_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_RENOVATION_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

// {{domain}}/api/crm/search-home?name=dd&type=projet

import Axios from '@/axios'
import domain from '@/environment'
let request = null
const state = {
  searchError: null,
  searchLoading: false,
  searchResult: null
}
const getters = {
  getSearchError: state => state.searchError,
  getSearchLoading: state => state.searchLoading,
  getSearchReasult: state => state.searchResult
}
const mutations = {
  SET_SEARCH_LOADING(state, payload = false) {
    state.searchLoading = payload
  },
  SET_SEARCH_ERROR(state, payload = null) {
    state.searchError = payload
  },
  UPDATE_SEARCH_RESULT(state, payload) {
    state.searchResult = payload
  }
}
const actions = {
  async globalSearch({ commit }, payload) {
    commit('SET_SEARCH_LOADING', true)
    commit('SET_SEARCH_ERROR')
    try {
      const params = {
        per_page: 30,
        page: 1,
        sort_by_asc: 'creation_date'
      }
      if (request) {
        request.cancel()
      }
      request = Axios.CancelToken.source()
      const response = await Axios.post(
        domain + '/api/crm/projets/search',
        {
          conditions: {
            glue: 'and',
            conditions: payload.conditions
          }
        },
        {
          cancelToken: request.token,
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('UPDATE_SEARCH_RESULT', response.data.data)
      commit('SET_SEARCH_LOADING')
    } catch (error) {
      commit('SET_SEARCH_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_SEARCH_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_SEARCH_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

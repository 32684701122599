import Entity from '../Entity'

export default class PixelType extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.substage_pixel_id = data.substage_pixel_id
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('PixelType::create: "data" is undefined')
    }
    return new PixelType(data)
  }
  update(data) {
    this.name = data.name
    this.substage_pixel_id = data.substage_pixel_id
  }
}

import Axios from 'axios'
import Pipeline from '../../models/crm/pipeline/Pipeline'
import Stage from '../../models/crm/pipeline/Stage'
import SubStage from '../../models/crm/pipeline/SubStage'
import domain from '@/environment'

const state = {
  pipelines: [],
  pipelinesLoading: false,
  pipelinesLoadingCategory: false,
  pipelinesLoadingCounter: false,
  pipelinesError: false,
  pipelineCategory: [],
  pipelinecatg: [],
  pipelinesPrincipale: [],
  pipelinesSousStade: []
}
const getters = {
  getPipelinesPipelines: state => state.pipelines,
  getPipelinesLoading: state => state.pipelinesLoading,
  getPipelinesError: state => state.pipelinesError,
  getPipelineCategory: state => state.pipelineCategory,
  getPipelinesPipelinesCateg: state => state.pipelinecatg,
  getPipelinesPipelinesPrincipale: state => state.pipelinesPrincipale,
  getPipelinesCategoryLoading: state => state.pipelinesLoadingCategory,
  getPipelineListeSousStade: state => state.pipelinesSousStade
}
const mutations = {
  SET_PIPELINES_LOADING(state, payload = false) {
    state.pipelinesLoading = payload
  },
  SET_PIPELINES_CATEGORY_LOADING(state, payload = false) {
    state.pipelinesLoadingCategory = payload
  },
  SET_PIPELINES_LOADING_COUNTER(state, payload = false) {
    state.pipelinesLoadingCounter = payload
  },
  SET_PIPELINES_ERROR(state, payload = null) {
    state.pipelinesError = payload
  },

  // fetch pipelines mutations
  SET_PIPELINES_PIPELINES(state, { pipelines }) {
    state.pipelines = pipelines.map(pipeline => Pipeline.create(pipeline))
  },
  SET_PIPELINES_PIPELINES_CATEGORY(state, { pipelines }) {
    state.pipelinecatg = [Pipeline.create(pipelines)]
  },
  SET_PIPELINES_PIPELINES_PRINCIPAL(state, { pipelines }) {
    state.pipelinesPrincipale = [Pipeline.create(pipelines)]
  },
  SET_PIPELINES_CATEGORIES_PIPELINES(state, { pipelines }) {
    state.pipelineCategory = [Pipeline.create(pipelines)]
  },
  SET_PIPELINES_COUNTER(state, { pipelines }) {
    for (let pipeline of state.pipelines) {
      const updatedPipline = pipelines.find(item => item.id == pipeline.id)
      if (updatedPipline.cstages && updatedPipline.cstages.data) {
        for (let stage of pipeline.stages) {
          const updatedStage = updatedPipline.cstages.data.find(
            item => item.id == stage.id
          )
          if (updatedStage) {
            stage.update(updatedStage)
          }
        }
      }
    }
  },
  // pipelines mutations
  ADD_PIPELINES_PIPELINES(state, { pipeline }) {
    if (pipeline.category_id) {
      state.pipelineCategory.push(Pipeline.create(pipeline))
    } else {
      state.pipelines.push(Pipeline.create(pipeline))
    }
  },
  REMOVE_PIPELINES_PIPELINE(state, id) {
    state.pipelines = state.pipelines.filter(pipeline => pipeline.id != id)
    state.pipelineCategory = state.pipelineCategory.filter(
      pipeline => pipeline.id != id
    )
  },
  UPDATE_PIPELINES_PIPELINE(state, { pipeline, data }) {
    pipeline.update(data)
  },

  // stages mutations
  ADD_PIPELINE_STAGES(state, { pipeline, stage }) {
    pipeline.stages.push(Stage.create(stage))
  },
  REMOVE_PIPELINE_STAGES(state, { pipeline, stage }) {
    pipeline.stages = pipeline.stages
      .filter(s => s.id != stage.id)
      .map(s => {
        if (s.order > stage.order) {
          s.order--
        }
        return s
      })
  },
  UPDATE_PIPELINE_STAGES(state, { stage, data }) {
    stage.update(data)
  },

  //subStages mutations
  ADD_PIPELINES_SUB_STAGES(state, { stage, subStage }) {
    stage.subStages.push(SubStage.create(subStage))
  },
  REMOVE_PIPELINES_SUB_STAGES(state, { stage, csubStage, cstage }) {
    if (cstage.id != stage.id) {
      cstage.subStages = cstage.subStages
        .filter(ss => ss.id != csubStage.id)
        .map(ss => {
          if (ss.order > csubStage.order) {
            ss.order--
          }
          return ss
        })
    }
    stage.subStages = stage.subStages
      .filter(ss => ss.id != csubStage.id)
      .map(ss => {
        if (ss.order > csubStage.order) {
          ss.order--
        }
        return ss
      })
  },
  UPDATE_PIPELINES_SUB_STAGES(state, { subStage, data }) {
    subStage.update(data)
  },
  SET_PIPELINES_LIST_SUBSTAGE(state, payload) {
    state.pipelinesSousStade = payload
  },
  SET_PIPELINES_CATEGORY(state, payload) {
    payload.pipeline.table_category.push(...payload.response)
  },
  SET_PIPELINES_DO_NOTHING() {}
}
const actions = {
  // fetch Pipelines
  async fetchPipelinesPipelines({ commit }, payload) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const params = {}
      if (payload && payload.is_hidden && payload.is_hidden == 1) {
        params.is_hidden = payload.is_hidden
      }
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      const response = await Axios.get(domain + '/api/crm/cpipeline', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_PIPELINES_PIPELINES', { pipelines: response.data.data })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchPipelinesPipelinesCategory({ commit }, payload) {
    commit('SET_PIPELINES_CATEGORY_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/crm/cpipeline/' + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PIPELINES_PIPELINES_CATEGORY', {
        pipelines: response.data.data
      })
      commit('SET_PIPELINES_CATEGORY_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_CATEGORY_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchPipelinesPipelinesPrincipal({ commit }, payload) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/crm/cpipeline/' + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PIPELINES_PIPELINES_PRINCIPAL', {
        pipelines: response.data.data
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updatePipelinesCounter({ commit }) {
    commit('SET_PIPELINES_LOADING_COUNTER', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const params = {
        projet_count: 1
      }
      const response = await Axios.get(domain + '/api/crm/cpipeline', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_PIPELINES_COUNTER', { pipelines: response.data.data })
      commit('SET_PIPELINES_LOADING_COUNTER')
    } catch (error) {
      commit('SET_PIPELINES_LOADING_COUNTER')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Pipelines
  async addPipelinesPipeline({ commit }, pipeline) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    const body = {
      name: pipeline.name
    }
    if (pipeline && pipeline.type) {
      body.id_dtype = pipeline.type
    }
    if (pipeline.category_id) {
      body.category_id = pipeline.category_id
    }
    try {
      const response = await Axios.post(domain + '/api/crm/cpipeline', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_PIPELINES_PIPELINES', { pipeline: response.data.data })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletePipelinesPipeline({ commit }, id) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      await Axios.delete(domain + '/api/crm/cpipeline/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PIPELINES_PIPELINE', id)
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updatePipelinesPipeline({ commit }, { pipeline, data }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    const body = {}
    if (data.type) {
      body.id_dtype = data.type
    }
    if (data.name) {
      body.name = data.name
    }
    if (data.pixel_id == 1 || data.pixel_id == 0) {
      body.linked_to_pixel = data.pixel_id
    }
    if (data.is_principal == 1 || data.is_principal == 0) {
      body.is_principal = data.is_principal
    }
    if (data.category) {
      body.category_id = data.id
    }
    try {
      const response = await Axios.put(
        domain + '/api/crm/cpipeline/' + pipeline.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PIPELINES_PIPELINE', {
        pipeline: pipeline,
        data: response.data.data
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  // Stages
  async addPipelinesStages({ commit }, { pipeline, stage }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    const body = {
      name: stage.name,
      order: stage.order,
      status: stage.status,
      id_cpipeline: pipeline.id
    }
    try {
      const response = await Axios.post(domain + '/api/crm/cstage', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_PIPELINE_STAGES', {
        pipeline: pipeline,
        stage: response.data.data
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletePipelinesStages({ commit }, { pipeline, stage }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      await Axios.delete(domain + '/api/crm/cstage/' + stage.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PIPELINE_STAGES', { pipeline, stage })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updatePipelinesStages({ commit }, { stage, data }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/cstage/' + stage.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PIPELINE_STAGES', { stage, data: response.data.data })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedPipelineStageSwitch({ commit }, { data }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      await Axios.delete(domain + '/api/crm/soft-delete-cstage', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        data
      })
      commit('SET_PIPELINES_DO_NOTHING')
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // SubStages
  async addPipelinesSubStages({ commit }, { stage, data }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.post(domain + '/api/crm/csubstage', data, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_PIPELINES_SUB_STAGES', {
        stage,
        subStage: response.data.data
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletePipelinesSousStages(
    { commit },
    { stage, substage, csubStage, cstage }
  ) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      await Axios.delete(
        domain +
          '/api/crm/csubstage/' +
          csubStage.id +
          '?stage_id=' +
          stage.id +
          '&substage_id=' +
          substage.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_PIPELINES_SUB_STAGES', {
        stage,
        csubStage,
        cstage
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updatePipelinesSubStages({ commit }, { subStage, data }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.put(
        domain + '/api/crm/csubstage/' + subStage.id,
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PIPELINES_SUB_STAGES', {
        subStage,
        data: response.data.data
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchPipelinesListeSubStages({ commit }, payload) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/csubstages-ids-pipeline?pipeline_id=' + payload,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PIPELINES_LIST_SUBSTAGE', response.data.data)
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deletedPipelineSubStageSwitch({ commit }, { subStage, data }) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.delete(
        domain + '/api/crm/soft-delete-csubstage',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          data
        }
      )
      commit('UPDATE_PIPELINES_SUB_STAGES', {
        subStage,
        data: response.data.data
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  // Pipeline Category
  async fetchPipelinesCategory({ commit }, payload) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/cpipelines/' + payload.id + '/possible-categories',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PIPELINES_CATEGORY', {
        response: response.data.data,
        pipeline: payload
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchPipelinesCategoriesPipelines({ commit }, payload) {
    commit('SET_PIPELINES_LOADING', true)
    commit('SET_PIPELINES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/categories/' + payload + '/cpipelines',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PIPELINES_CATEGORIES_PIPELINES', {
        pipelines: response.data.data
      })
      commit('SET_PIPELINES_LOADING')
    } catch (error) {
      commit('SET_PIPELINES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PIPELINES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PIPELINES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorPipeline({ commit }) {
    commit('SET_PIPELINES_ERROR')
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

import Vue from 'vue'

// b-modal
import { BModal, VBModal } from 'bootstrap-vue'
Vue.component('b-modal', BModal)
Vue.directive('b-modal', VBModal)

// b-form-group
import { BFormGroup } from 'bootstrap-vue'
Vue.component('b-form-group', BFormGroup)

// b-form-input
import { BFormInput } from 'bootstrap-vue'
Vue.component('b-form-input', BFormInput)

// b-form-input
import { BFormTextarea } from 'bootstrap-vue'
Vue.component('b-form-textarea', BFormTextarea)

// b-form-select
import { BFormSelect } from 'bootstrap-vue'
Vue.component('b-form-select', BFormSelect)

// b-form-select-option
import { BFormSelectOption } from 'bootstrap-vue'
Vue.component('b-form-select-option', BFormSelectOption)

//b-form-select-option-group
import { BFormSelectOptionGroup } from 'bootstrap-vue'
Vue.component('b-form-select-option-group', BFormSelectOptionGroup)

// b-form-checkbox-group
import { BFormCheckboxGroup } from 'bootstrap-vue'
Vue.component('b-form-checkbox-group', BFormCheckboxGroup)

// b-form-checkbox
import { BFormCheckbox } from 'bootstrap-vue'
Vue.component('b-form-checkbox', BFormCheckbox)

// b-spinner
import { BSpinner } from 'bootstrap-vue'
Vue.component('b-spinner', BSpinner)

// b-button
import { BButton } from 'bootstrap-vue'
Vue.component('b-button', BButton)

//b-form-radio-group
import { BFormRadioGroup } from 'bootstrap-vue'
Vue.component('b-form-radio-group', BFormRadioGroup)

//b-form-radio
import { BFormRadio } from 'bootstrap-vue'
Vue.component('b-form-radio', BFormRadio)

// b-badge
import { BBadge } from 'bootstrap-vue'
Vue.component('b-badge', BBadge)

//b-row
import { BRow } from 'bootstrap-vue'
Vue.component('b-row', BRow)

//b-col
import { BCol } from 'bootstrap-vue'
Vue.component('b-col', BCol)

//b-list-group
import { BListGroup } from 'bootstrap-vue'
Vue.component('b-list-group', BListGroup)

//b-list-group-item
import { BListGroupItem } from 'bootstrap-vue'
Vue.component('b-list-group-item', BListGroupItem)

//b-card
import { BCard } from 'bootstrap-vue'
Vue.component('b-card', BCard)

//b-collapse
import { BCollapse } from 'bootstrap-vue'
Vue.component('b-collapse', BCollapse)
//b-form-file
import { BFormFile } from 'bootstrap-vue'
Vue.component('b-form-file', BFormFile)
//b-form-datepicker
import { BFormDatepicker } from 'bootstrap-vue'
Vue.component('b-form-datepicker', BFormDatepicker)

import { ToastPlugin } from 'bootstrap-vue'
Vue.use(ToastPlugin)

//b-pagination
import { BPagination } from 'bootstrap-vue'
Vue.component('b-pagination', BPagination)
//b-table
import { BTable } from 'bootstrap-vue'
Vue.component('b-table', BTable)
//b-button-group
import { BButtonGroup } from 'bootstrap-vue'
Vue.component('b-button-group', BButtonGroup)
//b-table-simple
import { BTableSimple } from 'bootstrap-vue'
Vue.component('b-table-simple', BTableSimple)
//b-tr
import { BTr } from 'bootstrap-vue'
Vue.component('b-tr', BTr)
//b-th
import { BTh } from 'bootstrap-vue'
Vue.component('b-th', BTh)
//b-td
import { BTd } from 'bootstrap-vue'
Vue.component('b-td', BTd)
//b-thead
import { BThead } from 'bootstrap-vue'
Vue.component('b-thead', BThead)
//b-tbody
import { BTbody } from 'bootstrap-vue'
Vue.component('b-tbody', BTbody)

import { BTabs } from 'bootstrap-vue'
Vue.component('b-tabs', BTabs)

import { BTab } from 'bootstrap-vue'
Vue.component('b-tab', BTab)

import { BDropdown } from 'bootstrap-vue'
Vue.component('b-dropdown', BDropdown)

import { BDropdownItem } from 'bootstrap-vue'
Vue.component('b-dropdown-item', BDropdownItem)
//b-nav
import { BNav } from 'bootstrap-vue'
Vue.component('b-nav', BNav)
//b-nav-item
import { BNavItem } from 'bootstrap-vue'
Vue.component('b-nav-item', BNavItem)
//b-input-group
import { BInputGroup } from 'bootstrap-vue'
Vue.component('b-input-group', BInputGroup)
//b-input-group-append
import { BInputGroupAppend } from 'bootstrap-vue'
Vue.component('b-input-group-append', BInputGroupAppend)

import { VBToggle } from 'bootstrap-vue'
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-toggle', VBToggle)

import { BTooltip } from 'bootstrap-vue'
Vue.component('b-tooltip', BTooltip)

import { VBTooltip } from 'bootstrap-vue'
// Note: Vue automatically prefixes the directive name with 'v-'
Vue.directive('b-tooltip', VBTooltip)

// b-sidebar
import { BSidebar } from 'bootstrap-vue'
Vue.component('b-sidebar', BSidebar)

import Echo from 'laravel-echo'
import store from './store'
// import Pusher from 'pusher-js'

window.pusher = require('pusher-js')
// Pusher.logToConsole = true

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '7b7698fabb025c99ddcc',
  encrypt: true,
  cluster: 'eu'
})

const getnotificationPusher = function() {
  if (store.state && store.state.currentUser && store.state.currentUser.id) {
    window.Echo.channel('notification').listen(
      '.room_' + store.state.currentUser.id,
      e => {
        store.state.notifications.notificationList = store.state.notifications.notificationList.filter(
          notif => notif.activity_id != e.activity_id
        )
        store.state.notifications.notificationList.unshift(e)
        store.state.notifications.notVuNotif.unshift(e)
      }
    )
  }
}
const deconnecte = function() {
  window.Echo.leave('notification')
}
export default {
  getnotificationPusher,
  deconnecte
}

<template>
  <div id="app">
    <router-view />
    <portal-target name="project-modal" :transition="fadeTransition">
    </portal-target>
    <portal-target name="new-project-modal" :transition="fadeTransition">
    </portal-target>
  </div>
</template>

<script>
import pusher from './pusher'
export default {
  computed: {
    fadeTransition() {
      return {
        functional: true,
        render(h, context) {
          return h(
            'transition',
            { props: { name: 'slide-fade', mode: 'out-in' } },
            context.children
          )
        }
      }
    }
  },
  mounted() {
    pusher.getnotificationPusher()
  }
}
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease-in-out;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(500px);
  opacity: 0;
}
</style>

import Entity from '../Entity'

export default class ProjectType extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.projectsCount = null
    this.fields = []
    if (data.fields && data.fields.data) {
      this.fields = data.fields.data
    }
    this.subType = []
    this.visible = false
  }

  /**
   * @param { id, name, projet_count} data
   */
  static create(data) {
    if (!data) {
      throw new Error('ProjectType::create: "data" is undefined')
    }
    return new ProjectType(data)
  }

  update(data) {
    this.name = data.name
    this.projectsCount = data.projet_count
    this.fields = []
    if (data.fields && data.fields.data) {
      this.fields = data.fields.data
    }
  }
}

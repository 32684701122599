import Axios from 'axios'
import ProjectType from '@/models/crm/ProjectType'
import domain from '@/environment'
import ProjectSubType from '@/models/crm/ProjectSubTypes'

const state = {
  projectTypes: [],
  customFieldProject: [],
  projectTypesLoading: false,
  projectTypesError: null,
  listCategorieInTable: [],
  listCategorieProjet: [],
  listSubCategorie: [],
  typeProjetPrincipal: []
}
const getters = {
  getProjectsTypes: state => state.projectTypes,
  getProjectsTypesLoading: state => state.projectTypesLoading,
  getProjectsTypesError: state => state.projectTypesError,
  getCustomFieldProject: state => state.customFieldProject,
  getListCategorieInTable: state => state.listCategorieInTable,
  getListCategorieProjet: state => state.listCategorieProjet,
  getListSubCategorie: state => state.listSubCategorie,
  getListTypeProjetPrincipal: state => state.typeProjetPrincipal
}
const mutations = {
  SET_PROJECTS_TYPES_LOADING(state, payload = false) {
    state.projectTypesLoading = payload
  },
  SET_PROJECTS_TYPES_ERROR(state, payload = null) {
    state.projectTypesError = payload
  },
  SET_PROJECTS_TYPES(state, payload) {
    state.projectTypes = payload.map(t => ProjectType.create(t))
  },
  SET_TYPE_PROJECT_PRINCIPAL(state, payload) {
    state.typeProjetPrincipal = payload
  },
  SET_PROJECTS_TYPES_COUNTER(state, payload) {
    for (let type of state.projectTypes) {
      const updatedType = payload.find(item => item.id == type.id)
      if (updatedType) {
        type.update(updatedType)
      }
    }
  },
  ADD_PROJECTS_TYPE(state, payload) {
    state.projectTypes.push(ProjectType.create(payload))
  },
  UPDATE_PROJECTS_TYPE(state, payload) {
    state.projectTypes = state.projectTypes.map(item =>
      item.id == payload.id ? ProjectType.create(payload) : item
    )
  },
  REMOVE_PROJECTS_TYPE(state, id) {
    state.projectTypes = state.projectTypes.filter(p => p.id != id)
  },
  SET_CUSTOM_FIELD_PROJECT(state, payload) {
    state.customFieldProject = payload
  },
  SET_PROJECTS_SUBTYPES(state, { type, sousType }) {
    type.subType = sousType.map(t => ProjectSubType.create(t))
  },
  ADD_SUB_TYPES(state, { type, sousType }) {
    type.subType.push(ProjectSubType.create(sousType))
  },
  REMOVE_SUB_TYPES(state, { type, sousType }) {
    type.subType = type.subType
      .filter(c => c.id != sousType.id)
      .map(c => {
        if (c.order > sousType.order) {
          c.order--
        }
        return c
      })
  },
  UPDATE_SUB_TYPES(state, { sousType, data }) {
    sousType.update(data)
  },
  UPDATE_SUB_TYPES_SUB_CATEGORIES(state, { sousType, data }) {
    sousType.subcategories = data.subcategories
    if (data.subcategories.data && data.subcategories.data.length > 0) {
      for (let i = 0; i < data.subcategories.data.length; i++) {
        if (
          !sousType.selectedCategorie.find(
            item => item.id === data.subcategories.data[i].category.data.id
          )
        ) {
          sousType.selectedCategorie.push(
            data.subcategories.data[i].category.data
          )
          for (
            let j = 0;
            j <
            data.subcategories.data[i].category.data.subcategories.data.length;
            j++
          ) {
            sousType.listSubCategorie.push(
              data.subcategories.data[i].category.data.subcategories.data[j]
            )
          }
        }
      }
    }
  },
  SET_LIST_CATEGORIE_IN_TABLE(state, { ListCategorie }) {
    state.listCategorieInTable = ListCategorie
  },
  SET_LIST_CATEGORIE_PROJET(state, { ListCategorie }) {
    state.listCategorieProjet = ListCategorie
  },
  SET_SUB_CATEGORIE_FOR_CATEGORIE(state, { ListSubCategorie, sousType }) {
    sousType.listSubCategorie = ListSubCategorie
  },
  SET_SUB_CATEGORIE_FOR_CATEGORIES(state, { ListSubCategorie }) {
    state.listSubCategorie = ListSubCategorie
  }
}
const actions = {
  async fetchProjetsTypes({ commit }, payload) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      const params = {}
      if (payload && payload.sort_by) {
        params.sort_by = payload.sort_by
      }
      if (payload && payload.full_name) {
        params.full_name = payload.full_name
      }
      if (payload && payload.sort_by_desc) {
        params.sort_by_desc = payload.sort_by_desc
      }
      if (payload && payload.exclude) {
        params.exclude = payload.exclude.join()
      }
      const response = await Axios.get(
        domain + '/api/crm/dtype?include=fields',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_PROJECTS_TYPES', response.data.data)
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjetsTypesCounter({ commit }) {
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      const params = {
        exclude: 'fields',
        projet_count: 1
      }
      const response = await Axios.get(domain + '/api/crm/dtype', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params: params
      })
      commit('SET_PROJECTS_TYPES_COUNTER', response.data.data)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addProjectType({ commit }, payload) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    const body = {
      name: payload.name,
      fields: payload.selected,
      copy_dtype_id: payload.selectedTypeProjet
        ? payload.selectedTypeProjet.id
        : payload.selectedTypeProjet
    }
    try {
      const response = await Axios.post(domain + '/api/crm/dtype', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_PROJECTS_TYPE', response.data.data)
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateProjectType({ commit }, payload) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    const body = {
      name: payload.name,
      fields: payload.selected
    }
    try {
      const response = await Axios.put(
        domain + '/api/crm/dtype/' + payload.id,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_PROJECTS_TYPE', response.data.data)
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteProjectType({ commit }, id) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      await Axios.delete(domain + '/api/crm/dtype/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_PROJECTS_TYPE', id)
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjectTypePrincipal({ commit }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      const response = await Axios.get(domain + '/api/all-dtypes-principale', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_TYPE_PROJECT_PRINCIPAL', response.data.data)
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchCustomFieldProject({ commit }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/fields?default=0&table_id=42',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_CUSTOM_FIELD_PROJECT', response.data.data)
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchProjetsTypessubtypes({ commit }, payload) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/types/' + payload.id + '/subtypes',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_PROJECTS_SUBTYPES', {
        type: payload,
        sousType: response.data.data
      })
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async addSubType({ commit }, { type, sousType }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    const body = {
      name: sousType.name,
      sub_categories: sousType.sub_categories
    }
    try {
      const response = await Axios.post(
        domain +
          '/api/types/' +
          type.id +
          '/subtypes?include=subcategories.category.subcategories',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('ADD_SUB_TYPES', {
        type: type,
        sousType: response.data.data
      })
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteSubType({ commit }, { type, sousType }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      await Axios.delete(
        domain +
          '/api/types/' +
          type.id +
          '/subtypes/' +
          sousType.id +
          '?include=subcategories',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('REMOVE_SUB_TYPES', { type, sousType })
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateSubType({ commit }, { type, sousType, data }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')

    try {
      const response = await Axios.put(
        domain +
          '/api/types/' +
          type.id +
          '/subtypes/' +
          sousType.id +
          '?include=subcategories',
        data,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_SUB_TYPES', { sousType, data: response.data.data })
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchSubCategorieForSubType({ commit }, { type, sousType }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')

    try {
      const response = await Axios.get(
        domain +
          '/api/types/' +
          type.id +
          '/subtypes/' +
          sousType.id +
          '?include=subcategories.category.subcategories',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_SUB_TYPES_SUB_CATEGORIES', {
        sousType,
        data: response.data.data
      })
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListCategorieInTable({ commit }, { idTable, include }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    const params = {}

    if (include != null) {
      params.include = include
    }
    try {
      const response = await Axios.get(
        domain + '/api/tables/' + idTable.id + '/categories',

        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params: params
        }
      )
      commit('SET_LIST_CATEGORIE_IN_TABLE', {
        ListCategorie: response.data.data
      })
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListCategorieProjets({ commit }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/tables/42/list-categories',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_LIST_CATEGORIE_PROJET', {
        ListCategorie: response.data.data
      })
      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchSubCategorieForCategorie({ commit }, { event, sousType }) {
    commit('SET_PROJECTS_TYPES_LOADING', true)
    commit('SET_PROJECTS_TYPES_ERROR')
    try {
      let table = []
      for (let step = 0; step < event.length; step++) {
        const response = await Axios.get(
          domain +
            '/api/categories/' +
            event[step].id +
            '/subcategories?include=subtype',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        table.push(...response.data.data)
      }

      if (sousType) {
        commit('SET_SUB_CATEGORIE_FOR_CATEGORIE', {
          sousType: sousType,
          ListSubCategorie: table
        })
      } else {
        if (!sousType) {
          commit('SET_SUB_CATEGORIE_FOR_CATEGORIES', {
            //listSelectd: event
            ListSubCategorie: table
          })
        }
      }

      commit('SET_PROJECTS_TYPES_LOADING')
    } catch (error) {
      commit('SET_PROJECTS_TYPES_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_PROJECTS_TYPES_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_PROJECTS_TYPES_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  resetErrorProjectType({ commit }) {
    commit('SET_PROJECTS_TYPES_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

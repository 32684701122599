import Axios from 'axios'
import domain from '@/environment'

const state = {
  customFields: [],
  customFieldsTypes: [],
  customFieldsMenu: [],
  customFieldsError: null,
  customFieldsLoading: false,
  custemFieldsSubCategorie: null,
  custemFieldsSubCategorieList: [],
  allSubTypes: []
}
const getters = {
  getcustomFields: state => state.customFields,
  getAllTypescustomFields: state => state.customFieldsTypes,
  getCustomFieldsMenu: state => state.customFieldsMenu,
  getCustomFieldsError: state => state.customFieldsError,
  getCustomFieldsLoading: state => state.customFieldsLoading,
  getCustemFieldsSubCategorie: state => state.custemFieldsSubCategorie,
  getCustemFieldsSubCategorieList: state => state.custemFieldsSubCategorieList,
  getAllSubTypes: state => state.allSubTypes
}
const mutations = {
  SET_CUSTOM_FIELDS_ERROR(state, payload = null) {
    state.customFieldsError = payload
  },
  SET_CUSTOM_FIELDS_LOADING(state, payload = false) {
    state.customFieldsLoading = payload
  },
  //CUSTOM_FIELDS
  ADD_CUSTOM_FIELDS(state, payload) {
    state.customFields.push(payload)
  },
  UPDATE_CUSTOM_FIELDS(state, payload) {
    state.customFields = state.customFields.map(item =>
      item.id == payload.id ? payload : item
    )
  },
  REMOVE_CUSTOM_FIELDS(state, id) {
    let key = false
    for (let index = 0; index < state.customFields.length; index++) {
      const depot = state.customFields[index]
      if (depot.id == id) {
        key = index
        break
      }
    }
    if (key !== false) {
      state.customFields.splice(key, 1)
    }
  },
  SET_ALL_CUSTOM_FIELDS(state, payload) {
    state.customFields = payload
  },
  SET_ALL_Tables_CUSTOM_FIELDS(state, payload) {
    state.customFieldsMenu = payload
  },
  SET_CUSTOM_FIELDS_TYPES(state, payload) {
    state.customFieldsTypes = payload
  },
  UPDATE_CUSTOM_FIELDS_ORDER(state, payload) {
    state.customFields = payload
  },
  FETCH_ONE_CUSTOM_FIELDS(state, payload) {
    state.custemFieldsSubCategorie = payload
    state.custemFieldsSubCategorie.categorie = []
    if (payload.subcategories.data.length > 0) {
      for (let index = 0; index < payload.subcategories.data.length; index++) {
        if (
          !state.custemFieldsSubCategorie.categorie.find(
            item =>
              item.id === payload.subcategories.data[index].category.data.id
          )
        ) {
          state.custemFieldsSubCategorie.categorie.push(
            payload.subcategories.data[index].category.data
          )
        }
      }
    }
  },
  SET_SUB_CATEGORIE_FOR_CUSTEM_FIELDS(state, { ListSubCategorie }) {
    state.custemFieldsSubCategorieList = ListSubCategorie
  },
  //CUSTOM_FIELDS_OPTIONS
  ADD_CUSTOM_FIELDS_OPTIONS(state, payload) {
    state.customFields = state.customFields.map(p => {
      if (p.id == payload.field_id) {
        p.options.data.push({
          label: payload.label,
          order: payload.order,
          field_id: payload.field_id,
          id: payload.id
        })
      }
      return p
    })
  },
  REMOVE_CUSTOM_FIELDS_OPTIONS(state, payload) {
    state.customFields = state.customFields.map(p => {
      p.options.data = p.options.data.filter(o => {
        return o.id != payload
      })
      return p
    })
  },
  UPDATE_CUSTOM_FIELDS_OPTION_ORDER(state, payload) {
    state.customFields = state.customFields.map(p => {
      if (p.id == payload.id_field) {
        p.options.data = payload.result
      }
      return p
    })
  },
  SET_ALL_SUB_TYPES(state, payload) {
    state.allSubTypes = payload
  }
}
const actions = {
  async addCustomFields({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    var body = new URLSearchParams()

    body.append('name', payload.name)
    body.append('field_type', payload.field_type)
    body.append('table_id', payload.id)
    if (payload.sub_types) {
      for (const index in payload.sub_types) {
        body.append('sub_types[' + index + ']', payload.sub_types[index])
      }
    }
    if (payload.idPixel && payload.idPixel.length) {
      for (let key = 0; key < payload.idPixel.length; key++) {
        body.append(
          'pixel_types[' + key + '][pixel_type_id]',
          payload.idPixel[key].id
        )
        body.append(
          'pixel_types[' + key + '][name_pixel]',
          payload.namePixel[key] ? payload.namePixel[key] : ''
        )
      }
    }
    for (const key in payload.subcategory) {
      body.append(
        'sub_categories[' + key + '][subcategory_id]',
        payload.subcategory[key].subcategory_id
      )
      body.append(
        'sub_categories[' + key + '][is_subcategory_header]',
        payload.subcategory[key].is_subcategory_header
      )
    }
    if (payload.options && payload.options.length) {
      for (const key in payload.options) {
        body.append('options[' + key + ']', payload.options[key])
      }
    }

    try {
      const response = await Axios.post(domain + '/api/fields', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      commit('ADD_CUSTOM_FIELDS', response.data.data)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateCustomFields({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    var body = new URLSearchParams()
    if (payload.name != payload.label) {
      body.append('name', payload.name)
    }
    if (payload.idPixel && payload.idPixel.length) {
      for (let key = 0; key < payload.idPixel.length; key++) {
        body.append(
          'pixel_types[' + key + '][pixel_type_id]',
          payload.idPixel[key].id
        )
        body.append(
          'pixel_types[' + key + '][name_pixel]',
          payload.namePixel[key] && payload.namePixel[key].name_pixel
            ? payload.namePixel[key].name_pixel
            : ''
        )
      }
    }
    if (payload.sub_categories && payload.sub_categories.length) {
      for (const key in payload.sub_categories) {
        body.append(
          'sub_categories[' + key + '][subcategory_id]',
          payload.sub_categories[key].subcategory_id
        )
        body.append(
          'sub_categories[' + key + '][is_subcategory_header]',
          payload.sub_categories[key].is_subcategory_header
        )
      }
    }

    if (payload.sub_types && payload.sub_types.length) {
      for (const index in payload.sub_types) {
        body.append('sub_types[' + index + ']', payload.sub_types[index])
      }
    }
    try {
      const response = await Axios.put(
        domain +
          '/api/fields/' +
          payload.id +
          '?include=subcategories,subtypes',
        body,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_CUSTOM_FIELDS', response.data.data)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCustomFields({ commit }, id) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    try {
      await Axios.delete(domain + '/api/fields/' + id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_CUSTOM_FIELDS', id)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async ReordonnerCustomFields({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    const body = {
      order: payload.order
    }
    try {
      await Axios.put(domain + '/api/fields/' + payload.id, body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')

      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchAllCustomFields({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    commit('SET_ALL_CUSTOM_FIELDS', [])
    try {
      const params = {}
      if (payload && payload.sort_by) {
        params.sort_by = payload.sort_by
      }
      if (payload && payload.sort_by_desc) {
        params.sort_by_desc = payload.sort_by_desc
      }
      const response = await Axios.get(
        domain +
          '/api/fields?table_id=' +
          payload.table_id +
          '&default=1&include=subcategories,subtypes',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params
        }
      )
      const response1 = await Axios.get(
        domain +
          '/api/fields?table_id=' +
          payload.table_id +
          '&default=0&include=subcategories,subtypes',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          },
          params
        }
      )
      let table = response.data.data.concat(response1.data.data)
      commit('SET_ALL_CUSTOM_FIELDS', table)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  async fetchAllTablesCustomFields({ commit }, params) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    try {
      const response = await Axios.get(
        domain + '/api/tables?customizable=' + params,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_ALL_Tables_CUSTOM_FIELDS', response.data.data)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchAllCustomFieldsTypes({ commit }) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    try {
      const response = await Axios.get(domain + '/api/fieldtypes?default=0', {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_CUSTOM_FIELDS_TYPES', response.data.data)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  applyDragCustomFields({ commit }, payload) {
    commit('UPDATE_CUSTOM_FIELDS_ORDER', payload)
  },
  async addCustomFieldsOptions({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    const body = {
      label: payload.label,
      field_id: payload.field_id
    }
    try {
      const response = await Axios.post(domain + '/api/options', body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('ADD_CUSTOM_FIELDS_OPTIONS', response.data)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteCustomFieldsOptions({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    try {
      await Axios.delete(domain + '/api/options/' + payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('REMOVE_CUSTOM_FIELDS_OPTIONS', payload)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateCustomFieldsOptions({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    const body = {
      label: payload.label,
      order: payload.order
    }
    try {
      await Axios.put(domain + '/api/options/' + payload.id, body, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchOneCustomFields({ commit }, payload) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')

    try {
      const response = await Axios.get(
        domain +
          '/api/fields/' +
          payload.id +
          '?include=subcategories.category',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('FETCH_ONE_CUSTOM_FIELDS', response.data.data)
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchSubCategorieForCustemFields({ commit }, { event }) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    try {
      let table = []
      for (let step = 0; step < event.length; step++) {
        const response = await Axios.get(
          domain +
            '/api/categories/' +
            event[step].id +
            '?include=subcategories',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        table.push(...response.data.data.subcategories.data)
      }

      commit('SET_SUB_CATEGORIE_FOR_CUSTEM_FIELDS', {
        ListSubCategorie: table
      })

      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchAllSubTypesProject({ commit }) {
    commit('SET_CUSTOM_FIELDS_LOADING', true)
    commit('SET_CUSTOM_FIELDS_ERROR')
    commit('SET_ALL_CUSTOM_FIELDS', [])
    try {
      const response = await Axios.get(
        domain + '/api/crm/dtype?exclude=fields',
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      let table = []
      for (let i = 0; i < response.data.data.length; i++) {
        const response1 = await Axios.get(
          domain + '/api/types/' + response.data.data[i].id + '/subtypes',
          {
            headers: {
              Authorization: `Bearer ${this.state.currentUser.token}`
            }
          }
        )
        table = table.concat(response1.data.data)
        commit('SET_ALL_SUB_TYPES', table)
      }
      commit('SET_CUSTOM_FIELDS_LOADING')
    } catch (error) {
      commit('SET_CUSTOM_FIELDS_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_CUSTOM_FIELDS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_CUSTOM_FIELDS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  applyDragCustomFieldsOptions({ commit }, payload) {
    commit('UPDATE_CUSTOM_FIELDS_OPTION_ORDER', payload)
  },
  resetErrorCustomField({ commit }) {
    commit('SET_CUSTOM_FIELDS_ERROR')
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

import Entity from '../../Entity'
import File from '../File'
export default class CategorieFilesLength extends Entity {
  constructor(data) {
    super(data.id)
    this.count_files = data.count_files
    if (data.files && Array.isArray(data.files) && data.files.length) {
      this.files = data.files.map(item => File.create(item))
    } else {
      this.files = []
    }
    this.name = data.name
    this.order = data.order
  }
  /**
   * @param { id, name,order } data
   */
  static create(data) {
    if (!data) {
      throw new Error('CategorieFilesLength::create: "data" is undefined')
    }
    return new CategorieFilesLength(data)
  }
}

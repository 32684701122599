import Axios from 'axios'
import Notifications from '../../models/crm/Notifications'
import domain from '@/environment'
const state = {
  error: null,
  processing: false,
  notVuNotif: [],
  notificationList: []
}
const getters = {
  getNotificationsError: state => state.error,
  getNotificationsProcessing: state => state.processing,
  getNotification: state => state.notificationList,
  getNotificationNotVu: state => state.notVuNotif
}
const mutations = {
  SET_NOTIFICATIONS_PROCESSING(state, payload = false) {
    state.processing = payload
  },
  SET_NOTIFICATIONS_ERROR(state, payload = null) {
    state.error = payload
  },
  SET_NOTIFICATIONS_LIST(state, payload) {
    if (payload) {
      state.notificationList = payload.map(item => Notifications.create(item))
    }
    state.notVuNotif = state.notificationList.filter(item => item.seen != 1)
  },
  DO_NOTHING() {}
}
const actions = {
  async fetchListNotification({ commit }, payload) {
    commit('SET_NOTIFICATIONS_PROCESSING', true)
    commit('SET_NOTIFICATIONS_ERROR')
    const params = {
      user_id: payload
    }
    try {
      const response = await Axios.get(domain + '/api/notifications', {
        params,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_NOTIFICATIONS_LIST', response.data.data)
      commit('SET_NOTIFICATIONS_PROCESSING')
    } catch (error) {
      commit('SET_NOTIFICATIONS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_NOTIFICATIONS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_NOTIFICATIONS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchListNotificationNotVu({ commit }, payload) {
    commit('SET_NOTIFICATIONS_PROCESSING', true)
    commit('SET_NOTIFICATIONS_ERROR')
    try {
      await Axios.put(domain + '/api/notification-status', payload, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('DO_NOTHING')
      commit('SET_NOTIFICATIONS_PROCESSING')
    } catch (error) {
      commit('SET_NOTIFICATIONS_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_NOTIFICATIONS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_NOTIFICATIONS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

// Visibilities
const isSuper = () => {
  if (store.state.currentUser && store.state.currentUser.isSuper) {
    return true
  }
  return false
}
const isFromRegie = () => {
  if (
    store.state.currentUser &&
    (store.state.currentUser.type == 'user.commercial' ||
      store.state.currentUser.type == 'user.final' ||
      (store.state.currentUser.type == 'user.admin' &&
        store.state.currentUser.details &&
        store.state.currentUser.details.team &&
        store.state.currentUser.details.team.data &&
        store.state.currentUser.details.team.data.type == 'regie' &&
        // possibilite de voir plannings technicien
        store.state.currentUser.id != 61))
  ) {
    return true
  }
  // else if (store.state.currentUser.team_id == 425) {
  //   // filiale : ATELIER E-LEARNING
  //   return true
  // }
  return false
}
const isStocks = () => {
  if (
    store.state.currentUser &&
    store.state.currentUser.details &&
    store.state.currentUser.details.stock == 1
  ) {
    return true
  } else {
    return false
  }
}
const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    children: [
      //  PLANNING AMO
      {
        path: 'planning-amo',
        name: 'planning-amo',
        component: () =>
          import(
            /* webpackChunkName: "planning-amo" */ '../views/PlanningAMO/Plannings.vue'
          ),
        children: [
          {
            path: 'agence/:id_depot',
            name: 'planning-amo-id_depot',
            component: () =>
              import(
                /* webpackChunkName: "planning-amo-id_depot" */ '../views/PlanningAMO/Plannings.vue'
              ),
            children: [
              {
                path: 'mois/:id_month',
                name: 'planning-amo-id_month',
                component: () =>
                  import(
                    /* webpackChunkName: "planning-amo-id_month" */ '../views/PlanningAMO/Plannings.vue'
                  ),
                children: [
                  {
                    path: 'jour/:id_day',
                    name: 'planning-amo-id_day',
                    component: () =>
                      import(
                        /* webpackChunkName: "planning-amo-id_day" */ '../views/PlanningAMO/Plannings.vue'
                      )
                  }
                ]
              }
            ]
          }
        ],
        beforeEnter: (to, from, next) => {
          if (store.state.currentUser) {
            next()
          } else {
            next('/')
          }
        }
      },
      //  PLANNING VISITEUR TECHNIQUE
      {
        path: 'planning-visiteur-tech',
        name: 'planning-visiteur-tech',
        component: () =>
          import(
            /* webpackChunkName: "planning-visiteur-tech" */ '../views/PlanningVisiteurTech/PlanningVisiteurTech.vue'
          ),
        children: [
          {
            path: 'agence/:id_depot',
            name: 'planning-visiteur-tech-id_depot',
            component: () =>
              import(
                /* webpackChunkName: "planning-visiteur-tech-id_depot" */ '../views/PlanningVisiteurTech/PlanningVisiteurTech.vue'
              ),
            children: [
              {
                path: 'mois/:id_month',
                name: 'planning-visiteur-tech-id_month',
                component: () =>
                  import(
                    /* webpackChunkName: "planning-visiteur-tech-id_month" */ '../views/PlanningVisiteurTech/PlanningVisiteurTech.vue'
                  ),
                children: [
                  {
                    path: 'jour/:id_day',
                    name: 'planning-visiteur-tech-id_day',
                    component: () =>
                      import(
                        /* webpackChunkName: "planning-visiteur-tech-id_day" */ '../views/PlanningVisiteurTech/PlanningVisiteurTech.vue'
                      )
                  }
                ]
              }
            ]
          }
        ],
        beforeEnter: (to, from, next) => {
          if (store.state.currentUser) {
            next()
          } else {
            next('/')
          }
        }
      },
      // PLANNING POSEURS
      {
        path: 'planning-pose',
        name: 'planning-pose',
        component: () =>
          import(
            /* webpackChunkName: "PlanningPoseurs" */ '../views/PlanningPoseurs/PlanningPoseurs.vue'
          ),
        children: [
          {
            path: 'agence/:id_depot',
            name: 'planning-pose-id_depot',
            component: () =>
              import(
                /* webpackChunkName: "planning-pose-id_depot" */ '../views/PlanningPoseurs/PlanningPoseurs.vue'
              ),
            children: [
              {
                path: 'mois/:id_month',
                name: 'planning-pose-id_month',
                component: () =>
                  import(
                    /* webpackChunkName: "planning-pose-id_month" */ '../views/PlanningPoseurs/PlanningPoseurs.vue'
                  ),
                children: [
                  {
                    path: 'jour/:id_day',
                    name: 'planning-pose-id_day',
                    component: () =>
                      import(
                        /* webpackChunkName: "planning-pose-id_day" */ '../views/PlanningPoseurs/PlanningPoseurs.vue'
                      )
                  }
                ]
              }
            ]
          }
        ],
        beforeEnter: (to, from, next) => {
          if (!isFromRegie()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'reporting',
        name: 'Reporting',
        redirect: 'reporting/statistics',
        component: () =>
          import(/* webpackChunkName: "reporting" */ '../views/Reporting'),
        children: [
          {
            path: 'statistics',
            name: 'Statistics',
            component: () =>
              import(
                /* webpackChunkName: "statistics" */ '../views/Reporting/Statistics.vue'
              )
          },
          {
            path: 'terrain',
            name: 'Terrain',
            component: () =>
              import(
                /* webpackChunkName: "terrain" */ '../views/Reporting/Terrain.vue'
              )
          },
          {
            path: 'objectifs',
            name: 'Objectifs',
            component: () =>
              import(
                /* webpackChunkName: "objectifs" */ '../views/Reporting/Objectifs.vue'
              )
          }
        ],
        beforeEnter: (to, from, next) => {
          if (!isFromRegie()) {
            next()
          } else {
            next('/')
          }
        }
      },
      // {
      //   path: 'utils/devis-ite',
      //   name: 'devis-ite',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Devis-ite" */ '../views/Utils/DevisIte.vue'
      //     )
      // },
      // {
      //   path: 'utils/devis-iti',
      //   name: 'devis-iti',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "Devis-iti" */ '../views/Utils/DevisIti.vue'
      //     )
      // },
      // {
      //   path: 'utils/devis-audit',
      //   name: 'devis-audit',
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "devis-audi" */ '../views/Utils/DevisAudit.vue'
      //     )
      // },
      {
        path: 'projects',
        name: 'projects',
        component: () =>
          import(
            /* webpackChunkName: "projects" */ '../views/Projects/index.vue'
          ),
        children: [
          {
            path: ':id',
            name: 'project',
            component: () =>
              import(
                /* webpackChunkName: "project" */ '../views/Projects/index.vue'
              )
          },
          {
            path: ':id/activity',
            name: 'project_activity',
            component: () =>
              import(
                /* webpackChunkName: "project_activity" */ '../views/Projects/index.vue'
              )
          }
        ]
      },
      // stock
      {
        path: 'stock/pac-ballon',
        name: 'PacBallon',
        component: () =>
          import(
            /* webpackChunkName: "pac-ballon" */ '../views/Stock/PacBallon.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (isStocks()) {
            next()
          } else {
            next('/')
          }
        }
      },
      {
        path: 'stock/isolation',
        name: 'Isolation',
        component: () =>
          import(
            /* webpackChunkName: "isolation" */ '../views/Stock/Isolation.vue'
          ),
        beforeEnter: (to, from, next) => {
          if (isStocks()) {
            next()
          } else {
            next('/')
          }
        }
      },
      // users settings and configs
      {
        path: 'password',
        name: 'password',
        component: () =>
          import(
            /* webpackChunkName: "Change_password-password" */ '../views/Setting/Password.vue'
          )
      },
      {
        path: 'setting',
        redirect: 'setting/users',
        component: () =>
          import(
            /* webpackChunkName: "setting" */ '../views/Setting/index.vue'
          ),
        children: [
          {
            path: 'users',
            component: () =>
              import(
                /* webpackChunkName: "users-index" */ '../views/Setting/users/index.vue'
              ),
            children: [
              {
                path: '',
                name: 'users',
                component: () =>
                  import(
                    /* webpackChunkName: "users" */ '../views/Setting/users/Users.vue'
                  )
              },
              {
                path: 'permissions',
                name: 'permission',
                component: () =>
                  import(
                    /* webpackChunkName: "permission" */ '../views/Setting/users/Permission.vue'
                  )
              },
              {
                path: 'corbeilles',
                name: 'corbeille',
                component: () =>
                  import(
                    /* webpackChunkName: "corbeille" */ '../views/Setting/users/Corbeille.vue'
                  )
              },
              {
                path: 'historique-utilisateurs',
                name: 'historique-utilisateurs',
                component: () =>
                  import(
                    /* webpackChunkName: "historique-utilisateurs" */ '../views/Setting/users/HistoriqueUsers.vue'
                  )
              }
            ]
          },

          {
            path: 'visibility',
            component: () =>
              import(
                /* webpackChunkName: "VisibilityRegie-index" */ '../views/Setting/Visibility/index.vue'
              ),
            children: [
              {
                path: '',
                name: 'visibility',
                component: () =>
                  import(
                    /* webpackChunkName: "visibilityRegie" */ '../views/Setting/Visibility/VisibilityRegie.vue'
                  )
              },
              {
                path: 'visibilityPlanning',
                name: 'visibilityPlanning',
                component: () =>
                  import(
                    /* webpackChunkName: "VisibilityPlanning" */ '../views/Setting/Visibility/VisibilityPlanning.vue'
                  )
              }
            ]
          },
          {
            path: 'pipeline',
            name: 'pipeline',
            component: () =>
              import(
                /* webpackChunkName: "setting-pipeline" */ '../views/Setting/Pipeline.vue'
              )
          },
          {
            path: 'pipeline/:id',
            name: 'pipelineUpdate',
            component: () =>
              import(
                /* webpackChunkName: "setting-pipelineUpdate" */ '../views/Setting/PipelineUpdate.vue'
              )
          },
          {
            path: 'projects-types',
            name: 'projectsTypes',
            component: () =>
              import(
                /* webpackChunkName: "setting-projects-types" */ '../views/Setting/ProjectsType.vue'
              )
          },
          {
            path: 'projects-types/:id',
            name: 'projectsTypesUpdate',
            component: () =>
              import(
                /* webpackChunkName: "setting-projects-typesUpdate" */ '../views/Setting/ProjectsTypeUpdate.vue'
              )
          },
          {
            path: 'precarite',
            name: 'Precarite',
            component: () =>
              import(
                /* webpackChunkName: "setting-precarite" */ '../views/Setting/Precarite.vue'
              )
          },
          {
            path: 'visibility-project',
            name: 'Visibility-project',
            component: () =>
              import(
                /* webpackChunkName: "setting-visibility-project" */ '../views/Setting/VisibilityProject.vue'
              )
          },
          {
            path: 'affectation-leads',
            name: 'affectation-leads',
            component: () =>
              import(
                /* webpackChunkName: "setting-affectation-leads" */ '../views/Setting/AffectationLeads.vue'
              )
          },
          {
            path: 'history-export-excel',
            name: 'history-export-excel',
            component: () =>
              import(
                /* webpackChunkName: "setting-history-export-excel" */ '../views/Setting/HistoryExportExcel.vue'
              )
          },
          {
            path: 'webhook',
            name: 'Webhook',
            component: () =>
              import(
                /* webpackChunkName: "setting-webhook" */ '../views/Setting/Webhook.vue'
              )
          },
          {
            path: 'api',
            name: 'Api',
            component: () =>
              import(
                /* webpackChunkName: "setting-api" */ '../views/Setting/Api.vue'
              )
          },
          {
            path: 'sources',
            name: 'Sources',
            component: () =>
              import(
                /* webpackChunkName: "setting-sources" */ '../views/Setting/Sources.vue'
              )
          },
          {
            path: 'teams',
            name: 'Teams',
            component: () =>
              import(
                /* webpackChunkName: "setting-teams" */ '../views/Setting/Teams.vue'
              )
          },
          {
            path: 'deposits',
            name: 'Deposits',
            component: () =>
              import(
                /* webpackChunkName: "setting-deposits" */ '../views/Setting/Deposits.vue'
              )
          },
          {
            path: 'champs-personnalises',
            name: 'champs-personnalises',
            component: () =>
              import(
                /* webpackChunkName: "setting-champs-personnalises" */ '../views/Setting/CustomFields.vue'
              )
          },
          {
            path: 'connection',
            name: 'Connection',
            component: () =>
              import(
                /* webpackChunkName: "setting-connection" */ '../views/Setting/Connection.vue'
              )
          },
          {
            path: 'typePixel',
            name: 'TypePixel',
            component: () =>
              import(
                /* webpackChunkName: "setting-typePixel" */ '../views/Setting/TypePixel.vue'
              )
          },

          // GESTION DES  CATEGORIES
          // CATEGORIES CONTACT
          {
            path: 'categorie-contact',
            name: 'categorieContact',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorie" */ '../views/Setting/Category/Categorie.vue'
              )
          },
          {
            path: 'categorie-contact/:id/sous-categorie',
            name: 'categorieUpdateContact',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorieUpdate" */ '../views/Setting/Category/CategorieUpdate.vue'
              )
          },
          {
            path: 'categorie-contact/:id/sous-categorie/:idSubCategorie',
            name: 'subCategorieContactUpdate',
            component: () =>
              import(
                /* webpackChunkName: "settings-subcategorie-update" */ '../views/Setting/Category/SubCategorieUpdate.vue'
              )
          },
          //CATEGORIES PROJET
          {
            path: 'categorie-projet',
            name: 'categorieProjet',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorie" */ '../views/Setting/Category/Categorie.vue'
              )
          },
          {
            path: 'categorie-projet/:id/sous-categorie',
            name: 'categorieUpdateProjet',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorieUpdate" */ '../views/Setting/Category/CategorieUpdate.vue'
              )
          },
          {
            path: 'categorie-projet/:id/sous-categorie/:idSubCategorie',
            name: 'subCategorieProjetUpdate',
            component: () =>
              import(
                /* webpackChunkName: "settings-subcategorie-update" */ '../views/Setting/Category/SubCategorieUpdate.vue'
              )
          },
          {
            path: 'categorie-projet/:id/pipeline',
            name: 'categorieProjetPipeline',
            component: () =>
              import(
                /* webpackChunkName: "setting-categoriePipeline" */ '../views/Setting/Pipeline.vue'
              )
          },
          {
            path: 'categorie-projet/:id/pipeline/:idPipeline',
            name: 'categorieProjetPipelineUpdate',
            component: () =>
              import(
                /* webpackChunkName: "setting-categoriePipelineUpdate" */ '../views/Setting/PipelineUpdate.vue'
              )
          },

          // CATEGORIES PIECE JOINTE
          {
            path: 'categorie-piece-jointe',
            name: 'categoriePieceJointe',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorie" */ '../views/Setting/Category/Categorie.vue'
              )
          },
          {
            path: 'categorie-piece-jointe/:id/sous-categorie',
            name: 'categorieUpdatePieceJointe',
            component: () =>
              import(
                /* webpackChunkName: "setting-categorieUpdate" */ '../views/Setting/Category/CategorieUpdate.vue'
              )
          },
          /////////////////////////
          {
            path: 'templates',
            component: () =>
              import(
                /* webpackChunkName: "templates-index" */ '../views/Setting/Template/index.vue'
              ),
            children: [
              {
                path: '',
                name: 'templates',
                component: () =>
                  import(
                    /* webpackChunkName: "templates" */ '../views/Setting/Template/TemplateEmail.vue'
                  )
              },
              {
                path: 'templatesSms',
                name: 'templatesSms',
                component: () =>
                  import(
                    /* webpackChunkName: "templatesSms" */ '../views/Setting/Template/TemplateSms.vue'
                  )
              }
            ]
          },
          {
            path: 'history-planning',
            component: () =>
              import(
                /* webpackChunkName: "historiquesPlanning-index" */ '../views/Setting/HistoryPlanning/index.vue'
              ),
            children: [
              {
                path: '',
                name: 'history-planning',
                component: () =>
                  import(
                    /* webpackChunkName: "HistoriquePlanningPoseur" */ '../views/Setting/HistoryPlanning/HistoryPlanningPoseur.vue'
                  )
              },
              {
                path: 'commerciaux',
                name: 'commerciaux',
                component: () =>
                  import(
                    /* webpackChunkName: "HistoriquePlanningCom" */ '../views/Setting/HistoryPlanning/HistoryPlanningCom.vue'
                  )
              }
            ]
          }
        ],
        beforeEnter: (to, from, next) => {
          if (isSuper() || isFromRegie()) {
            next()
          } else {
            next('/')
          }
        }
      },
      // users notification page
      {
        path: 'notifications',
        name: 'notifications',
        component: () =>
          import(
            /* webpackChunkName: "Notifications" */ '../views/Setting/Notifications.vue'
          )
      }
    ],
    beforeEnter: (to, from, next) => {
      if (store.state.currentUser) {
        next()
      } else {
        next('/login')
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: (to, from, next) => {
      if (!store.state.currentUser) {
        next()
      } else {
        next('/')
      }
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/sign',
    name: 'sign',
    component: () => import(/* webpackChunkName: "sign" */ '../views/Sign.vue')
  },
  {
    path: '/error',
    name: 'error',
    component: () =>
      import(/* webpackChunkName: "error" */ '../views/Error.vue')
  },
  {
    path: '*',
    redirect: '/error'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
const DEFAULT_TITLE = 'CRM Abondance'
router.afterEach(() => {
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE
  })
})
export default router

import Entity from '../Entity'
import Pipeline from './pipeline/Pipeline'
import Stage from './pipeline/Stage'
import SubStage from './pipeline/SubStage'
import Comment from './Comment'
import File from './File'
import History from './History'
import Activity from './Activity'

import * as moment from 'moment'
moment.locale('fr')

export default class Project extends Entity {
  /**
   *
   */
  constructor(data) {
    // clean
    super(data.id)
    if (data.header && data.body) {
      this.checked = data.header.checked || false
      if (data.body.contact) {
        this.adresse = data.body.contact.adresse
        this.city = data.body.contact.city
        this.zipCode = data.body.contact.zipCode
        this.categoriesContacts = data.body.contact.categories
        this.air_eau_date_accept = data.body.contact.air_eau_date_accept
          ? moment(
              data.body.contact.air_eau_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.air_eau_date_accept
        this.air_eau_prime = data.body.contact.air_eau_prime
        this.audit_ener_date_accept = data.body.contact.audit_ener_date_accept
          ? moment(
              data.body.contact.audit_ener_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.audit_ener_date_accept
        this.audit_ener_prime = data.body.contact.audit_ener_prime
        this.ballon_thermo_date_accept = data.body.contact
          .ballon_thermo_date_accept
          ? moment(
              data.body.contact.ballon_thermo_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.ballon_thermo_date_accept
        this.ballon_thermo_prime = data.body.contact.ballon_thermo_prime
        this.bar_en_101_date_accept = data.body.contact.bar_en_101_date_accept
          ? moment(
              data.body.contact.bar_en_101_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_en_101_date_accept
        this.bar_en_101_prime = data.body.contact.bar_en_101_prime
        this.bar_en_102_date_accept = data.body.contact.bar_en_102_date_accept
          ? moment(
              data.body.contact.bar_en_102_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_en_102_date_accept
        this.bar_en_102_prime = data.body.contact.bar_en_102_prime
        this.bar_en_103_date_accept = data.body.contact.bar_en_103_date_accept
          ? moment(
              data.body.contact.bar_en_103_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_en_103_date_accept
        this.bar_en_103_prime = data.body.contact.bar_en_103_prime
        this.bar_th_104_date_accept = data.body.contact.bar_th_104_date_accept
          ? moment(
              data.body.contact.bar_th_104_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_104_date_accept
        this.bar_th_104_prime = data.body.contact.bar_th_104_prime
        this.bar_th_127_date_accept = data.body.contact.bar_th_127_date_accept
          ? moment(
              data.body.contact.bar_th_127_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_127_date_accept
        this.bar_th_127_prime = data.body.contact.bar_th_127_prime
        this.bar_th_148_date_accept = data.body.contact.bar_th_148_date_accept
          ? moment(
              data.body.contact.bar_th_148_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_148_date_accept
        this.bar_th_148_prime = data.body.contact.bar_th_148_prime
        this.bar_th_164_date_accept = data.body.contact.bar_th_164_date_accept
          ? moment(
              data.body.contact.bar_th_164_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_164_date_accept
        this.bar_th_164_prime = data.body.contact.bar_th_164_prime
        this.ite_date_accept = data.body.contact.ite_date_accept
          ? moment(
              data.body.contact.ite_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.ite_date_accept
        this.ite_prime = data.body.contact.ite_prime
        this.iti_prime = data.body.contact.iti_prime
        this.iti_date_accept = data.body.contact.iti_date_accept
          ? moment(
              data.body.contact.iti_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.iti_date_accept
        this.projets = data.body.contact.projets
        this.reference_fiscal_avis = data.body.contact.reference_fiscal_avis
          ? data.body.contact.reference_fiscal_avis
          : []
        this.tax_number = data.body.contact.tax_number
        this.tax_number_2 = data.body.contact.tax_number_2
        this.vmc_double_flux_date_accept = data.body.contact
          .vmc_double_flux_date_accept
          ? moment(
              data.body.contact.vmc_double_flux_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.vmc_double_flux_date_accept
        this.vmc_double_flux_prime = data.body.contact.vmc_double_flux_prime
        this.vmc_simple_flux_date_accept = data.body.contact
          .vmc_simple_flux_date_accept
          ? moment(
              data.body.contact.vmc_simple_flux_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.vmc_simple_flux_date_accept
        this.vmc_simple_flux_prime = data.body.contact.vmc_simple_flux_prime
      }
      if (data.body.projet) {
        this.categoriesProjets = data.body.projet.categories
        this.iauditor_id = data.body.projet.iauditor_id
        this.iauditor_technique_id = data.body.projet.iauditor_technique_id
        this.bt_elec_capacite = data.body.projet.bt_elec_capacite
        this.bt_elec_marque = data.body.projet.bt_elec_marque
        this.bt_solaire_capacite = data.body.projet.bt_solaire_capacite
        this.bt_solaire_marque = data.body.projet.bt_solaire_marque
        this.bt_thermo_capacite = data.body.projet.bt_thermo_capacite
        this.bt_thermo_marque = data.body.projet.bt_thermo_marque
        this.cee_montant_prime_104 = data.body.projet.cee_montant_prime_104
        this.cee_montant_prime_127 = data.body.projet.cee_montant_prime_127
        this.cee_montant_prime_148 = data.body.projet.cee_montant_prime_148
        this.cp_reference_fiscal_avis = data.body.projet
          .cp_reference_fiscal_avis
          ? data.body.projet.cp_reference_fiscal_avis
          : []
        this.pac_air_air_marque = data.body.projet.pac_air_air_marque
        this.pac_air_air_puiss = data.body.projet.pac_air_air_puiss
        this.pac_air_eau_marque = data.body.projet.pac_air_eau_marque
        this.pac_air_eau_puiss = data.body.projet.pac_air_eau_puiss
        this.pac_eau_eau_marque = data.body.projet.pac_eau_eau_marque
        this.pac_eau_eau_puiss = data.body.projet.pac_eau_eau_puiss
        this.status_document = data.body.projet.status_document
        this.lettre_acroi_color = data.body.projet.lettre_acroi_color
        this.surface_deroule = data.body.projet.surface_deroule
        this.surface_deroule_pose = data.body.projet.surface_deroule_pose
        this.surface_mur = data.body.projet.surface_mur
        this.surface_mur_pose = data.body.projet.surface_mur_pose
        this.surface_pignon = data.body.projet.surface_pignon
        this.surface_pignon_pose = data.body.projet.surface_pignon_pose
        this.surface_plafond = data.body.projet.surface_plafond
        this.surface_plafond_pose = data.body.projet.surface_plafond_pose
        this.surface_rampant = data.body.projet.surface_rampant
        this.surface_rampant_pose = data.body.projet.surface_rampant_pose
        this.surface_souffle = data.body.projet.surface_souffle
        this.surface_souffle_pose = data.body.projet.surface_souffle_pose
        this.surface_iti = data.body.projet.surface_iti
        this.surface_iti_pose = data.body.projet.surface_iti_pose
        this.surface_vide_sanitaire = data.body.projet.surface_vide_sanitaire
        this.surface_vide_sanitaire_pose =
          data.body.projet.surface_vide_sanitaire_pose
        this.tax_fiscal_number = data.body.projet.tax_fiscal_number
        this.tax_household = data.body.projet.tax_household
        this.total = data.body.projet.total
        this.total_pose = data.body.projet.total_pose
      }
      if (data.header) {
        this.pixel_type_id = data.header.pixel_type_id
          ? data.header.pixel_type_id
          : null
        this.user_creator = data.header.creator
        this.type_name = data.header.type ? data.header.type.type_name : null
        if (data.header.type) {
          this.type_name = data.header.type ? data.header.type.type_name : null
          this.type_id = data.header.type ? data.header.type.type_id : null
        }
        this.logo = data.header.logo
        this.title = data.header.title
        this.phones =
          data.header.phone && data.header.phone.number
            ? data.header.phone.number
            : 0
        this.status = data.header.status
        if (data.header.cpipeline) {
          this.pipeline = Pipeline.create(data.header.cpipeline)
        }
        if (data.header.cstage) {
          this.stage = Stage.create(data.header.cstage)
        }
        if (data.header.csubstage) {
          this.subStage = SubStage.create(data.header.csubstage)
        }
        this.created_at = data.header.created_at
        this.dossier = !data.header.dossier || data.header.dossier == 0 ? 0 : 1
        this.photo = !data.header.photo || data.header.photo == 0 ? 0 : 1
        this.confirmation =
          !data.header.confirmation || data.header.confirmation == 0 ? 0 : 1
        this.confirmation_previsite =
          !data.header.confirmation_previsite ||
          data.header.confirmation_previsite == 0
            ? 0
            : 1
        this.depot_id_planning_comms = data.header.depot_id_planning_comms
        this.depot_id_planning_poseurs = data.header.depot_id_planning_poseurs
        this.week_id_planning_comms = data.header.week_id_planning_comms
        this.week_id_planning_poseurs = data.header.week_id_planning_poseurs
        this.pixel_type = data.header.pixel_type
        this.passerelle_status = data.header.passerelle_status
        this.pixel_id = data.header.pixel_id
        this.passerelle_id = data.header.passerelle_id
        this.injected_to_pixel = data.header.injected_to_pixel
      }
      // static
      if (data.static) {
        //  CHAUFFAGE
        this.team_chauffage_name = data.static.team_chauffage_name
        this.team_chauffage_id = data.static.team_chauffage_id
        // ISOLATION
        this.team_isolation_plancher_bas_id =
          data.static.team_isolation_plancher_bas_id
        this.team_isolation_plancher_bas_name =
          data.static.team_isolation_plancher_bas_name
        this.team_isolation_souffle_id = data.static.team_isolation_souffle_id
        this.team_isolation_souffle_name =
          data.static.team_isolation_souffle_name
        this.team_isolation_deroule_id = data.static.team_isolation_deroule_id
        this.team_isolation_deroule_name = data.static.team_isolation_deroule_id
        this.team_isolation_ite_id = data.static.team_isolation_ite_id
        this.team_name_isolations = data.static.team_name_isolations
        this.team_isolation_ite_name = data.static.team_isolation_ite_name
        this.team_isolation_iti_id = data.static.team_isolation_iti_id
        this.team_isolation_iti_name = data.static.team_isolation_iti_name
        this.team_isolation_sous_rampant_id =
          data.static.team_isolation_sous_rampant_id
        this.team_isolation_sous_rampant_name =
          data.static.team_isolation_sous_rampant_name
        //
        this.acompte_recu = data.static.acompte_recu
        this.project_id = data.static.id
        this.principal_pipeline_id = data.static.principal_pipeline_id
        this.principal_pipeline_name = data.static.principal_pipeline_name
        this.budget_reel = data.static.budget_reel
        this.created_at = data.static.creation_date
        this.comm_ite = data.static.comm_ite
        this.comm_ite_depots_name = data.static.comm_ite_depots_name
        this.comm_ite_first_name = data.static.comm_ite_first_name
        this.comm_ite_last_name = data.static.comm_ite_last_name
        this.comm_ite_full_name = `${
          data.static.comm_ite_first_name ? data.static.comm_ite_first_name : ''
        } ${
          data.static.comm_ite_first_name ? data.static.comm_ite_last_name : ''
        }`
        this.comm_ite_team_id = data.static.comm_ite_team_id
        this.comm_ite_team_name = data.static.comm_ite_team_name
        this.comm_regie = data.static.comm_regie
        this.visiteur_first_name = data.static.visiteur_first_name
        this.visiteur_last_name = data.static.visiteur_last_name
        this.visiteur_tech = data.static.visiteur_tech
        this.visiteur_tech_full_name = `${
          data.static.visiteur_first_name ? data.static.visiteur_first_name : ''
        } ${
          data.static.visiteur_last_name ? data.static.visiteur_last_name : ''
        }`
        this.comm_sed = data.static.comm_sed
        this.comm_sed_first_name = data.static.comm_sed_first_name
        this.comm_sed_last_name = data.static.comm_sed_last_name
        this.comm_sed_first_full_name = `${
          data.static.comm_sed_first_name ? data.static.comm_sed_first_name : ''
        } ${
          data.static.comm_sed_last_name ? data.static.comm_sed_last_name : ''
        }`
        this.comm_sed_team_id = data.static.comm_sed_team_id
        this.comm_sed_team_name = data.static.comm_sed_team_name
        this.comm_terr = data.static.comm_terr
        this.comm_terr_depots_name = data.static.comm_terr_depots_name
        this.comm_terr_first_name = data.static.comm_terr_first_name
        this.comm_terr_last_name = data.static.comm_terr_last_name
        this.comm_terr_full_name = `${
          data.static.comm_terr_first_name
            ? data.static.comm_terr_first_name
            : ''
        } ${
          data.static.comm_terr_last_name ? data.static.comm_terr_last_name : ''
        }`
        this.comm_terr_team_id = data.static.comm_terr_team_id
        this.comm_terr_team_name = data.static.comm_terr_team_name
        this.confirmation =
          !data.static.confirmation || data.static.confirmation == 0 ? 0 : 1
        this.confirmation_previsite =
          !data.static.confirmation_previsite ||
          data.static.confirmation_previsite == 0
            ? 0
            : 1
        this.contact_full_name = data.static.contact_full_name
        this.contact_id = data.static.contact_id
        this.contact_zipCode = data.static.contact_zipCode
        this.create_date = data.static.creation_date
          ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.creation_date
        this.create_time = data.static.creation_date
          ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
              'HH:mm:ss'
            )
          : data.static.creation_date
        this.update_date = data.static.modification_date
          ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.modification_date
        this.update_time = data.static.modification_date
          ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
              'HH:mm:ss'
            )
          : data.static.modification_date
        this.date_debut_formation = data.static.date_debut_formation
        this.date_fin_formation = data.static.date_fin_formation
        this.date_mpr = data.static.date_mpr
        this.date_previsite = data.static.date_previsite
          ? moment(data.static.date_previsite, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.date_previsite
        this.date_travaux = data.static.date_travaux
          ? moment(data.static.date_travaux, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.date_travaux
        this.dossier = !data.static.dossier || data.static.dossier == 0 ? 0 : 1
        this.id = data.static.id
        this.pac = data.static.pac
        this.photo = !data.static.photo || data.static.photo == 0 ? 0 : 1
        this.pmr = data.static.pmr
        this.poseur_depots_name = data.static.poseur_depots_name
        this.poseur_first_name = data.static.poseur_first_name
        this.poseur_full_name = `${
          data.static.poseur_first_name ? data.static.poseur_first_name : ''
        } ${data.static.poseur_last_name ? data.static.poseur_last_name : ''}`
        this.poseur_id = data.static.poseur_id
        this.poseur_last_name = data.static.poseur_last_name
        this.precarity = data.static.precarity
        this.prj_activity_recent = data.static.prj_activity_recent
          ? moment(
              data.static.prj_activity_recent,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.static.prj_activity_recent
        this.prj_appel_count = data.static.prj_appel_count
        this.prj_date_closing = ''
        if (data.static.prj_date_closing) {
          this.prj_date_closing = data.static.prj_date_closing
            ? moment(
                data.static.prj_date_closing,
                'YYYY-MM-DD HH:mm:ss'
              ).format('DD-MM-YYYY')
            : data.static.prj_date_closing
        }
        this.prj_type_heating = data.static.prj_type_heating
        this.progression = data.static.progression
        this.qlty_manager = data.static.qlty_manager
        this.qlty_manager_first_name = data.static.qlty_manager_first_name
        this.qlty_manager_last_name = data.static.qlty_manager_last_name
        this.qlty_manager_full_name = `${
          data.static.qlty_manager_first_name
            ? data.static.qlty_manager_first_name
            : ''
        } ${
          data.static.qlty_manager_last_name
            ? data.static.qlty_manager_last_name
            : ''
        }`
        this.qlty_manager_team_id = data.static.qlty_manager_team_id
        this.qlty_manager_team_name = data.static.qlty_manager_team_name
        this.solde_recu = data.static.solde_recu
        this.source = data.static.source
        this.stage_name = data.static.stage
        this.stage_id = data.static.stage_id
        this.statut_mpr = data.static.statut_mpr
        this.statut_previsite = data.static.statut_previsite
        this.statut_travaux = data.static.statut_travaux
        this.cumac = data.static.cumac
        this.type_renovation = data.static.type_renovation
        this.type_dash = data.static.type_dash == 1 ? 1 : 0
        this.ecs_dash = data.static.ecs_dash == 1 ? 1 : 0
        this.ro_dash = data.static.ro_dash == 1 ? 1 : 0
        this.rr_dash = data.static.rr_dash == 1 ? 1 : 0
        this.vmc_dash = data.static.vmc_dash == 1 ? 1 : 0
        this.iso_dash = data.static.iso_dash == 1 ? 1 : 0
        this.chauff_dash = data.static.chauff_dash == 1 ? 1 : 0
        this.ambiance_dash = data.static.ambiance_dash == 1 ? 1 : 0
        this.info_vmc_dash = data.static.info_vmc_dash == 1 ? 1 : 0
        this.sub_stage_color = data.static.sub_stage_color
        this.sub_stage_id = data.static.sub_stage_id
        this.statut_dossier_administratif =
          data.static.statut_dossier_administratif
        this.sub_stage_name = data.static.sub_stage_name
        this.sub_stage_status = data.static.sub_stage_status
        this.surface_deroule = data.static.surface_deroule
        this.surface_deroule_pose = data.static.surface_deroule_pose
        this.surface_iti = data.static.surface_iti
        this.surface_iti_pose = data.static.surface_iti_pose
        this.surface_mur = data.static.surface_mur
        this.surface_mur_pose = data.static.surface_mur_pose
        this.surface_pignon = data.static.surface_pignon
        this.surface_pignon_pose = data.static.surface_pignon_pose
        this.surface_plafond = data.static.surface_plafond
        this.surface_plafond_pose = data.static.surface_plafond_pose
        this.surface_rampant = data.static.surface_rampant
        this.surface_rampant_pose = data.static.surface_rampant_pose
        this.surface_souffle = data.static.surface_souffle
        this.surface_souffle_pose = data.static.surface_souffle_pose
        this.surface_iti = data.static.surface_iti
        this.surface_iti_pose = data.static.surface_iti_pose
        this.surface_vide_sanitaire = data.static.surface_vide_sanitaire
        this.surface_vide_sanitaire_pose =
          data.static.surface_vide_sanitaire_pose
        this.total = data.static.total
        this.total_pose = data.static.total_pose
        this.type_formation = data.static.type_formation
        this.zone = data.static.zone
        this.lettre_acroi_color = data.static.lettre_acroi_color
      }
      //
      this.activities = []
      this.journals = []
      this.comments = []
      this.files = []
      this.timeline = []
    } else {
      // PROJECT  TYPE TH164
      this.tableregie = []
      this.tablecomm_sed_info_client = []
      this.tablefiliale = []
      this.tableingenieur_bao = []
      this.tablecomm_terrain = []
      this.tablecomm_sed_envoi_email = []
      this.tablebt_poseur = []
      this.tablerr_poseur = []
      this.tablero_poseur = []
      this.tableposeur_iso_interne_deroule = []
      this.tableposeur_iso_interne_souffle = []
      this.tableposeur_iso_interne_plafond = []
      this.tableposeur_iso_interne_rampant_plus = []
      this.tableposeur_iso_interne_mur_plus = []
      this.tableposeur_vmc = []
      this.tablevmc_flux = []
      this.tablevmc_bouche = []
      this.tablevmc_marque = []
      this.tableposeur_iso_externe = []
      this.tablevisiteur_cofrac = []
      // pop up ECS //  R/R // R/O  // VMC
      this.tablebt_ballon = []
      this.tablebt_marque = []
      this.tablefournisseur_bt = []

      this.tablerr_capacite = []
      this.tablerr_marque = []
      this.tablefournisseur_rr = []

      this.tablero_capacite = []
      this.tablero_marque = []
      this.tablefournisseur_ro = []

      this.tablevmc_marque = []
      this.tablefournisseur_vmc = []
      //
      this.tablestatus_pose = []
      // Pop UP
      // commentaire
      this.ecs_comments = data.ecs_comments
      this.rr_comments = data.rr_comments
      this.ro_comments = data.ro_comments
      this.s_comments = data.s_comments
      this.d_comments = data.d_comments
      this.p_comments = data.p_comments
      this.r_comments = data.r_comments
      this.m_comments = data.m_comments
      this.ite_comments = data.ite_comments
      this.vmc_comments = data.vmc_comments
      this.cofrac_comments = data.cofrac_comments
      this.nom_client_comments = data.nom_client_comments
      //
      this.ecs_label_date_pose = data.ecs_label_date_pose
      this.ro_label_date_pose = data.ro_label_date_pose
      this.rr_label_date_pose = data.rr_label_date_pose
      this.s_label_date_pose = data.s_label_date_pose
      this.d_label_date_pose = data.d_label_date_pose
      this.p_label_date_pose = data.p_label_date_pose
      this.r_label_date_pose = data.r_label_date_pose
      this.m_label_date_pose = data.m_label_date_pose
      this.ite_label_date_pose = data.ite_label_date_pose
      this.vmc_label_date_pose = data.vmc_label_date_pose
      //
      this.alimentation_rr = data.alimentation_rr
      this.alimentation_ro = data.alimentation_ro
      this.ite_demande_mairie =
        data.ite_demande_mairie == 1
          ? 'Oui'
          : data.ite_demande_mairie == 0
          ? 'Non'
          : data.ite_demande_mairie
      this.s_marque = data.s_marque
      this.fournisseur_s = data.fournisseur_s
      this.s_dispo = data.s_dispo
      this.s_num_facture = data.s_num_facture
      this.s_montant_facture = data.s_montant_facture
        ? data.s_montant_facture
        : ''
      this.s_montant_facture_ttc = data.s_montant_facture_ttc
        ? data.s_montant_facture_ttc
        : ''
      this.s_num_facture_pose = data.s_num_facture_pose
      this.s_montant_facture_ht_pose = data.s_montant_facture_ht_pose
        ? data.s_montant_facture_ht_pose
        : ''
      this.s_montant_facture_ttc_pose = data.s_montant_facture_ttc_pose
        ? data.s_montant_facture_ttc_pose
        : ''
      this.s_etat_facture = data.s_etat_facture
      this.fournit_pose_s =
        data.fournit_pose_s == 1
          ? 'Oui'
          : data.fournit_pose_s == 0
          ? 'Non'
          : data.fournit_pose_s

      this.d_marque = data.d_marque
      this.fournisseur_d = data.fournisseur_d
      this.d_dispo = data.d_dispo
      this.d_num_facture = data.d_num_facture
      this.d_montant_facture = data.d_montant_facture
        ? data.d_montant_facture
        : ''
      this.d_montant_facture_ttc = data.d_montant_facture_ttc
        ? data.d_montant_facture_ttc
        : ''
      this.d_num_facture_pose = data.d_num_facture_pose
      this.d_montant_facture_ht_pose = data.d_montant_facture_ht_pose
        ? data.d_montant_facture_ht_pose
        : ''
      this.d_montant_facture_ttc_pose = data.d_montant_facture_ttc_pose
        ? data.d_montant_facture_ttc_pose
        : ''
      this.d_etat_facture = data.d_etat_facture
      this.fournit_pose_d =
        data.fournit_pose_d == 1
          ? 'Oui'
          : data.fournit_pose_d == 0
          ? 'Non'
          : data.fournit_pose_d

      this.p_marque = data.p_marque
      this.fournisseur_p = data.fournisseur_p
      this.p_dispo = data.p_dispo
      this.p_num_facture = data.p_num_facture
      this.p_montant_facture = data.p_montant_facture
        ? data.p_montant_facture
        : ''
      this.p_montant_facture_ttc = data.p_montant_facture_ttc
        ? data.p_montant_facture_ttc
        : ''
      this.p_num_facture_pose = data.p_num_facture_pose
      this.p_montant_facture_ht_pose = data.p_montant_facture_ht_pose
        ? data.p_montant_facture_ht_pose
        : ''
      this.p_montant_facture_ttc_pose = data.p_montant_facture_ttc_pose
        ? data.p_montant_facture_ttc_pose
        : ''
      this.p_etat_facture = data.p_etat_facture
      this.fournit_pose_p =
        data.fournit_pose_p == 1
          ? 'Oui'
          : data.fournit_pose_p == 0
          ? 'Non'
          : data.fournit_pose_p

      this.r_marque = data.r_marque
      this.fournisseur_r = data.fournisseur_r
      this.r_dispo = data.r_dispo
      this.r_num_facture = data.r_num_facture
      this.r_montant_facture = data.r_montant_facture
        ? data.r_montant_facture
        : ''
      this.r_montant_facture_ttc = data.r_montant_facture_ttc
        ? data.r_montant_facture_ttc
        : ''
      this.r_num_facture_pose = data.r_num_facture_pose
      this.r_montant_facture_ht_pose = data.r_montant_facture_ht_pose
        ? data.r_montant_facture_ht_pose
        : ''
      this.r_montant_facture_ttc_pose = data.r_montant_facture_ttc_pose
        ? data.r_montant_facture_ttc_pose
        : ''
      this.r_etat_facture = data.r_etat_facture
      this.fournit_pose_r =
        data.fournit_pose_r == 1
          ? 'Oui'
          : data.fournit_pose_r == 0
          ? 'Non'
          : data.fournit_pose_r

      this.m_marque = data.m_marque
      this.fournisseur_m = data.fournisseur_m
      this.m_dispo = data.m_dispo
      this.m_num_facture = data.m_num_facture
      this.m_montant_facture = data.m_montant_facture
        ? data.m_montant_facture
        : ''
      this.m_montant_facture_ttc = data.m_montant_facture_ttc
        ? data.m_montant_facture_ttc
        : ''
      this.m_num_facture_pose = data.m_num_facture_pose
      this.m_montant_facture_ht_pose = data.m_montant_facture_ht_pose
        ? data.m_montant_facture_ht_pose
        : ''
      this.m_montant_facture_ttc_pose = data.m_montant_facture_ttc_pose
        ? data.m_montant_facture_ttc_pose
        : ''
      this.m_etat_facture = data.m_etat_facture
      this.fournit_pose_m =
        data.fournit_pose_m == 1
          ? 'Oui'
          : data.fournit_pose_m == 0
          ? 'Non'
          : data.fournit_pose_m

      this.ite_marque = data.ite_marque
      this.fournisseur_ite = data.fournisseur_ite
      this.ite_dispo = data.ite_dispo
      this.ite_num_facture = data.ite_num_facture
      this.ite_montant_facture = data.ite_montant_facture
        ? data.ite_montant_facture
        : ''
      this.ite_montant_facture_ttc = data.ite_montant_facture_ttc
        ? data.ite_montant_facture_ttc
        : ''
      this.ite_num_facture_pose = data.ite_num_facture_pose
      this.ite_montant_facture_ht_pose = data.ite_montant_facture_ht_pose
        ? data.ite_montant_facture_ht_pose
        : ''
      this.ite_montant_facture_ttc_pose = data.ite_montant_facture_ttc_pose
        ? data.ite_montant_facture_ttc_pose
        : ''
      this.ite_etat_facture = data.ite_etat_facture
      this.fournit_pose_ite =
        data.fournit_pose_ite == 1
          ? 'Oui'
          : data.fournit_pose_ite == 0
          ? 'Non'
          : data.fournit_pose_ite

      this.vmc_marque = data.vmc_marque
      this.vmc_flux = data.vmc_flux
      this.vmc_bouche = data.vmc_bouche
      this.fournisseur_vmc = data.fournisseur_vmc
      this.vmc_dispo = data.vmc_dispo
      this.vmc_num_facture = data.vmc_num_facture
      this.vmc_montant_facture = data.vmc_montant_facture
        ? data.vmc_montant_facture
        : ''
      this.vmc_montant_facture_ttc = data.vmc_montant_facture_ttc
        ? data.vmc_montant_facture_ttc
        : ''
      this.vmc_num_facture_pose = data.vmc_num_facture_pose
      this.vmc_montant_facture_ht_pose = data.vmc_montant_facture_ht_pose
        ? data.vmc_montant_facture_ht_pose
        : ''
      this.vmc_montant_facture_ttc_pose = data.vmc_montant_facture_ttc_pose
        ? data.vmc_montant_facture_ttc_pose
        : ''
      this.vmc_etat_facture = data.vmc_etat_facture
      this.fournit_pose_vmc =
        data.fournit_pose_vmc == 1
          ? 'Oui'
          : data.fournit_pose_vmc == 0
          ? 'Non'
          : data.fournit_pose_vmc
      this.adresse = data.adresse
      this.label_pose_termine = data.label_pose_termine
      this.label_email_charge = data.label_email_charge
      this.label_etat_devis = data.label_etat_devis
      this.label_etat_visite = data.label_etat_visite
      this.created_at = data.created_at
      this.logement_type = data.logement_type
      this.occupation = data.occupation
      this.fournisseur_bt = data.fournisseur_bt
      this.fournisseur_ro = data.fournisseur_ro
      this.fournisseur_rr = data.fournisseur_rr
      this.bt_ballon = data.bt_ballon ? data.bt_ballon : ''
      this.bt_dispo = data.bt_dispo
      this.bt_etat_facture = data.bt_etat_facture
      this.bt_marque = data.bt_marque ? data.bt_marque : ''
      this.bt_montant_facture = data.bt_montant_facture
        ? data.bt_montant_facture
        : ''
      this.bt_montant_facture_ttc = data.bt_montant_facture_ttc
        ? data.bt_montant_facture_ttc
        : ''
      this.bt_montant_facture_ht_pose = data.bt_montant_facture_ht_pose
        ? data.bt_montant_facture_ht_pose
        : ''
      this.bt_montant_facture_ttc_pose = data.bt_montant_facture_ttc_pose
        ? data.bt_montant_facture_ttc_pose
        : ''
      this.bt_num_facture = data.bt_num_facture
      this.bt_num_facture_pose = data.bt_num_facture_pose
      this.fournit_pose_bt =
        data.fournit_pose_bt == 1
          ? 'Oui'
          : data.fournit_pose_bt == 0
          ? 'Non'
          : data.fournit_pose_bt
      this.fournit_pose_ro =
        data.fournit_pose_ro == 1
          ? 'Oui'
          : data.fournit_pose_ro == 0
          ? 'Non'
          : data.fournit_pose_ro
      this.fournit_pose_rr =
        data.fournit_pose_rr == 1
          ? 'Oui'
          : data.fournit_pose_rr == 0
          ? 'Non'
          : data.fournit_pose_rr
      this.bt_poseur = data.bt_poseur
      this.chauffage = data.chauffage
      this.comm_sed_envoi_email = data.comm_sed_envoi_email
      this.comm_sed_info_client = data.comm_sed_info_client
      this.comm_terrain = data.comm_terrain
      this.cp = data.cp
      this.created_at = data.created_at
        ? moment(data.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.created_at
      this.date_bao = data.date_bao
        ? moment(data.date_bao, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_bao
      this.date_bt_planifie = data.date_bt_planifie
        ? moment(data.date_bt_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_bt_planifie
      this.date_bt_pose = data.date_bt_pose
        ? moment(data.date_bt_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_bt_pose
      this.date_envoi_email = data.date_envoi_email
        ? moment(data.date_envoi_email, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_envoi_email
      this.date_iso_externe_planifie = data.date_iso_externe_planifie
        ? moment(data.date_iso_externe_planifie, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_externe_planifie
      this.date_iso_externe_pose = data.date_iso_externe_pose
        ? moment(data.date_iso_externe_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_iso_externe_pose
      this.date_iso_interne_planifie_deroule = data.date_iso_interne_planifie_deroule
        ? moment(data.date_iso_interne_planifie_deroule, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_deroule
      this.date_iso_interne_planifie_mur_plus = data.date_iso_interne_planifie_mur_plus
        ? moment(data.date_iso_interne_planifie_mur_plus, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_mur_plus
      this.date_iso_interne_planifie_plafond = data.date_iso_interne_planifie_plafond
        ? moment(data.date_iso_interne_planifie_plafond, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_plafond
      this.date_iso_interne_planifie_rampant_plus = data.date_iso_interne_planifie_rampant_plus
        ? moment(
            data.date_iso_interne_planifie_rampant_plus,
            'YYYY-MM-DD'
          ).format('DD-MM-YYYY')
        : data.date_iso_interne_planifie_rampant_plus
      this.date_iso_interne_planifie_souffle = data.date_iso_interne_planifie_souffle
        ? moment(data.date_iso_interne_planifie_souffle, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_souffle
      this.date_iso_interne_pose_deroule = data.date_iso_interne_pose_deroule
        ? moment(data.date_iso_interne_pose_deroule, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_deroule
      this.date_iso_interne_pose_mur_plus = data.date_iso_interne_pose_mur_plus
        ? moment(data.date_iso_interne_pose_mur_plus, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_mur_plus
      this.date_iso_interne_pose_plafond = data.date_iso_interne_pose_plafond
        ? moment(data.date_iso_interne_pose_plafond, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_plafond
      this.date_iso_interne_pose_rampant_plus = data.date_iso_interne_pose_rampant_plus
        ? moment(data.date_iso_interne_pose_rampant_plus, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_rampant_plus
      this.date_iso_interne_pose_souffle = data.date_iso_interne_pose_souffle
        ? moment(data.date_iso_interne_pose_souffle, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_souffle
      this.date_pose_termine = data.date_pose_termine
        ? moment(data.date_pose_termine, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_pose_termine
      this.date_relv_info = data.date_relv_info
        ? moment(data.date_relv_info, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_relv_info
      this.date_ro_planifie = data.date_ro_planifie
        ? moment(data.date_ro_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_ro_planifie
      this.date_ro_pose = data.date_ro_pose
        ? moment(data.date_ro_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_ro_pose
      this.date_rr_planifie = data.date_rr_planifie
        ? moment(data.date_rr_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_rr_planifie
      this.date_rr_pose = data.date_rr_pose
        ? moment(data.date_rr_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_rr_pose
      this.date_sign_devis = data.date_sign_devis
        ? moment(data.date_sign_devis, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_sign_devis
      this.date_signature_cee = data.date_signature_cee
        ? moment(data.date_signature_cee, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_signature_cee
      this.date_visite_cofrac_prevu = data.date_visite_cofrac_prevu
        ? moment(data.date_visite_cofrac_prevu, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_visite_cofrac_prevu
      this.date_visite_cofrac_visite = data.date_visite_cofrac_visite
        ? moment(data.date_visite_cofrac_visite, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_visite_cofrac_visite
      this.date_visite_tech = data.date_visite_tech
        ? moment(data.date_visite_tech, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_visite_tech
      this.date_vmc_planifie = data.date_vmc_planifie
        ? moment(data.date_vmc_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_vmc_planifie
      this.date_vmc_pose = data.date_vmc_pose
        ? moment(data.date_vmc_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_vmc_pose
      this.email = data.email
      this.filiale = data.filiale
      this.ingenieur_bao = data.ingenieur_bao
      this.num_fisc = data.num_fisc
      this.parcelle = data.parcelle
      this.phone = data.phone
      this.poseur_iso_externe = data.poseur_iso_externe
      this.poseur_iso_interne_deroule = data.poseur_iso_interne_deroule
      this.poseur_iso_interne_mur_plus = data.poseur_iso_interne_mur_plus
      this.poseur_iso_interne_plafond = data.poseur_iso_interne_plafond
      this.poseur_iso_interne_rampant_plus =
        data.poseur_iso_interne_rampant_plus
      this.poseur_iso_interne_souffle = data.poseur_iso_interne_souffle
      this.poseur_vmc = data.poseur_vmc
      this.precar = data.precar
      this.prime_cee = data.prime_cee
      this.ref_fisc = data.ref_fisc
      this.regie = data.regie
      this.remise = data.remise
      this.reste_a_payer = data.reste_a_payer
      this.ro_capacite = data.ro_capacite ? data.ro_capacite : ''
      this.ro_dispo = data.ro_dispo
      this.ro_etat_facture = data.ro_etat_facture
      this.ro_marque = data.ro_marque ? data.ro_marque : ''
      this.ro_montant_facture = data.ro_montant_facture
        ? data.ro_montant_facture
        : ''
      this.ro_montant_facture_ht_pose = data.ro_montant_facture_ht_pose
        ? data.ro_montant_facture_ht_pose
        : ''
      this.ro_montant_facture_ttc = data.ro_montant_facture_ttc
        ? data.ro_montant_facture_ttc
        : ''
      this.ro_montant_facture_ttc_pose = data.ro_montant_facture_ttc_pose
        ? data.ro_montant_facture_ttc_pose
        : ''
      this.ro_num_facture = data.ro_num_facture
      this.ro_num_facture_pose = data.ro_num_facture_pose
      this.ro_poseur = data.ro_poseur
      this.rr_capacite = data.rr_capacite ? data.rr_capacite : ''
      this.rr_dispo = data.rr_dispo
      this.rr_etat_facture = data.rr_etat_facture
      this.rr_marque = data.rr_marque ? data.rr_marque : ''
      this.rr_montant_facture = data.rr_montant_facture
        ? data.rr_montant_facture
        : ''
      this.rr_montant_facture_ht_pose = data.rr_montant_facture_ht_pose
        ? data.rr_montant_facture_ht_pose
        : ''
      this.rr_montant_facture_ttc = data.rr_montant_facture_ttc
        ? data.rr_montant_facture_ttc
        : ''
      this.rr_montant_facture_ttc_pose = data.rr_montant_facture_ttc_pose
        ? data.rr_montant_facture_ttc_pose
        : ''
      this.rr_num_facture = data.rr_num_facture
      this.rr_num_facture_pose = data.rr_num_facture_pose
      this.rr_poseur = data.rr_poseur
      this.status_visite_cofrac = data.status_visite_cofrac
      this.statut_bao = data.statut_bao
      this.statut_client = data.statut_client
      this.statut_relv_info = data.statut_relv_info
      this.surface_deroule = data.surface_deroule
      this.surface_externe = data.surface_externe
      this.surface_habitable = data.surface_habitable
      this.surface_mur_plus = data.surface_mur_plus
      this.surface_plafond = data.surface_plafond
      this.surface_rampant_plus = data.surface_rampant_plus
      this.surface_souffle = data.surface_souffle
      this.titre = data.titre
      this.total_ht = data.total_ht
      this.total_tt = data.total_tt
      this.total_ttc = data.total_ttc
      this.updated_at = data.updated_at
      this.ville = data.ville
      this.visiteur_cofrac = data.visiteur_cofrac
      this.vmc = data.vmc
      this.vue = data.vue
      // PROJECT
      this.checked = data.checked || false
      this.acompte_recu = data.acompte_recu
      this.project_id = data.id
      this.principal_pipeline_id = data.principal_pipeline_id
      this.principal_pipeline_name = data.principal_pipeline_name
      this.budget_reel = data.budget_reel
      this.date_debut_formation = data.date_debut_formation
      this.date_fin_formation = data.date_fin_formation
      this.progression = data.progression
      this.solde_recu = data.solde_recu
      this.type_formation = data.type_formation
      this.lettre_acroi_color = data.lettre_acroi_color
      this.comm_ite = data.comm_ite
      this.comm_ite_depots_name = data.comm_ite_depots_name
      this.comm_ite_first_name = data.comm_ite_first_name
      this.comm_ite_last_name = data.comm_ite_last_name
      this.comm_ite_full_name = `${
        data.comm_ite_first_name ? data.comm_ite_first_name : ''
      } ${data.comm_ite_last_name ? data.comm_ite_last_name : ''}`
      this.comm_ite_team_id = data.comm_ite_team_id
      this.comm_ite_team_name = data.comm_ite_team_name
      this.comm_regie = data.comm_regie
      this.visiteur_first_name = data.visiteur_first_name
      this.visiteur_last_name = data.visiteur_last_name
      this.visiteur_tech = data.visiteur_tech
      this.visiteur_tech_full_name = `${
        data.visiteur_first_name ? data.visiteur_first_name : ''
      } ${data.visiteur_last_name ? data.visiteur_last_name : ''}`
      this.comm_sed = data.comm_sed
      this.comm_sed_first_name = data.comm_sed_first_name
      this.comm_sed_last_name = data.comm_sed_last_name
      this.comm_sed_first_full_name = `${
        data.comm_sed_first_name ? data.comm_sed_first_name : ''
      } ${data.comm_sed_last_name ? data.comm_sed_last_name : ''}`
      this.comm_sed_team_id = data.comm_sed_team_id
      this.comm_sed_team_name = data.comm_sed_team_name
      this.comm_terr = data.comm_terr
      this.comm_terr_depots_name = data.comm_terr_depots_name
      this.comm_terr_first_name = data.comm_terr_first_name
      this.comm_terr_full_name = `${
        data.comm_terr_first_name ? data.comm_terr_first_name : ''
      } ${data.comm_terr_last_name ? data.comm_terr_last_name : ''}`
      this.comm_terr_last_name = data.comm_terr_last_name
      this.comm_terr_team_id = data.comm_terr_team_id
      this.comm_terr_team_name = data.comm_terr_team_name
      this.confirmation = !data.confirmation || data.confirmation == 0 ? 0 : 1
      this.confirmation_previsite =
        !data.confirmation_previsite || data.confirmation_previsite == 0 ? 0 : 1
      this.dossier = !data.dossier || data.dossier == 0 ? 0 : 1
      this.photo = !data.photo || data.photo == 0 ? 0 : 1
      this.contact_full_name = data.contact_full_name
      this.contact_id = data.contact_id
      this.contact_zipCode = data.contact_zipCode
      this.create_date = data.creation_date
        ? moment(data.creation_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
        : data.creation_date
      this.create_time = data.creation_date
        ? moment(data.creation_date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
        : data.creation_date
      this.update_date = data.modification_date
        ? moment(data.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.modification_date
      this.update_time = data.modification_date
        ? moment(data.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
            'HH:mm:ss'
          )
        : data.modification_date
      this.date_mpr = data.date_mpr
        ? moment(data.date_mpr, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
        : data.date_mpr
      this.date_previsite = data.date_previsite
        ? moment(data.date_previsite, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.date_previsite
      this.date_travaux = data.date_travaux
        ? moment(data.date_travaux, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
        : data.date_travaux
      this.pac = data.pac
      this.pmr = data.pmr
      this.poseur_depots_name = data.poseur_depots_name
      this.poseur_full_name = `${
        data.poseur_first_name ? data.poseur_first_name : ''
      } ${data.poseur_last_name ? data.poseur_last_name : ''}`
      this.poseur_id = data.poseur_id
      this.precarity = data.precarity
      this.prj_activity_recent = data.prj_activity_recent
        ? moment(data.prj_activity_recent, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.prj_activity_recent
      this.prj_appel_count = data.prj_appel_count
      this.prj_date_closing = ''
      if (data.prj_date_closing) {
        this.prj_date_closing = data.prj_date_closing
          ? moment(data.prj_date_closing, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.prj_date_closing
      }
      this.prj_type_heating = data.prj_type_heating
      this.qlty_manager = data.qlty_manager
      this.qlty_manager_first_name = data.qlty_manager_first_name
      this.qlty_manager_last_name = data.qlty_manager_last_name
      this.qlty_manager_full_name = `${
        data.qlty_manager_first_name ? data.qlty_manager_first_name : ''
      } ${data.qlty_manager_last_name ? data.qlty_manager_last_name : ''}`
      this.qlty_manager_team_id = data.qlty_manager_team_id
      this.qlty_manager_team_name = data.qlty_manager_team_name
      this.source = data.source
      if (data.cpipeline) {
        this.pipeline = Pipeline.create(data.cpipeline)
      }
      if (data.cstage) {
        this.stage = Stage.create(data.cstage)
      }
      if (data.csubstage) {
        this.subStage = SubStage.create(data.csubstage)
      }
      //  CHAUFFAGE
      this.team_chauffage_name = data.team_chauffage_name
      this.team_chauffage_id = data.team_chauffage_id
      // ISOLATION
      this.team_isolation_plancher_bas_id = data.team_isolation_plancher_bas_id
      this.team_isolation_plancher_bas_name =
        data.team_isolation_plancher_bas_name
      this.team_isolation_souffle_id = data.team_isolation_souffle_id
      this.team_isolation_souffle_name = data.team_isolation_souffle_name
      this.team_isolation_deroule_id = data.team_isolation_deroule_id
      this.team_isolation_deroule_name = data.team_isolation_deroule_name
      this.team_isolation_ite_id = data.team_isolation_ite_id
      this.team_name_isolations = data.team_name_isolations
      this.team_isolation_ite_name = data.team_isolation_ite_name
      this.team_isolation_iti_id = data.team_isolation_iti_id
      this.team_isolation_iti_name = data.team_isolation_iti_name
      this.team_isolation_sous_rampant_id = data.team_isolation_sous_rampant_id
      this.team_isolation_sous_rampant_name =
        data.team_isolation_sous_rampant_name
      //
      this.stage_name = data.stage
      this.stage_id = data.stage_id
      this.statut_mpr = data.statut_mpr
      this.statut_previsite = data.statut_previsite
      this.statut_travaux = data.statut_travaux
      this.cumac = data.cumac
      this.file_cat_color = data.file_cat_color
      this.type_renovation = data.type_renovation
      this.type_dash = data.type_dash == 1 ? 1 : 0
      this.ecs_dash = data.ecs_dash == 1 ? 1 : 0
      this.ro_dash = data.ro_dash == 1 ? 1 : 0
      this.rr_dash = data.rr_dash == 1 ? 1 : 0
      this.vmc_dash = data.vmc_dash == 1 ? 1 : 0
      this.iso_dash = data.iso_dash == 1 ? 1 : 0
      this.chauff_dash = data.chauff_dash == 1 ? 1 : 0
      this.ambiance_dash = data.ambiance_dash == 1 ? 1 : 0
      this.info_vmc_dash = data.info_vmc_dash == 1 ? 1 : 0
      this.sub_stage_color = data.sub_stage_color
      this.sub_stage_id = data.sub_stage_id
      this.statut_dossier_administratif = data.statut_dossier_administratif
      this.sub_stage_name = data.sub_stage_name
      this.sub_stage_status = data.sub_stage_status
      this.surface_deroule = data.surface_deroule
      this.surface_deroule_pose = data.surface_deroule_pose
      this.surface_iti = data.surface_iti
      this.surface_iti_pose = data.surface_iti_pose
      this.surface_mur = data.surface_mur
      this.surface_mur_pose = data.surface_mur_pose
      this.surface_pignon = data.surface_pignon
      this.surface_pignon_pose = data.surface_pignon_pose
      this.surface_plafond = data.surface_plafond
      this.surface_plafond_pose = data.surface_plafond_pose
      this.surface_rampant = data.surface_rampant
      this.surface_rampant_pose = data.surface_rampant_pose
      this.surface_souffle = data.surface_souffle
      this.surface_souffle_pose = data.surface_souffle_pose
      this.surface_iti = data.surface_iti
      this.surface_iti_pose = data.surface_iti_pose
      this.surface_vide_sanitaire = data.surface_vide_sanitaire
      this.surface_vide_sanitaire_pose = data.surface_vide_sanitaire_pose
      this.total = data.total
      this.total_pose = data.total_pose
      this.zone = data.zone
    }
  }

  /**
   *
   * @param {
   * id,
   * contact,
   * cpipeline,
   * cstage,
   * csubstage,
   * dtype,
   * owner,
   * status,
   * title,
   * user
   * } data
   */
  static create(data) {
    if (!data) {
      throw new Error('Project::create: "data" is undefined')
    }
    return new Project(data)
  }

  update(data) {
    if (data.header && data.body) {
      // body
      if (data.body.contact) {
        this.adresse = data.body.contact.adresse
        this.city = data.body.contact.city
        this.zipCode = data.body.contact.zipCode
        this.categoriesContacts = data.body.contact.categories
        this.air_eau_date_accept = data.body.contact.air_eau_date_accept
          ? moment(
              data.body.contact.air_eau_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.air_eau_date_accept
        this.air_eau_prime = data.body.contact.air_eau_prime
        this.audit_ener_date_accept = data.body.contact.audit_ener_date_accept
          ? moment(
              data.body.contact.audit_ener_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.audit_ener_date_accept
        this.audit_ener_prime = data.body.contact.audit_ener_prime
        this.ballon_thermo_date_accept = data.body.contact
          .ballon_thermo_date_accept
          ? moment(
              data.body.contact.ballon_thermo_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.ballon_thermo_date_accept
        this.ballon_thermo_prime = data.body.contact.ballon_thermo_prime
        this.bar_en_101_date_accept = data.body.contact.bar_en_101_date_accept
          ? moment(
              data.body.contact.bar_en_101_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_en_101_date_accept
        this.bar_en_101_prime = data.body.contact.bar_en_101_prime
        this.bar_en_102_date_accept = data.body.contact.bar_en_102_date_accept
          ? moment(
              data.body.contact.bar_en_102_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_en_102_date_accept
        this.bar_en_102_prime = data.body.contact.bar_en_102_prime
        this.bar_en_103_date_accept = data.body.contact.bar_en_103_date_accept
          ? moment(
              data.body.contact.bar_en_103_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_en_103_date_accept
        this.bar_en_103_prime = data.body.contact.bar_en_103_prime
        this.bar_th_104_date_accept = data.body.contact.bar_th_104_date_accept
          ? moment(
              data.body.contact.bar_th_104_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_104_date_accept
        this.bar_th_104_prime = data.body.contact.bar_th_104_prime
        this.bar_th_127_date_accept = data.body.contact.bar_th_127_date_accept
          ? moment(
              data.body.contact.bar_th_127_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_127_date_accept
        this.bar_th_127_prime = data.body.contact.bar_th_127_prime
        this.bar_th_148_date_accept = data.body.contact.bar_th_148_date_accept
          ? moment(
              data.body.contact.bar_th_148_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_148_date_accept
        this.bar_th_148_prime = data.body.contact.bar_th_148_prime
        this.bar_th_164_date_accept = data.body.contact.bar_th_164_date_accept
          ? moment(
              data.body.contact.bar_th_164_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.bar_th_164_date_accept
        this.bar_th_164_prime = data.body.contact.bar_th_164_prime
        this.ite_date_accept = data.body.contact.ite_date_accept
          ? moment(
              data.body.contact.ite_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.ite_date_accept
        this.ite_prime = data.body.contact.ite_prime
        this.iti_prime = data.body.contact.iti_prime
        this.iti_date_accept = data.body.contact.iti_date_accept
          ? moment(
              data.body.contact.iti_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.iti_date_accept
        this.projets = data.body.contact.projets
        this.reference_fiscal_avis = data.body.contact.reference_fiscal_avis
          ? data.body.contact.reference_fiscal_avis
          : []
        this.tax_number = data.body.contact.tax_number
        this.tax_number_2 = data.body.contact.tax_number_2
        this.vmc_double_flux_date_accept = data.body.contact
          .vmc_double_flux_date_accept
          ? moment(
              data.body.contact.vmc_double_flux_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.vmc_double_flux_date_accept
        this.vmc_double_flux_prime = data.body.contact.vmc_double_flux_prime
        this.vmc_simple_flux_date_accept = data.body.contact
          .vmc_simple_flux_date_accept
          ? moment(
              data.body.contact.vmc_simple_flux_date_accept,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.body.contact.vmc_simple_flux_date_accept
        this.vmc_simple_flux_prime = data.body.contact.vmc_simple_flux_prime
      }
      if (data.body.projet) {
        this.categoriesProjets = data.body.projet.categories
        this.iauditor_id = data.body.projet.iauditor_id
        this.iauditor_technique_id = data.body.projet.iauditor_technique_id
        this.bt_elec_capacite = data.body.projet.bt_elec_capacite
        this.bt_elec_marque = data.body.projet.bt_elec_marque
        this.bt_solaire_capacite = data.body.projet.bt_solaire_capacite
        this.bt_solaire_marque = data.body.projet.bt_solaire_marque
        this.bt_thermo_capacite = data.body.projet.bt_thermo_capacite
        this.bt_thermo_marque = data.body.projet.bt_thermo_marque
        this.cee_montant_prime_104 = data.body.projet.cee_montant_prime_104
        this.cee_montant_prime_127 = data.body.projet.cee_montant_prime_127
        this.cee_montant_prime_148 = data.body.projet.cee_montant_prime_148
        this.cp_reference_fiscal_avis = data.body.projet
          .cp_reference_fiscal_avis
          ? data.body.projet.cp_reference_fiscal_avis
          : []
        this.pac_air_air_marque = data.body.projet.pac_air_air_marque
        this.pac_air_air_puiss = data.body.projet.pac_air_air_puiss
        this.pac_air_eau_marque = data.body.projet.pac_air_eau_marque
        this.pac_air_eau_puiss = data.body.projet.pac_air_eau_puiss
        this.pac_eau_eau_marque = data.body.projet.pac_eau_eau_marque
        this.pac_eau_eau_puiss = data.body.projet.pac_eau_eau_puiss
        this.status_document = data.body.projet.status_document
        this.lettre_acroi_color = data.body.projet.lettre_acroi_color
        this.surface_deroule = data.body.projet.surface_deroule
        this.surface_deroule_pose = data.body.projet.surface_deroule_pose
        this.surface_mur = data.body.projet.surface_mur
        this.surface_mur_pose = data.body.projet.surface_mur_pose
        this.surface_pignon = data.body.projet.surface_pignon
        this.surface_pignon_pose = data.body.projet.surface_pignon_pose
        this.surface_plafond = data.body.projet.surface_plafond
        this.surface_plafond_pose = data.body.projet.surface_plafond_pose
        this.surface_rampant = data.body.projet.surface_rampant
        this.surface_rampant_pose = data.body.projet.surface_rampant_pose
        this.surface_souffle = data.body.projet.surface_souffle
        this.surface_souffle_pose = data.body.projet.surface_souffle_pose
        this.surface_iti = data.body.projet.surface_iti
        this.surface_iti_pose = data.body.projet.surface_iti_pose
        this.surface_vide_sanitaire = data.body.projet.surface_vide_sanitaire
        this.surface_vide_sanitaire_pose =
          data.body.projet.surface_vide_sanitaire_pose
        this.tax_fiscal_number = data.body.projet.tax_fiscal_number
        this.tax_household = data.body.projet.tax_household
        this.total = data.body.projet.total
        this.total_pose = data.body.projet.total_pose
      }
      if (data.header) {
        this.pixel_type_id = data.header.pixel_type_id
          ? data.header.pixel_type_id
          : null
        this.user_creator = data.header.creator
        this.type_name = data.header.type ? data.header.type.type_name : null
        if (data.header.type) {
          this.type_name = data.header.type ? data.header.type.type_name : null
          this.type_id = data.header.type ? data.header.type.type_id : null
        }
        this.logo = data.header.logo
        this.title = data.header.title
        this.phones =
          data.header.phone && data.header.phone.number
            ? data.header.phone.number
            : 0
        this.status = data.header.status
        if (data.header.cpipeline) {
          this.pipeline = Pipeline.create(data.header.cpipeline)
        }
        if (data.header.cstage) {
          this.stage = Stage.create(data.header.cstage)
        }
        if (data.header.csubstage) {
          this.subStage = SubStage.create(data.header.csubstage)
        }
        this.created_at = data.header.created_at
        this.dossier = !data.header.dossier || data.header.dossier == 0 ? 0 : 1
        this.photo = !data.header.photo || data.header.photo == 0 ? 0 : 1
        this.confirmation =
          !data.header.confirmation || data.header.confirmation == 0 ? 0 : 1
        this.confirmation_previsite =
          !data.header.confirmation_previsite ||
          data.header.confirmation_previsite == 0
            ? 0
            : 1
        this.depot_id_planning_comms = data.header.depot_id_planning_comms
        this.depot_id_planning_poseurs = data.header.depot_id_planning_poseurs
        this.week_id_planning_comms = data.header.week_id_planning_comms
        this.week_id_planning_poseurs = data.header.week_id_planning_poseurs
        this.pixel_type = data.header.pixel_type
        this.passerelle_status = data.header.passerelle_status
        this.pixel_id = data.header.pixel_id
        this.passerelle_id = data.header.passerelle_id
        this.injected_to_pixel = data.header.injected_to_pixel
      }
      // static
      if (data.static) {
        //  CHAUFFAGE
        this.team_chauffage_name = data.static.team_chauffage_name
        this.team_chauffage_id = data.static.team_chauffage_id
        // ISOLATION
        this.team_isolation_plancher_bas_id =
          data.static.team_isolation_plancher_bas_id
        this.team_isolation_plancher_bas_name =
          data.static.team_isolation_plancher_bas_name
        this.team_isolation_souffle_id = data.static.team_isolation_souffle_id
        this.team_isolation_souffle_name =
          data.static.team_isolation_souffle_name
        this.team_isolation_deroule_id = data.static.team_isolation_deroule_id
        this.team_isolation_deroule_name =
          data.static.team_isolation_deroule_name
        this.team_isolation_ite_id = data.static.team_isolation_ite_id
        this.team_name_isolations = data.static.team_name_isolations
        this.team_isolation_ite_name = data.static.team_isolation_ite_name
        this.team_isolation_iti_id = data.static.team_isolation_iti_id
        this.team_isolation_iti_name = data.static.team_isolation_iti_name
        this.team_isolation_sous_rampant_id =
          data.static.team_isolation_sous_rampant_id
        this.team_isolation_sous_rampant_name =
          data.static.team_isolation_sous_rampant_name
        //
        this.acompte_recu = data.static.acompte_recu
        this.project_id = data.static.id
        this.principal_pipeline_id = data.static.principal_pipeline_id
        this.principal_pipeline_name = data.static.principal_pipeline_name
        this.budget_reel = data.static.budget_reel
        this.created_at = data.static.creation_date
        this.comm_ite = data.static.comm_ite
        this.comm_ite_depots_name = data.static.comm_ite_depots_name
        this.comm_ite_first_name = data.static.comm_ite_first_name
        this.comm_ite_last_name = data.static.comm_ite_last_name
        this.comm_ite_full_name = `${
          data.static.comm_ite_first_name ? data.static.comm_ite_first_name : ''
        } ${
          data.static.comm_ite_first_name ? data.static.comm_ite_last_name : ''
        }`
        this.comm_ite_team_id = data.static.comm_ite_team_id
        this.comm_ite_team_name = data.static.comm_ite_team_name
        this.comm_regie = data.static.comm_regie
        this.visiteur_first_name = data.static.visiteur_first_name
        this.visiteur_last_name = data.static.visiteur_last_name
        this.visiteur_tech = data.static.visiteur_tech
        this.visiteur_tech_full_name = `${
          data.static.visiteur_first_name ? data.static.visiteur_first_name : ''
        } ${
          data.static.visiteur_last_name ? data.static.visiteur_last_name : ''
        }`
        this.comm_sed = data.static.comm_sed
        this.comm_sed_first_name = data.static.comm_sed_first_name
        this.comm_sed_last_name = data.static.comm_sed_last_name
        this.comm_sed_first_full_name = `${
          data.static.comm_sed_first_name ? data.static.comm_sed_first_name : ''
        } ${
          data.static.comm_sed_last_name ? data.static.comm_sed_last_name : ''
        }`
        this.comm_sed_team_id = data.static.comm_sed_team_id
        this.comm_sed_team_name = data.static.comm_sed_team_name
        this.comm_terr = data.static.comm_terr
        this.comm_terr_depots_name = data.static.comm_terr_depots_name
        this.comm_terr_first_name = data.static.comm_terr_first_name
        this.comm_terr_last_name = data.static.comm_terr_last_name
        this.comm_terr_full_name = `${
          data.static.comm_terr_first_name
            ? data.static.comm_terr_first_name
            : ''
        } ${
          data.static.comm_terr_last_name ? data.static.comm_terr_last_name : ''
        }`
        this.comm_terr_team_id = data.static.comm_terr_team_id
        this.comm_terr_team_name = data.static.comm_terr_team_name
        this.confirmation =
          !data.static.confirmation || data.static.confirmation == 0 ? 0 : 1
        this.confirmation_previsite =
          !data.static.confirmation_previsite ||
          data.static.confirmation_previsite == 0
            ? 0
            : 1
        this.contact_full_name = data.static.contact_full_name
        this.contact_id = data.static.contact_id
        this.contact_zipCode = data.static.contact_zipCode
        this.create_date = data.static.creation_date
          ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.creation_date
        this.create_time = data.static.creation_date
          ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
              'HH:mm:ss'
            )
          : data.static.creation_date
        this.update_date = data.static.modification_date
          ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.modification_date
        this.update_time = data.static.modification_date
          ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
              'HH:mm:ss'
            )
          : data.static.modification_date
        this.date_debut_formation = data.static.date_debut_formation
        this.date_fin_formation = data.static.date_fin_formation
        this.date_mpr = data.static.date_mpr
        this.date_previsite = data.static.date_previsite
          ? moment(data.static.date_previsite, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.date_previsite
        this.date_travaux = data.static.date_travaux
          ? moment(data.static.date_travaux, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.date_travaux
        this.dossier = !data.static.dossier || data.static.dossier == 0 ? 0 : 1
        this.pac = data.static.pac
        this.photo = !data.static.photo || data.static.photo == 0 ? 0 : 1
        this.pmr = data.static.pmr
        this.poseur_depots_name = data.static.poseur_depots_name
        this.poseur_first_name = data.static.poseur_first_name
        this.poseur_full_name = `${
          data.static.poseur_first_name ? data.static.poseur_first_name : ''
        } ${data.static.poseur_last_name ? data.static.poseur_last_name : ''}`
        this.poseur_id = data.static.poseur_id
        this.poseur_last_name = data.static.poseur_last_name
        this.precarity = data.static.precarity
        this.prj_activity_recent = data.static.prj_activity_recent
          ? moment(
              data.static.prj_activity_recent,
              'YYYY-MM-DD HH:mm:ss'
            ).format('DD-MM-YYYY')
          : data.static.prj_activity_recent
        this.prj_appel_count = data.static.prj_appel_count
        this.prj_date_closing = ''
        if (data.static.prj_date_closing) {
          this.prj_date_closing = data.static.prj_date_closing
            ? moment(
                data.static.prj_date_closing,
                'YYYY-MM-DD HH:mm:ss'
              ).format('DD-MM-YYYY')
            : data.static.prj_date_closing
        }
        this.prj_type_heating = data.static.prj_type_heating
        this.progression = data.static.progression
        this.qlty_manager = data.static.qlty_manager
        this.qlty_manager_first_name = data.static.qlty_manager_first_name
        this.qlty_manager_last_name = data.static.qlty_manager_last_name
        this.qlty_manager_full_name = `${
          data.static.qlty_manager_first_name
            ? data.static.qlty_manager_first_name
            : ''
        } ${
          data.static.qlty_manager_last_name
            ? data.static.qlty_manager_last_name
            : ''
        }`
        this.qlty_manager_team_id = data.static.qlty_manager_team_id
        this.qlty_manager_team_name = data.static.qlty_manager_team_name
        this.solde_recu = data.static.solde_recu
        this.source = data.static.source
        this.stage_name = data.static.stage
        this.stage_id = data.static.stage_id
        this.statut_mpr = data.static.statut_mpr
        this.statut_previsite = data.static.statut_previsite
        this.statut_travaux = data.static.statut_travaux
        this.cumac = data.static.cumac
        this.type_renovation = data.static.type_renovation
        this.type_dash = data.static.type_dash == 1 ? 1 : 0
        this.ecs_dash = data.static.ecs_dash == 1 ? 1 : 0
        this.ro_dash = data.static.ro_dash == 1 ? 1 : 0
        this.rr_dash = data.static.rr_dash == 1 ? 1 : 0
        this.vmc_dash = data.static.vmc_dash == 1 ? 1 : 0
        this.iso_dash = data.static.iso_dash == 1 ? 1 : 0
        this.chauff_dash = data.static.chauff_dash == 1 ? 1 : 0
        this.ambiance_dash = data.static.ambiance_dash == 1 ? 1 : 0
        this.info_vmc_dash = data.static.info_vmc_dash == 1 ? 1 : 0
        this.sub_stage_color = data.static.sub_stage_color
        this.sub_stage_id = data.static.sub_stage_id
        this.statut_dossier_administratif =
          data.static.statut_dossier_administratif
        this.sub_stage_name = data.static.sub_stage_name
        this.sub_stage_status = data.static.sub_stage_status
        this.surface_deroule = data.static.surface_deroule
        this.surface_deroule_pose = data.static.surface_deroule_pose
        this.surface_iti = data.static.surface_iti
        this.surface_iti_pose = data.static.surface_iti_pose
        this.surface_mur = data.static.surface_mur
        this.surface_mur_pose = data.static.surface_mur_pose
        this.surface_pignon = data.static.surface_pignon
        this.surface_pignon_pose = data.static.surface_pignon_pose
        this.surface_plafond = data.static.surface_plafond
        this.surface_plafond_pose = data.static.surface_plafond_pose
        this.surface_rampant = data.static.surface_rampant
        this.surface_rampant_pose = data.static.surface_rampant_pose
        this.surface_souffle = data.static.surface_souffle
        this.surface_souffle_pose = data.static.surface_souffle_pose
        this.surface_iti = data.static.surface_iti
        this.surface_iti_pose = data.static.surface_iti_pose
        this.surface_vide_sanitaire = data.static.surface_vide_sanitaire
        this.surface_vide_sanitaire_pose =
          data.static.surface_vide_sanitaire_pose
        this.total = data.static.total
        this.total_pose = data.static.total_pose
        this.type_formation = data.static.type_formation
        this.zone = data.static.zone
        this.lettre_acroi_color = data.static.lettre_acroi_color
      }
    } else if (data.static) {
      //  CHAUFFAGE
      this.team_chauffage_name = data.static.team_chauffage_name
      this.team_chauffage_id = data.static.team_chauffage_id
      // ISOLATION
      this.team_isolation_plancher_bas_id =
        data.static.team_isolation_plancher_bas_id
      this.team_isolation_plancher_bas_name =
        data.static.team_isolation_plancher_bas_name
      this.team_isolation_souffle_id = data.static.team_isolation_souffle_id
      this.team_isolation_souffle_name = data.static.team_isolation_souffle_name
      this.team_isolation_deroule_id = data.static.team_isolation_deroule_id
      this.team_isolation_deroule_name = data.static.team_isolation_deroule_name
      this.team_isolation_ite_id = data.static.team_isolation_ite_id
      this.team_name_isolations = data.static.team_name_isolations
      this.team_isolation_ite_name = data.static.team_isolation_ite_name
      this.team_isolation_iti_id = data.static.team_isolation_iti_id
      this.team_isolation_iti_name = data.static.team_isolation_iti_name
      this.team_isolation_sous_rampant_id =
        data.static.team_isolation_sous_rampant_id
      this.team_isolation_sous_rampant_name =
        data.static.team_isolation_sous_rampant_name
      //
      // static
      this.acompte_recu = data.static.acompte_recu
      this.project_id = data.static.id
      this.principal_pipeline_id = data.static.principal_pipeline_id
      this.principal_pipeline_name = data.static.principal_pipeline_name
      this.budget_reel = data.static.budget_reel
      this.created_at = data.static.creation_date
      this.comm_ite = data.static.comm_ite
      this.comm_ite_depots_name = data.static.comm_ite_depots_name
      this.comm_ite_first_name = data.static.comm_ite_first_name
      this.comm_ite_last_name = data.static.comm_ite_last_name
      this.comm_ite_full_name = `${
        data.static.comm_ite_first_name ? data.static.comm_ite_first_name : ''
      } ${
        data.static.comm_ite_first_name ? data.static.comm_ite_last_name : ''
      }`
      this.comm_ite_team_id = data.static.comm_ite_team_id
      this.comm_ite_team_name = data.static.comm_ite_team_name
      this.comm_regie = data.static.comm_regie
      this.visiteur_first_name = data.static.visiteur_first_name
      this.visiteur_last_name = data.static.visiteur_last_name
      this.visiteur_tech = data.static.visiteur_tech
      this.visiteur_tech_full_name = `${
        data.static.visiteur_first_name ? data.static.visiteur_first_name : ''
      } ${data.static.visiteur_last_name ? data.static.visiteur_last_name : ''}`
      this.comm_sed = data.static.comm_sed
      this.comm_sed_first_name = data.static.comm_sed_first_name
      this.comm_sed_last_name = data.static.comm_sed_last_name
      this.comm_sed_first_full_name = `${
        data.static.comm_sed_first_name ? data.static.comm_sed_first_name : ''
      } ${data.static.comm_sed_last_name ? data.static.comm_sed_last_name : ''}`
      this.comm_sed_team_id = data.static.comm_sed_team_id
      this.comm_sed_team_name = data.static.comm_sed_team_name
      this.comm_terr = data.static.comm_terr
      this.comm_terr_depots_name = data.static.comm_terr_depots_name
      this.comm_terr_first_name = data.static.comm_terr_first_name
      this.comm_terr_full_name = `${
        data.static.comm_terr_first_name ? data.static.comm_terr_first_name : ''
      } ${
        data.static.comm_terr_last_name ? data.static.comm_terr_last_name : ''
      }`
      this.comm_terr_last_name = data.static.comm_terr_last_name
      this.comm_terr_team_id = data.static.comm_terr_team_id
      this.comm_terr_team_name = data.static.comm_terr_team_name
      this.confirmation =
        !data.static.confirmation || data.static.confirmation == 0 ? 0 : 1
      this.confirmation_previsite =
        !data.static.confirmation_previsite ||
        data.static.confirmation_previsite == 0
          ? 0
          : 1
      this.contact_full_name = data.static.contact_full_name
      this.contact_id = data.static.contact_id
      this.contact_zipCode = data.static.contact_zipCode
      this.create_date = data.static.creation_date
        ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.static.creation_date
      this.create_time = data.static.creation_date
        ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
            'HH:mm:ss'
          )
        : data.static.creation_date
      this.update_date = data.static.modification_date
        ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.static.modification_date
      this.update_time = data.static.modification_date
        ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
            'HH:mm:ss'
          )
        : data.static.modification_date
      this.date_debut_formation = data.static.date_debut_formation
      this.date_fin_formation = data.static.date_fin_formation
      this.date_mpr = data.static.date_mpr
      this.date_previsite = data.static.date_previsite
        ? moment(data.static.date_previsite, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.static.date_previsite
      this.date_travaux = data.static.date_travaux
        ? moment(data.static.date_travaux, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.static.date_travaux
      this.dossier = !data.static.dossier || data.static.dossier == 0 ? 0 : 1
      this.pac = data.static.pac
      this.photo = !data.static.photo || data.static.photo == 0 ? 0 : 1
      this.pmr = data.static.pmr
      this.poseur_depots_name = data.static.poseur_depots_name
      this.poseur_first_name = data.static.poseur_first_name
      this.poseur_full_name = `${
        data.static.poseur_first_name ? data.static.poseur_first_name : ''
      } ${data.static.poseur_last_name ? data.static.poseur_last_name : ''}`
      this.poseur_id = data.static.poseur_id
      this.poseur_last_name = data.static.poseur_last_name
      this.precarity = data.static.precarity
      this.prj_activity_recent = data.static.prj_activity_recent
        ? moment(data.static.prj_activity_recent, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.static.prj_activity_recent
      this.prj_appel_count = data.static.prj_appel_count
      this.prj_date_closing = ''
      if (data.static.prj_date_closing) {
        this.prj_date_closing = data.static.prj_date_closing
          ? moment(data.static.prj_date_closing, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.static.prj_date_closing
      }
      this.prj_type_heating = data.static.prj_type_heating
      this.progression = data.static.progression
      this.qlty_manager = data.static.qlty_manager
      this.qlty_manager_first_name = data.static.qlty_manager_first_name
      this.qlty_manager_last_name = data.static.qlty_manager_last_name
      this.qlty_manager_full_name = `${
        data.static.qlty_manager_first_name
          ? data.static.qlty_manager_first_name
          : ''
      } ${
        data.static.qlty_manager_last_name
          ? data.static.qlty_manager_last_name
          : ''
      }`
      this.qlty_manager_team_id = data.static.qlty_manager_team_id
      this.qlty_manager_team_name = data.static.qlty_manager_team_name
      this.solde_recu = data.static.solde_recu
      this.source = data.static.source
      this.stage_name = data.static.stage
      this.stage_id = data.static.stage_id
      this.statut_mpr = data.static.statut_mpr
      this.statut_previsite = data.static.statut_previsite
      this.statut_travaux = data.static.statut_travaux
      this.cumac = data.static.cumac
      this.type_renovation = data.static.type_renovation
      this.type_dash = data.static.type_dash == 1 ? 1 : 0
      this.ecs_dash = data.static.ecs_dash == 1 ? 1 : 0
      this.ro_dash = data.static.ro_dash == 1 ? 1 : 0
      this.rr_dash = data.static.rr_dash == 1 ? 1 : 0
      this.vmc_dash = data.static.vmc_dash == 1 ? 1 : 0
      this.iso_dash = data.static.iso_dash == 1 ? 1 : 0
      this.chauff_dash = data.static.chauff_dash == 1 ? 1 : 0
      this.ambiance_dash = data.static.ambiance_dash == 1 ? 1 : 0
      this.info_vmc_dash = data.static.info_vmc_dash == 1 ? 1 : 0
      this.sub_stage_color = data.static.sub_stage_color
      this.sub_stage_id = data.static.sub_stage_id
      this.statut_dossier_administratif =
        data.static.statut_dossier_administratif
      this.sub_stage_name = data.static.sub_stage_name
      this.sub_stage_status = data.static.sub_stage_status
      this.surface_deroule = data.static.surface_deroule
      this.surface_deroule_pose = data.static.surface_deroule_pose
      this.surface_iti = data.static.surface_iti
      this.surface_iti_pose = data.static.surface_iti_pose
      this.surface_mur = data.static.surface_mur
      this.surface_mur_pose = data.static.surface_mur_pose
      this.surface_pignon = data.static.surface_pignon
      this.surface_pignon_pose = data.static.surface_pignon_pose
      this.surface_plafond = data.static.surface_plafond
      this.surface_plafond_pose = data.static.surface_plafond_pose
      this.surface_rampant = data.static.surface_rampant
      this.surface_rampant_pose = data.static.surface_rampant_pose
      this.surface_souffle = data.static.surface_souffle
      this.surface_souffle_pose = data.static.surface_souffle_pose
      this.surface_iti = data.static.surface_iti
      this.surface_iti_pose = data.static.surface_iti_pose
      this.surface_vide_sanitaire = data.static.surface_vide_sanitaire
      this.surface_vide_sanitaire_pose = data.static.surface_vide_sanitaire_pose
      this.total = data.static.total
      this.total_pose = data.static.total_pose
      this.type_formation = data.static.type_formation
      this.zone = data.static.zone
      this.lettre_acroi_color = data.static.lettre_acroi_color
    } else {
      // PROJECT  TYPE TH164
      // Pop UP
      // commentaire
      //  CHAUFFAGE
      this.team_chauffage_name = data.team_chauffage_name
      this.team_chauffage_id = data.team_chauffage_id
      // ISOLATION
      this.team_isolation_plancher_bas_id = data.team_isolation_plancher_bas_id
      this.team_isolation_plancher_bas_name =
        data.team_isolation_plancher_bas_name
      this.team_isolation_souffle_id = data.team_isolation_souffle_id
      this.team_isolation_souffle_name = data.team_isolation_souffle_name
      this.team_isolation_deroule_id = data.team_isolation_deroule_id
      this.team_isolation_deroule_name = data.team_isolation_deroule_name
      this.team_isolation_ite_id = data.team_isolation_ite_id
      this.team_name_isolations = data.team_name_isolations
      this.team_isolation_ite_name = data.team_isolation_ite_name
      this.team_isolation_iti_id = data.team_isolation_iti_id
      this.team_isolation_iti_name = data.team_isolation_iti_name
      this.team_isolation_sous_rampant_id = data.team_isolation_sous_rampant_id
      this.team_isolation_sous_rampant_name =
        data.team_isolation_sous_rampant_name
      //
      this.ecs_comments = data.ecs_comments
      this.rr_comments = data.rr_comments
      this.ro_comments = data.ro_comments
      this.s_comments = data.s_comments
      this.d_comments = data.d_comments
      this.p_comments = data.p_comments
      this.r_comments = data.r_comments
      this.m_comments = data.m_comments
      this.ite_comments = data.ite_comments
      this.vmc_comments = data.vmc_comments
      this.cofrac_comments = data.cofrac_comments
      this.nom_client_comments = data.nom_client_comments
      //
      this.ecs_label_date_pose = data.ecs_label_date_pose
      this.ro_label_date_pose = data.ro_label_date_pose
      this.rr_label_date_pose = data.rr_label_date_pose
      this.s_label_date_pose = data.s_label_date_pose
      this.d_label_date_pose = data.d_label_date_pose
      this.p_label_date_pose = data.p_label_date_pose
      this.r_label_date_pose = data.r_label_date_pose
      this.m_label_date_pose = data.m_label_date_pose
      this.ite_label_date_pose = data.ite_label_date_pose
      this.vmc_label_date_pose = data.vmc_label_date_pose
      //
      this.alimentation_rr = data.alimentation_rr
      this.alimentation_ro = data.alimentation_ro
      this.ite_demande_mairie =
        data.ite_demande_mairie == 1
          ? 'Oui'
          : data.ite_demande_mairie == 0
          ? 'Non'
          : data.ite_demande_mairie
      this.s_marque = data.s_marque
      this.fournisseur_s = data.fournisseur_s
      this.s_dispo = data.s_dispo
      this.s_num_facture = data.s_num_facture
      this.s_montant_facture = data.s_montant_facture
        ? data.s_montant_facture
        : ''
      this.s_montant_facture_ttc = data.s_montant_facture_ttc
        ? data.s_montant_facture_ttc
        : ''
      this.s_num_facture_pose = data.s_num_facture_pose
      this.s_montant_facture_ht_pose = data.s_montant_facture_ht_pose
        ? data.s_montant_facture_ht_pose
        : ''
      this.s_montant_facture_ttc_pose = data.s_montant_facture_ttc_pose
        ? data.s_montant_facture_ttc_pose
        : ''
      this.s_etat_facture = data.s_etat_facture
      this.fournit_pose_s =
        data.fournit_pose_s == 1
          ? 'Oui'
          : data.fournit_pose_s == 0
          ? 'Non'
          : data.fournit_pose_s

      this.d_marque = data.d_marque
      this.fournisseur_d = data.fournisseur_d
      this.d_dispo = data.d_dispo
      this.d_num_facture = data.d_num_facture
      this.d_montant_facture = data.d_montant_facture
        ? data.d_montant_facture
        : ''
      this.d_montant_facture_ttc = data.d_montant_facture_ttc
        ? data.d_montant_facture_ttc
        : ''
      this.d_num_facture_pose = data.d_num_facture_pose
      this.d_montant_facture_ht_pose = data.d_montant_facture_ht_pose
        ? data.d_montant_facture_ht_pose
        : ''
      this.d_montant_facture_ttc_pose = data.d_montant_facture_ttc_pose
        ? data.d_montant_facture_ttc_pose
        : ''
      this.d_etat_facture = data.d_etat_facture
      this.fournit_pose_d =
        data.fournit_pose_d == 1
          ? 'Oui'
          : data.fournit_pose_d == 0
          ? 'Non'
          : data.fournit_pose_d

      this.p_marque = data.p_marque
      this.fournisseur_p = data.fournisseur_p
      this.p_dispo = data.p_dispo
      this.p_num_facture = data.p_num_facture
      this.p_montant_facture = data.p_montant_facture
        ? data.p_montant_facture
        : ''
      this.p_montant_facture_ttc = data.p_montant_facture_ttc
        ? data.p_montant_facture_ttc
        : ''
      this.p_num_facture_pose = data.p_num_facture_pose
      this.p_montant_facture_ht_pose = data.p_montant_facture_ht_pose
        ? data.p_montant_facture_ht_pose
        : ''
      this.p_montant_facture_ttc_pose = data.p_montant_facture_ttc_pose
        ? data.p_montant_facture_ttc_pose
        : ''
      this.p_etat_facture = data.p_etat_facture
      this.fournit_pose_p =
        data.fournit_pose_p == 1
          ? 'Oui'
          : data.fournit_pose_p == 0
          ? 'Non'
          : data.fournit_pose_p

      this.r_marque = data.r_marque
      this.fournisseur_r = data.fournisseur_r
      this.r_dispo = data.r_dispo
      this.r_num_facture = data.r_num_facture
      this.r_montant_facture = data.r_montant_facture
        ? data.r_montant_facture
        : ''
      this.r_montant_facture_ttc = data.r_montant_facture_ttc
        ? data.r_montant_facture_ttc
        : ''
      this.r_num_facture_pose = data.r_num_facture_pose
      this.r_montant_facture_ht_pose = data.r_montant_facture_ht_pose
        ? data.r_montant_facture_ht_pose
        : ''
      this.r_montant_facture_ttc_pose = data.r_montant_facture_ttc_pose
        ? data.r_montant_facture_ttc_pose
        : ''
      this.r_etat_facture = data.r_etat_facture
      this.fournit_pose_r =
        data.fournit_pose_r == 1
          ? 'Oui'
          : data.fournit_pose_r == 0
          ? 'Non'
          : data.fournit_pose_r

      this.m_marque = data.m_marque
      this.fournisseur_m = data.fournisseur_m
      this.m_dispo = data.m_dispo
      this.m_num_facture = data.m_num_facture
      this.m_montant_facture = data.m_montant_facture
        ? data.m_montant_facture
        : ''
      this.m_montant_facture_ttc = data.m_montant_facture_ttc
        ? data.m_montant_facture_ttc
        : ''
      this.m_num_facture_pose = data.m_num_facture_pose
      this.m_montant_facture_ht_pose = data.m_montant_facture_ht_pose
        ? data.m_montant_facture_ht_pose
        : ''
      this.m_montant_facture_ttc_pose = data.m_montant_facture_ttc_pose
        ? data.m_montant_facture_ttc_pose
        : ''
      this.m_etat_facture = data.m_etat_facture
      this.fournit_pose_m =
        data.fournit_pose_m == 1
          ? 'Oui'
          : data.fournit_pose_m == 0
          ? 'Non'
          : data.fournit_pose_m

      this.ite_marque = data.ite_marque
      this.fournisseur_ite = data.fournisseur_ite
      this.ite_dispo = data.ite_dispo
      this.ite_num_facture = data.ite_num_facture
      this.ite_montant_facture = data.ite_montant_facture
        ? data.ite_montant_facture
        : ''
      this.ite_montant_facture_ttc = data.ite_montant_facture_ttc
        ? data.ite_montant_facture_ttc
        : ''
      this.ite_num_facture_pose = data.ite_num_facture_pose
      this.ite_montant_facture_ht_pose = data.ite_montant_facture_ht_pose
        ? data.ite_montant_facture_ht_pose
        : ''
      this.ite_montant_facture_ttc_pose = data.ite_montant_facture_ttc_pose
        ? data.ite_montant_facture_ttc_pose
        : ''
      this.ite_etat_facture = data.ite_etat_facture
      this.fournit_pose_ite =
        data.fournit_pose_ite == 1
          ? 'Oui'
          : data.fournit_pose_ite == 0
          ? 'Non'
          : data.fournit_pose_ite

      this.vmc_marque = data.vmc_marque
      this.vmc_flux = data.vmc_flux
      this.vmc_bouche = data.vmc_bouche
      this.fournisseur_vmc = data.fournisseur_vmc
      this.vmc_dispo = data.vmc_dispo
      this.vmc_num_facture = data.vmc_num_facture
      this.vmc_montant_facture = data.vmc_montant_facture
        ? data.vmc_montant_facture
        : ''
      this.vmc_montant_facture_ttc = data.vmc_montant_facture_ttc
        ? data.vmc_montant_facture_ttc
        : ''
      this.vmc_num_facture_pose = data.vmc_num_facture_pose
      this.vmc_montant_facture_ht_pose = data.vmc_montant_facture_ht_pose
        ? data.vmc_montant_facture_ht_pose
        : ''
      this.vmc_montant_facture_ttc_pose = data.vmc_montant_facture_ttc_pose
        ? data.vmc_montant_facture_ttc_pose
        : ''
      this.vmc_etat_facture = data.vmc_etat_facture
      this.fournit_pose_vmc =
        data.fournit_pose_vmc == 1
          ? 'Oui'
          : data.fournit_pose_vmc == 0
          ? 'Non'
          : data.fournit_pose_vmc
      this.adresse = data.adresse
      this.label_pose_termine = data.label_pose_termine
      this.label_email_charge = data.label_email_charge
      this.label_etat_devis = data.label_etat_devis
      this.label_etat_visite = data.label_etat_visite
      this.created_at = data.created_at
      this.logement_type = data.logement_type
      this.occupation = data.occupation
      this.fournisseur_bt = data.fournisseur_bt
      this.fournisseur_ro = data.fournisseur_ro
      this.fournisseur_rr = data.fournisseur_rr
      this.bt_ballon = data.bt_ballon ? data.bt_ballon : ''
      this.bt_dispo = data.bt_dispo
      this.bt_etat_facture = data.bt_etat_facture
      this.bt_marque = data.bt_marque ? data.bt_marque : ''
      this.bt_montant_facture = data.bt_montant_facture
        ? data.bt_montant_facture
        : ''
      this.bt_montant_facture_ttc = data.bt_montant_facture_ttc
        ? data.bt_montant_facture_ttc
        : ''
      this.bt_montant_facture_ht_pose = data.bt_montant_facture_ht_pose
        ? data.bt_montant_facture_ht_pose
        : ''
      this.bt_montant_facture_ttc_pose = data.bt_montant_facture_ttc_pose
        ? data.bt_montant_facture_ttc_pose
        : ''
      this.bt_num_facture = data.bt_num_facture
      this.bt_num_facture_pose = data.bt_num_facture_pose
      this.fournit_pose_bt =
        data.fournit_pose_bt == 1
          ? 'Oui'
          : data.fournit_pose_bt == 0
          ? 'Non'
          : data.fournit_pose_bt
      this.fournit_pose_ro =
        data.fournit_pose_ro == 1
          ? 'Oui'
          : data.fournit_pose_ro == 0
          ? 'Non'
          : data.fournit_pose_ro
      this.fournit_pose_rr =
        data.fournit_pose_rr == 1
          ? 'Oui'
          : data.fournit_pose_rr == 0
          ? 'Non'
          : data.fournit_pose_rr
      this.bt_poseur = data.bt_poseur
      this.chauffage = data.chauffage
      this.comm_sed_envoi_email = data.comm_sed_envoi_email
      this.comm_sed_info_client = data.comm_sed_info_client
      this.comm_terrain = data.comm_terrain
      this.cp = data.cp
      this.created_at = data.created_at
        ? moment(data.created_at, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.created_at
      this.date_bao = data.date_bao
        ? moment(data.date_bao, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_bao
      this.date_bt_planifie = data.date_bt_planifie
        ? moment(data.date_bt_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_bt_planifie
      this.date_bt_pose = data.date_bt_pose
        ? moment(data.date_bt_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_bt_pose
      this.date_envoi_email = data.date_envoi_email
        ? moment(data.date_envoi_email, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_envoi_email
      this.date_iso_externe_planifie = data.date_iso_externe_planifie
        ? moment(data.date_iso_externe_planifie, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_externe_planifie
      this.date_iso_externe_pose = data.date_iso_externe_pose
        ? moment(data.date_iso_externe_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_iso_externe_pose
      this.date_iso_interne_planifie_deroule = data.date_iso_interne_planifie_deroule
        ? moment(data.date_iso_interne_planifie_deroule, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_deroule
      this.date_iso_interne_planifie_mur_plus = data.date_iso_interne_planifie_mur_plus
        ? moment(data.date_iso_interne_planifie_mur_plus, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_mur_plus
      this.date_iso_interne_planifie_plafond = data.date_iso_interne_planifie_plafond
        ? moment(data.date_iso_interne_planifie_plafond, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_plafond
      this.date_iso_interne_planifie_rampant_plus = data.date_iso_interne_planifie_rampant_plus
        ? moment(
            data.date_iso_interne_planifie_rampant_plus,
            'YYYY-MM-DD'
          ).format('DD-MM-YYYY')
        : data.date_iso_interne_planifie_rampant_plus
      this.date_iso_interne_planifie_souffle = data.date_iso_interne_planifie_souffle
        ? moment(data.date_iso_interne_planifie_souffle, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_planifie_souffle
      this.date_iso_interne_pose_deroule = data.date_iso_interne_pose_deroule
        ? moment(data.date_iso_interne_pose_deroule, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_deroule
      this.date_iso_interne_pose_mur_plus = data.date_iso_interne_pose_mur_plus
        ? moment(data.date_iso_interne_pose_mur_plus, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_mur_plus
      this.date_iso_interne_pose_plafond = data.date_iso_interne_pose_plafond
        ? moment(data.date_iso_interne_pose_plafond, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_plafond
      this.date_iso_interne_pose_rampant_plus = data.date_iso_interne_pose_rampant_plus
        ? moment(data.date_iso_interne_pose_rampant_plus, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_rampant_plus
      this.date_iso_interne_pose_souffle = data.date_iso_interne_pose_souffle
        ? moment(data.date_iso_interne_pose_souffle, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_iso_interne_pose_souffle
      this.date_pose_termine = data.date_pose_termine
        ? moment(data.date_pose_termine, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_pose_termine
      this.date_relv_info = data.date_relv_info
        ? moment(data.date_relv_info, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_relv_info
      this.date_ro_planifie = data.date_ro_planifie
        ? moment(data.date_ro_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_ro_planifie
      this.date_ro_pose = data.date_ro_pose
        ? moment(data.date_ro_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_ro_pose
      this.date_rr_planifie = data.date_rr_planifie
        ? moment(data.date_rr_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_rr_planifie
      this.date_rr_pose = data.date_rr_pose
        ? moment(data.date_rr_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_rr_pose
      this.date_sign_devis = data.date_sign_devis
        ? moment(data.date_sign_devis, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_sign_devis
      this.date_signature_cee = data.date_signature_cee
        ? moment(data.date_signature_cee, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_signature_cee
      this.date_visite_cofrac_prevu = data.date_visite_cofrac_prevu
        ? moment(data.date_visite_cofrac_prevu, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_visite_cofrac_prevu
      this.date_visite_cofrac_visite = data.date_visite_cofrac_visite
        ? moment(data.date_visite_cofrac_visite, 'YYYY-MM-DD').format(
            'DD-MM-YYYY'
          )
        : data.date_visite_cofrac_visite
      this.date_visite_tech = data.date_visite_tech
        ? moment(data.date_visite_tech, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_visite_tech
      this.date_vmc_planifie = data.date_vmc_planifie
        ? moment(data.date_vmc_planifie, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_vmc_planifie
      this.date_vmc_pose = data.date_vmc_pose
        ? moment(data.date_vmc_pose, 'YYYY-MM-DD').format('DD-MM-YYYY')
        : data.date_vmc_pose
      this.email = data.email
      this.filiale = data.filiale
      this.ingenieur_bao = data.ingenieur_bao
      this.num_fisc = data.num_fisc
      this.parcelle = data.parcelle
      this.phone = data.phone
      this.poseur_iso_externe = data.poseur_iso_externe
      this.poseur_iso_interne_deroule = data.poseur_iso_interne_deroule
      this.poseur_iso_interne_mur_plus = data.poseur_iso_interne_mur_plus
      this.poseur_iso_interne_plafond = data.poseur_iso_interne_plafond
      this.poseur_iso_interne_rampant_plus =
        data.poseur_iso_interne_rampant_plus
      this.poseur_iso_interne_souffle = data.poseur_iso_interne_souffle
      this.poseur_vmc = data.poseur_vmc
      this.precar = data.precar
      this.prime_cee = data.prime_cee
      this.ref_fisc = data.ref_fisc
      this.regie = data.regie
      this.remise = data.remise
      this.reste_a_payer = data.reste_a_payer
      this.ro_capacite = data.ro_capacite ? data.ro_capacite : ''
      this.ro_dispo = data.ro_dispo
      this.ro_etat_facture = data.ro_etat_facture
      this.ro_marque = data.ro_marque ? data.ro_marque : ''
      this.ro_montant_facture = data.ro_montant_facture
        ? data.ro_montant_facture
        : ''
      this.ro_montant_facture_ht_pose = data.ro_montant_facture_ht_pose
        ? data.ro_montant_facture_ht_pose
        : ''
      this.ro_montant_facture_ttc = data.ro_montant_facture_ttc
        ? data.ro_montant_facture_ttc
        : ''
      this.ro_montant_facture_ttc_pose = data.ro_montant_facture_ttc_pose
        ? data.ro_montant_facture_ttc_pose
        : ''
      this.ro_num_facture = data.ro_num_facture
      this.ro_num_facture_pose = data.ro_num_facture_pose
      this.ro_poseur = data.ro_poseur
      this.rr_capacite = data.rr_capacite ? data.rr_capacite : ''
      this.rr_dispo = data.rr_dispo
      this.rr_etat_facture = data.rr_etat_facture
      this.rr_marque = data.rr_marque ? data.rr_marque : ''
      this.rr_montant_facture = data.rr_montant_facture
        ? data.rr_montant_facture
        : ''
      this.rr_montant_facture_ht_pose = data.rr_montant_facture_ht_pose
        ? data.rr_montant_facture_ht_pose
        : ''
      this.rr_montant_facture_ttc = data.rr_montant_facture_ttc
        ? data.rr_montant_facture_ttc
        : ''
      this.rr_montant_facture_ttc_pose = data.rr_montant_facture_ttc_pose
        ? data.rr_montant_facture_ttc_pose
        : ''
      this.rr_num_facture = data.rr_num_facture
      this.rr_num_facture_pose = data.rr_num_facture_pose
      this.rr_poseur = data.rr_poseur
      this.status_visite_cofrac = data.status_visite_cofrac
      this.statut_bao = data.statut_bao
      this.statut_client = data.statut_client
      this.statut_relv_info = data.statut_relv_info
      this.surface_deroule = data.surface_deroule
      this.surface_externe = data.surface_externe
      this.surface_habitable = data.surface_habitable
      this.surface_mur_plus = data.surface_mur_plus
      this.surface_plafond = data.surface_plafond
      this.surface_rampant_plus = data.surface_rampant_plus
      this.surface_souffle = data.surface_souffle
      this.titre = data.titre
      this.total_ht = data.total_ht
      this.total_tt = data.total_tt
      this.total_ttc = data.total_ttc
      this.updated_at = data.updated_at
      this.ville = data.ville
      this.visiteur_cofrac = data.visiteur_cofrac
      this.vmc = data.vmc
      this.vue = data.vue
      // Project
      this.checked = data.checked || false
      this.acompte_recu = data.acompte_recu
      this.project_id = data.id
      this.principal_pipeline_id = data.principal_pipeline_id
      this.principal_pipeline_name = data.principal_pipeline_name
      this.budget_reel = data.budget_reel
      this.date_debut_formation = data.date_debut_formation
      this.date_fin_formation = data.date_fin_formation
      this.progression = data.progression
      this.solde_recu = data.solde_recu
      this.type_formation = data.type_formation
      this.lettre_acroi_color = data.lettre_acroi_color
      this.comm_ite = data.comm_ite
      this.comm_ite_depots_name = data.comm_ite_depots_name
      this.comm_ite_first_name = data.comm_ite_first_name
      this.comm_ite_last_name = data.comm_ite_last_name
      this.comm_ite_full_name = `${
        data.comm_ite_first_name ? data.comm_ite_first_name : ''
      } ${data.comm_ite_last_name ? data.comm_ite_last_name : ''}`
      this.comm_ite_team_id = data.comm_ite_team_id
      this.comm_ite_team_name = data.comm_ite_team_name
      this.comm_regie = data.comm_regie
      this.visiteur_first_name = data.visiteur_first_name
      this.visiteur_last_name = data.visiteur_last_name
      this.visiteur_tech = data.visiteur_tech
      this.visiteur_tech_full_name = `${
        data.visiteur_first_name ? data.visiteur_first_name : ''
      } ${data.visiteur_last_name ? data.visiteur_last_name : ''}`
      this.comm_sed = data.comm_sed
      this.comm_sed_first_name = data.comm_sed_first_name
      this.comm_sed_last_name = data.comm_sed_last_name
      this.comm_sed_first_full_name = `${
        data.comm_sed_first_name ? data.comm_sed_first_name : ''
      } ${data.comm_sed_last_name ? data.comm_sed_last_name : ''}`
      this.comm_sed_team_id = data.comm_sed_team_id
      this.comm_sed_team_name = data.comm_sed_team_name
      this.comm_terr = data.comm_terr
      this.comm_terr_depots_name = data.comm_terr_depots_name
      this.comm_terr_first_name = data.comm_terr_first_name
      this.comm_terr_full_name = `${
        data.comm_terr_first_name ? data.comm_terr_first_name : ''
      } ${data.comm_terr_last_name ? data.comm_terr_last_name : ''}`
      this.comm_terr_last_name = data.comm_terr_last_name
      this.comm_terr_team_id = data.comm_terr_team_id
      this.comm_terr_team_name = data.comm_terr_team_name
      this.confirmation = !data.confirmation || data.confirmation == 0 ? 0 : 1
      this.confirmation_previsite =
        !data.confirmation_previsite || data.confirmation_previsite == 0 ? 0 : 1
      this.dossier = !data.dossier || data.dossier == 0 ? 0 : 1
      this.photo = !data.photo || data.photo == 0 ? 0 : 1
      this.contact_full_name = data.contact_full_name
      this.contact_id = data.contact_id
      this.contact_zipCode = data.contact_zipCode
      this.create_date = data.creation_date
        ? moment(data.creation_date, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
        : data.creation_date
      this.create_time = data.creation_date
        ? moment(data.creation_date, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
        : data.creation_date
      this.update_date = data.modification_date
        ? moment(data.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.modification_date
      this.update_time = data.modification_date
        ? moment(data.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
            'HH:mm:ss'
          )
        : data.modification_date
      this.date_mpr = data.date_mpr
        ? moment(data.date_mpr, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
        : data.date_mpr
      this.date_previsite = data.date_previsite
        ? moment(data.date_previsite, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.date_previsite
      this.date_travaux = data.date_travaux
        ? moment(data.date_travaux, 'YYYY-MM-DD HH:mm:ss').format('DD-MM-YYYY')
        : data.date_travaux
      this.pac = data.pac
      this.pmr = data.pmr
      this.poseur_depots_name = data.poseur_depots_name
      this.poseur_full_name = `${
        data.poseur_first_name ? data.poseur_first_name : ''
      } ${data.poseur_last_name ? data.poseur_last_name : ''}`
      this.poseur_id = data.poseur_id
      this.precarity = data.precarity
      this.prj_activity_recent = data.prj_activity_recent
        ? moment(data.prj_activity_recent, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.prj_activity_recent
      this.prj_appel_count = data.prj_appel_count
      this.prj_date_closing = ''
      if (data.prj_date_closing) {
        this.prj_date_closing = data.prj_date_closing
          ? moment(data.prj_date_closing, 'YYYY-MM-DD HH:mm:ss').format(
              'DD-MM-YYYY'
            )
          : data.prj_date_closing
      }
      this.prj_type_heating = data.prj_type_heating
      this.qlty_manager = data.qlty_manager
      this.qlty_manager_first_name = data.qlty_manager_first_name
      this.qlty_manager_last_name = data.qlty_manager_last_name
      this.qlty_manager_full_name = `${
        data.qlty_manager_first_name ? data.qlty_manager_first_name : ''
      } ${data.qlty_manager_last_name ? data.qlty_manager_last_name : ''}`
      this.qlty_manager_team_id = data.qlty_manager_team_id
      this.qlty_manager_team_name = data.qlty_manager_team_name
      this.source = data.source
      if (data.cpipeline) {
        this.pipeline = Pipeline.create(data.cpipeline)
      }
      if (data.cstage) {
        this.stage = Stage.create(data.cstage)
      }
      if (data.csubstage) {
        this.subStage = SubStage.create(data.csubstage)
      }
      this.stage_name = data.stage
      this.stage_id = data.stage_id
      this.statut_mpr = data.statut_mpr
      this.statut_previsite = data.statut_previsite
      this.statut_travaux = data.statut_travaux
      this.cumac = data.cumac
      this.file_cat_color = data.file_cat_color
      this.type_renovation = data.type_renovation
      this.type_dash = data.type_dash == 1 ? 1 : 0
      this.ecs_dash = data.ecs_dash == 1 ? 1 : 0
      this.ro_dash = data.ro_dash == 1 ? 1 : 0
      this.rr_dash = data.rr_dash == 1 ? 1 : 0
      this.vmc_dash = data.vmc_dash == 1 ? 1 : 0
      this.iso_dash = data.iso_dash == 1 ? 1 : 0
      this.chauff_dash = data.chauff_dash == 1 ? 1 : 0
      this.ambiance_dash = data.ambiance_dash == 1 ? 1 : 0
      this.info_vmc_dash = data.info_vmc_dash == 1 ? 1 : 0
      this.sub_stage_color = data.sub_stage_color
      this.sub_stage_id = data.sub_stage_id
      this.statut_dossier_administratif = data.statut_dossier_administratif
      this.sub_stage_name = data.sub_stage_name
      this.sub_stage_status = data.sub_stage_status
      this.surface_deroule = data.surface_deroule
      this.surface_deroule_pose = data.surface_deroule_pose
      this.surface_iti = data.surface_iti
      this.surface_iti_pose = data.surface_iti_pose
      this.surface_mur = data.surface_mur
      this.surface_mur_pose = data.surface_mur_pose
      this.surface_pignon = data.surface_pignon
      this.surface_pignon_pose = data.surface_pignon_pose
      this.surface_plafond = data.surface_plafond
      this.surface_plafond_pose = data.surface_plafond_pose
      this.surface_rampant = data.surface_rampant
      this.surface_rampant_pose = data.surface_rampant_pose
      this.surface_souffle = data.surface_souffle
      this.surface_souffle_pose = data.surface_souffle_pose
      this.surface_iti = data.surface_iti
      this.surface_iti_pose = data.surface_iti_pose
      this.surface_vide_sanitaire = data.surface_vide_sanitaire
      this.surface_vide_sanitaire_pose = data.surface_vide_sanitaire_pose
      this.total = data.total
      this.total_pose = data.total_pose
      this.zone = data.zone
      this.activities = []
      this.journals = []
      this.comments = []
      this.files = []
      this.timeline = []
    }
  }

  formatedObjet(data) {
    //  CHAUFFAGE
    this.team_chauffage_name = data.static.team_chauffage_name
    this.team_chauffage_id = data.static.team_chauffage_id
    // ISOLATION
    this.team_isolation_plancher_bas_id =
      data.static.team_isolation_plancher_bas_id
    this.team_isolation_plancher_bas_name =
      data.static.team_isolation_plancher_bas_name
    this.team_isolation_souffle_id = data.static.team_isolation_souffle_id
    this.team_isolation_souffle_name = data.static.team_isolation_souffle_name
    this.team_isolation_deroule_id = data.static.team_isolation_deroule_id
    this.team_isolation_deroule_name = data.static.team_isolation_deroule_name
    this.team_isolation_ite_id = data.static.team_isolation_ite_id
    this.team_name_isolations = data.static.team_name_isolations
    this.team_isolation_ite_name = data.static.team_isolation_ite_name
    this.team_isolation_iti_id = data.static.team_isolation_iti_id
    this.team_isolation_iti_name = data.static.team_isolation_iti_name
    this.team_isolation_sous_rampant_id =
      data.static.team_isolation_sous_rampant_id
    this.team_isolation_sous_rampant_name =
      data.static.team_isolation_sous_rampant_name
    //
    this.acompte_recu = data.static.acompte_recu
    this.project_id = data.static.id
    this.principal_pipeline_id = data.static.principal_pipeline_id
    this.principal_pipeline_name = data.static.principal_pipeline_name
    this.budget_reel = data.static.budget_reel
    this.created_at = data.static.creation_date
    this.comm_ite = data.static.comm_ite
    this.comm_ite_depots_name = data.static.comm_ite_depots_name
    this.comm_ite_first_name = data.static.comm_ite_first_name
    this.comm_ite_last_name = data.static.comm_ite_last_name
    this.comm_ite_full_name = `${
      data.static.comm_ite_first_name ? data.static.comm_ite_first_name : ''
    } ${data.static.comm_ite_first_name ? data.static.comm_ite_last_name : ''}`
    this.comm_ite_team_id = data.static.comm_ite_team_id
    this.comm_ite_team_name = data.static.comm_ite_team_name
    this.comm_regie = data.static.comm_regie
    this.visiteur_first_name = data.static.visiteur_first_name
    this.visiteur_last_name = data.static.visiteur_last_name
    this.visiteur_tech = data.static.visiteur_tech
    this.visiteur_tech_full_name = `${
      data.static.visiteur_first_name ? data.static.visiteur_first_name : ''
    } ${data.static.visiteur_last_name ? data.static.visiteur_last_name : ''}`
    this.comm_sed = data.static.comm_sed
    this.comm_sed_first_name = data.static.comm_sed_first_name
    this.comm_sed_last_name = data.static.comm_sed_last_name
    this.comm_sed_first_full_name = `${
      data.static.comm_sed_first_name ? data.static.comm_sed_first_name : ''
    } ${data.static.comm_sed_last_name ? data.static.comm_sed_last_name : ''}`
    this.comm_sed_team_id = data.static.comm_sed_team_id
    this.comm_sed_team_name = data.static.comm_sed_team_name
    this.comm_terr = data.static.comm_terr
    this.comm_terr_depots_name = data.static.comm_terr_depots_name
    this.comm_terr_first_name = data.static.comm_terr_first_name
    this.comm_terr_last_name = data.static.comm_terr_last_name
    this.comm_terr_full_name = `${
      data.static.comm_terr_first_name ? data.static.comm_terr_first_name : ''
    } ${data.static.comm_terr_last_name ? data.static.comm_terr_last_name : ''}`
    this.comm_terr_team_id = data.static.comm_terr_team_id
    this.comm_terr_team_name = data.static.comm_terr_team_name
    this.confirmation =
      !data.static.confirmation || data.static.confirmation == 0 ? 0 : 1
    this.confirmation_previsite =
      !data.static.confirmation_previsite ||
      data.static.confirmation_previsite == 0
        ? 0
        : 1
    this.contact_full_name = data.static.contact_full_name
    this.contact_id = data.static.contact_id
    this.contact_zipCode = data.static.contact_zipCode
    this.create_date = data.static.creation_date
      ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
          'DD-MM-YYYY'
        )
      : data.static.creation_date
    this.create_time = data.static.creation_date
      ? moment(data.static.creation_date, 'YYYY-MM-DD HH:mm:ss').format(
          'HH:mm:ss'
        )
      : data.static.creation_date
    this.update_date = data.static.modification_date
      ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
          'DD-MM-YYYY'
        )
      : data.static.modification_date
    this.update_time = data.static.modification_date
      ? moment(data.static.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
          'HH:mm:ss'
        )
      : data.static.modification_date
    this.date_debut_formation = data.static.date_debut_formation
    this.date_fin_formation = data.static.date_fin_formation
    this.date_mpr = data.static.date_mpr
    this.date_previsite = data.static.date_previsite
      ? moment(data.static.date_previsite, 'YYYY-MM-DD HH:mm:ss').format(
          'DD-MM-YYYY'
        )
      : data.static.date_previsite
    this.date_travaux = data.static.date_travaux
      ? moment(data.static.date_travaux, 'YYYY-MM-DD HH:mm:ss').format(
          'DD-MM-YYYY'
        )
      : data.static.date_travaux
    this.dossier = !data.static.dossier || data.static.dossier == 0 ? 0 : 1
    this.id = data.static.id
    this.pac = data.static.pac
    this.photo = !data.static.photo || data.static.photo == 0 ? 0 : 1
    this.pmr = data.static.pmr
    this.poseur_depots_name = data.static.poseur_depots_name
    this.poseur_first_name = data.static.poseur_first_name
    this.poseur_full_name = `${
      data.static.poseur_first_name ? data.static.poseur_first_name : ''
    } ${data.static.poseur_last_name ? data.static.poseur_last_name : ''}`
    this.poseur_id = data.static.poseur_id
    this.poseur_last_name = data.static.poseur_last_name
    this.precarity = data.static.precarity
    this.prj_activity_recent = data.static.prj_activity_recent
      ? moment(data.static.prj_activity_recent, 'YYYY-MM-DD HH:mm:ss').format(
          'DD-MM-YYYY'
        )
      : data.static.prj_activity_recent
    this.prj_appel_count = data.static.prj_appel_count
    this.prj_date_closing = ''
    if (data.static.prj_date_closing) {
      this.prj_date_closing = data.static.prj_date_closing
        ? moment(data.static.prj_date_closing, 'YYYY-MM-DD HH:mm:ss').format(
            'DD-MM-YYYY'
          )
        : data.static.prj_date_closing
    }
    this.prj_type_heating = data.static.prj_type_heating
    this.progression = data.static.progression
    this.qlty_manager = data.static.qlty_manager
    this.qlty_manager_first_name = data.static.qlty_manager_first_name
    this.qlty_manager_last_name = data.static.qlty_manager_last_name
    this.qlty_manager_full_name = `${
      data.static.qlty_manager_first_name
        ? data.static.qlty_manager_first_name
        : ''
    } ${
      data.static.qlty_manager_last_name
        ? data.static.qlty_manager_last_name
        : ''
    }`
    this.qlty_manager_team_id = data.static.qlty_manager_team_id
    this.qlty_manager_team_name = data.static.qlty_manager_team_name
    this.solde_recu = data.static.solde_recu
    this.source = data.static.source
    this.stage_name = data.static.stage
    this.stage_id = data.static.stage_id
    this.statut_mpr = data.static.statut_mpr
    this.statut_previsite = data.static.statut_previsite
    this.statut_travaux = data.static.statut_travaux
    this.cumac = data.static.cumac
    this.type_renovation = data.static.type_renovation
    this.type_dash = data.static.type_dash == 1 ? 1 : 0
    this.ecs_dash = data.static.ecs_dash == 1 ? 1 : 0
    this.ro_dash = data.static.ro_dash == 1 ? 1 : 0
    this.rr_dash = data.static.rr_dash == 1 ? 1 : 0
    this.vmc_dash = data.static.vmc_dash == 1 ? 1 : 0
    this.iso_dash = data.static.iso_dash == 1 ? 1 : 0
    this.chauff_dash = data.static.chauff_dash == 1 ? 1 : 0
    this.ambiance_dash = data.static.ambiance_dash == 1 ? 1 : 0
    this.info_vmc_dash = data.static.info_vmc_dash == 1 ? 1 : 0
    this.sub_stage_color = data.static.sub_stage_color
    this.sub_stage_id = data.static.sub_stage_id
    this.statut_dossier_administratif = data.static.statut_dossier_administratif
    this.sub_stage_name = data.static.sub_stage_name
    this.sub_stage_status = data.static.sub_stage_status
    this.surface_deroule = data.static.surface_deroule
    this.surface_deroule_pose = data.static.surface_deroule_pose
    this.surface_iti = data.static.surface_iti
    this.surface_iti_pose = data.static.surface_iti_pose
    this.surface_mur = data.static.surface_mur
    this.surface_mur_pose = data.static.surface_mur_pose
    this.surface_pignon = data.static.surface_pignon
    this.surface_pignon_pose = data.static.surface_pignon_pose
    this.surface_plafond = data.static.surface_plafond
    this.surface_plafond_pose = data.static.surface_plafond_pose
    this.surface_rampant = data.static.surface_rampant
    this.surface_rampant_pose = data.static.surface_rampant_pose
    this.surface_souffle = data.static.surface_souffle
    this.surface_souffle_pose = data.static.surface_souffle_pose
    this.surface_iti = data.static.surface_iti
    this.surface_iti_pose = data.static.surface_iti_pose
    this.surface_vide_sanitaire = data.static.surface_vide_sanitaire
    this.surface_vide_sanitaire_pose = data.static.surface_vide_sanitaire_pose
    this.total = data.static.total
    this.total_pose = data.static.total_pose
    this.type_formation = data.static.type_formation
    this.zone = data.static.zone
    this.lettre_acroi_color = data.static.lettre_acroi_color
  }
  setTimeLine(data) {
    this.timeline = data.map(item => {
      if (item.object == 'ChangeProjet') {
        return History.create(item)
      }
      if (item.object == 'Fichier') {
        return File.create(item)
      }
      if (item.object == 'Comment') {
        return Comment.create(item)
      }
      if (item.object == 'Activity') {
        return Activity.create(item)
      }
    })
  }
}

import Axios from '@/axios'
import domain from '@/environment'

const state = {
  errorStocks: null,
  processingStocks: false,
  processingStocksAplanifier: false,
  stocksPlanifier: [],
  stocksAPlanifier: []
}
const getters = {
  getStockErrorStocks: state => state.errorStocks,
  getStockProcessing: state => state.processingStocks,
  getStockAPlanifierProcessing: state => state.processingStocksAplanifier,
  getStocksPlanifier: state => state.stocksPlanifier,
  getStocksAPlanifier: state => state.stocksAPlanifier
}
const mutations = {
  SET_STOCK_ERROR(state, payload) {
    state.errorStocks = payload
    state.processingStocks = false
    state.processingStocksAplanifier = false
  },
  REMOVE_STOCK_ERROR(state) {
    state.errorStocks = null
  },
  SET_STOCK_PROCESSING(state, payload) {
    state.processingStocks = payload
    state.errorStocks = null
  },
  SET_STOCK_A_PLANFIER_PROCESSING(state, payload) {
    state.processingStocksAplanifier = payload
    state.errorStocks = null
  },
  SET_STOCK_PLANIFIER(state, payload) {
    state.stocksPlanifier = payload
  },
  SET_STOCK_A_PALNIFIER(state, payload) {
    state.stocksAPlanifier = payload
  }
}
const actions = {
  async fetchStocksPlannifier({ commit }, payload) {
    commit('SET_STOCK_PROCESSING', true)
    commit('SET_STOCK_ERROR')
    const params = new URLSearchParams()
    params.append('categorie', payload)

    try {
      const response = await Axios.get(domain + '/api/planifies-stock', {
        params,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_STOCK_PLANIFIER', response.data)
      commit('SET_STOCK_PROCESSING')
    } catch (error) {
      commit('SET_STOCK_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_STOCK_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_STOCK_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchStocksAplannifier({ commit }, payload) {
    commit('SET_STOCK_A_PLANFIER_PROCESSING', true)
    commit('SET_STOCK_ERROR')
    const params = new URLSearchParams()
    params.append('categorie', payload)

    try {
      const response = await Axios.get(domain + '/api/aplanifier-stock', {
        params,
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('SET_STOCK_A_PALNIFIER', response.data)
      commit('SET_STOCK_A_PLANFIER_PROCESSING')
    } catch (error) {
      commit('SET_STOCK_A_PLANFIER_PROCESSING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_STOCK_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_STOCK_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

import Axios from '@/axios'
import domain from '@/environment'
import TemplateEmail from '../../models/crm/TemplateEmail'
import TemplateSms from '../../models/crm/TemplateSms'

const state = {
  emailTemplatesFields: null,
  newMailTemplateLoading: false,
  newTemplateEmailError: null,
  mailTemplateLoading: false,
  allTemplateEmail: [],
  templateEmailLoading: false,
  smsTemplatesFields: null,
  smsTemplateLoading: false,
  allTemplateSms: [],
  templateSmsError: null,
  imageTemplateList: []
}
const getters = {
  getEmailTemplatesFields: state => state.emailTemplatesFields,
  getAllTemplateEmail: state => state.allTemplateEmail,
  getMailTemplateLoading: state => state.mailTemplateLoading,
  getTemplateEmailError: state => state.newTemplateEmailError,
  getNewMailTemplateLoading: state => state.newMailTemplateLoading,
  getTemplateEmailLoading: state => state.templateEmailLoading,
  getSmsTemplatesFields: state => state.smsTemplatesFields,
  getSmsTemplateLoading: state => state.smsTemplateLoading,
  getAllTemplateSms: state => state.allTemplateSms,
  getTemplateSmsError: state => state.templateSmsError,
  getImageTemplateList: state => state.imageTemplateList
}
const mutations = {
  SET_EMAIL_TEMPLATE(state, payload) {
    state.emailTemplatesFields = payload
  },
  ADD_NEW_EMAIL_TEMPLATE(state, payload) {
    state.allTemplateEmail.push(TemplateEmail.create(payload))
  },
  SET_FETCH_EMAIL_TEMPLATE_LOADING(state, payload = false) {
    state.mailTemplateLoading = payload
  },
  SET_ALL_EMAIL_TEMPLATE(state, payload) {
    state.allTemplateEmail = payload.map(item => TemplateEmail.create(item))
  },
  SET_NEW_EMAIL_TEMPLATE_LOADING(state, payload = false) {
    state.newMailTemplateLoading = payload
  },
  SET_TEMPLATE_EMAIL_ERROR(state, payload) {
    state.newTemplateEmailError = payload
  },
  SET_TEMPLATE_EMAIL_LOADING(state, payload = false) {
    state.templateEmailLoading = payload
  },
  UPDATE_EMAIL_TEMPLATE(state, { response, templateEmailToUpdate }) {
    templateEmailToUpdate.update(response)
  },
  DELETE_TEMPLATE(state, payload) {
    state.allTemplateEmail = state.allTemplateEmail.filter(TemplateEmail => {
      return TemplateEmail.id != payload.id
    })
  },
  SET_SMS_TEMPLATE(state, payload) {
    state.smsTemplatesFields = payload
  },
  SET_FETCH_SMS_TEMPLATE_LOADING(state, payload = false) {
    state.smsTemplateLoading = payload
  },
  SET_ALL_SMS_TEMPLATE(state, payload) {
    state.allTemplateSms = payload.map(item => TemplateSms.create(item))
  },
  SET_TEMPLATE_SMS_ERROR(state, payload) {
    state.templateSmsError = payload
  },
  ADD_NEW_SMS_TEMPLATE(state, payload) {
    state.allTemplateSms.push(TemplateSms.create(payload))
  },
  DELETE_TEMPLATE_SMS(state, payload) {
    state.allTemplateSms = state.allTemplateSms.filter(TemplateSms => {
      return TemplateSms.id != payload.id
    })
  },
  UPDATE_SMS_TEMPLATE(state, { response, templateSmsToUpdate }) {
    templateSmsToUpdate.update(response)
  },
  ADD_NEW_IMAGE(state, payload) {
    state.imageTemplateList.push(payload)
  },
  SET_LIST_IMAGE(state, payload) {
    state.imageTemplateList = payload
  }
}
const actions = {
  async fetchEmailTemplatesFields({ commit }) {
    try {
      const response = await Axios.get(
        `${domain}/api/email_templates_specific_fields`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_EMAIL_TEMPLATE', response.data)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_FIELD_EMAIL_ERROR', error.response.data.error.messages)
      } else {
        commit('FIELD_EMAIL', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async newEmailTemplate({ commit }, payload) {
    commit('SET_TEMPLATE_EMAIL_ERROR', null)
    commit('SET_NEW_EMAIL_TEMPLATE_LOADING', true)
    try {
      const response = await Axios.post(
        `${domain}/api/email_templates`,

        payload.template,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('ADD_NEW_EMAIL_TEMPLATE', response.data.data)
      commit('SET_NEW_EMAIL_TEMPLATE_LOADING')
      return true
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_EMAIL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_EMAIL_ERROR', ['Une erreur est survenue'])
      }
      commit('SET_NEW_EMAIL_TEMPLATE_LOADING')

      return false
    }
  },

  async fetchAllEmailTemplates({ commit }, payload) {
    commit('SET_FETCH_EMAIL_TEMPLATE_LOADING', true)
    let route = '/api/email_templates'
    if (payload && payload.id_template) {
      route = '/api/email_templates/' + payload.id_template
    }
    const params = {}
    if (payload && payload.sort_by) {
      params.sort_by = payload.sort_by
    }
    if (payload && payload.sort_by_desc) {
      params.sort_by_desc = payload.sort_by_desc
    }
    if (payload && payload.full_name) {
      params.full_name = payload.full_name
    }
    try {
      const response = await Axios.get(`${domain}${route}`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })

      commit('SET_ALL_EMAIL_TEMPLATE', response.data.data)
      commit('SET_FETCH_EMAIL_TEMPLATE_LOADING')
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_SMS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_SMS_ERROR', ['Une erreur est survenue'])
      }
      commit('SET_FETCH_EMAIL_TEMPLATE_LOADING')

      return false
    }
    return true
  },

  async updateEmailTemplate({ commit }, { TemplateEmailToUpdate }) {
    commit('SET_TEMPLATE_EMAIL_ERROR', null)
    commit('SET_TEMPLATE_EMAIL_LOADING', true)
    try {
      const response = await Axios.put(
        `${domain}/api/email_templates/` + TemplateEmailToUpdate.id,
        {
          title: TemplateEmailToUpdate.title,
          specific_field: TemplateEmailToUpdate.specific_field,
          subject: TemplateEmailToUpdate.subject,
          category_files:
            TemplateEmailToUpdate.category_files &&
            TemplateEmailToUpdate.category_files.length
              ? TemplateEmailToUpdate.category_files.map(item => item.id)
              : [],
          body: TemplateEmailToUpdate.body,
          email: TemplateEmailToUpdate.email
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_EMAIL_TEMPLATE', {
        response: response.data.data,
        templateEmailToUpdate: TemplateEmailToUpdate
      })
      commit('SET_TEMPLATE_EMAIL_LOADING')
    } catch (error) {
      commit('SET_TEMPLATE_EMAIL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_EMAIL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_EMAIL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async deleteTemplateEmail({ commit }, payload) {
    commit('SET_TEMPLATE_EMAIL_LOADING', true)
    commit('SET_TEMPLATE_EMAIL_ERROR')
    try {
      await Axios.delete(
        domain + '/api/email_templates/' + payload.template.id,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('DELETE_TEMPLATE', payload.template)
      commit('SET_TEMPLATE_EMAIL_LOADING')
    } catch (error) {
      commit('SET_TEMPLATE_EMAIL_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_EMAIL_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_EMAIL_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchSmsTemplatesFields({ commit }) {
    try {
      const response = await Axios.get(
        `${domain}/api/sms_templates_specific_fields`,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_SMS_TEMPLATE', response.data)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_SMS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_SMS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async fetchAllSmsTemplates({ commit }, payload) {
    commit('SET_FETCH_SMS_TEMPLATE_LOADING', true)
    try {
      const params = {}
      if (payload && payload.sort_by) {
        params.sort_by = payload.sort_by
      }
      if (payload && payload.sort_by_desc) {
        params.sort_by_desc = payload.sort_by_desc
      }
      if (payload && payload.full_name) {
        params.full_name = payload.full_name
      }
      const response = await Axios.get(`${domain}/api/sms_templates`, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        },
        params
      })

      commit('SET_ALL_SMS_TEMPLATE', response.data.data)
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_SMS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_SMS_ERROR', ['Une erreur est survenue'])
      }
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')

      return false
    }
    return true
  },
  async newSmsTemplate({ commit }, payload) {
    commit('SET_TEMPLATE_SMS_ERROR', null)
    commit('SET_FETCH_SMS_TEMPLATE_LOADING', true)
    try {
      const response = await Axios.post(
        `${domain}/api/sms_templates`,

        payload.template,
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )

      commit('ADD_NEW_SMS_TEMPLATE', response.data.data)
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')
      return true
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_SMS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_SMS_ERROR', ['Une erreur est survenue'])
      }
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')
      return false
    }
  },
  async deleteTemplateSms({ commit }, payload) {
    commit('SET_FETCH_SMS_TEMPLATE_LOADING', true)
    commit('SET_TEMPLATE_SMS_ERROR')
    try {
      await Axios.delete(domain + '/api/sms_templates/' + payload.template.id, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`
        }
      })
      commit('DELETE_TEMPLATE_SMS', payload.template)
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')
    } catch (error) {
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_SMS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_SMS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  async updateSmsTemplate({ commit }, { TemplateSmsToUpdate }) {
    commit('SET_TEMPLATE_SMS_ERROR', null)
    commit('SET_FETCH_SMS_TEMPLATE_LOADING', true)
    try {
      const response = await Axios.put(
        `${domain}/api/sms_templates/` + TemplateSmsToUpdate.id,
        {
          message: TemplateSmsToUpdate.message,
          specific_field: TemplateSmsToUpdate.specific_field,
          title: TemplateSmsToUpdate.title
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('UPDATE_SMS_TEMPLATE', {
        response: response.data.data,
        templateSmsToUpdate: TemplateSmsToUpdate
      })
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')
    } catch (error) {
      commit('SET_FETCH_SMS_TEMPLATE_LOADING')
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_SMS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_SMS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },

  async setLisImage({ commit }, param) {
    return new Promise((resolve, reject) => {
      Axios.post(`${domain}/api/upload-image`, param, {
        headers: {
          Authorization: `Bearer ${this.state.currentUser.token}`,
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(response => {
          commit('ADD_NEW_IMAGE', response.data.data)
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  async getAllImageTemplate({ commit }) {
    try {
      const response = await Axios.get(
        `${domain}/api/images`,

        {
          headers: {
            Authorization: `Bearer ${this.state.currentUser.token}`
          }
        }
      )
      commit('SET_LIST_IMAGE', response.data.data)
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.messages
      ) {
        commit('SET_TEMPLATE_SMS_ERROR', error.response.data.error.messages)
      } else {
        commit('SET_TEMPLATE_SMS_ERROR', ['Une erreur est survenue'])
      }
      return false
    }
    return true
  },
  resetErrorEmailTemplate({ commit }) {
    commit('SET_TEMPLATE_EMAIL_ERROR', null)
  },
  resetErrorSmsTemplate({ commit }) {
    commit('SET_TEMPLATE_SMS_ERROR', null)
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

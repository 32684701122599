import Entity from '../Entity'

export default class Notifications extends Entity {
  constructor(data) {
    super(data.id)
    this.activity_date_start = data.activity_date_start
    this.activity_id = data.activity_id
    this.activity_name = data.activity_name
    this.activity_type = data.activity_type
    this.contenu = data.contenu
    this.depuis = data.depuis
    this.icon = data.icon
    this.projet_id = data.projet_id
    this.activity_me_prevenir = data.activity_me_prevenir
    this.time = data.time
    this.to_id = data.to_id
    this.seen = data.seen
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('Notifications::create: "data" is undefined')
    }
    return new Notifications(data)
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Libraries components
import './imports/bootstrap'
import './imports/font-awesome'
//International Telephone
import VueTelInput from 'vue-tel-input'
Vue.use(VueTelInput)
// Application global style
import '@/assets/sass/custom.scss'
import '@/assets/themify-icons/themify-icons.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import VTooltip from 'v-tooltip'
import vueDebounce from 'vue-debounce'
// vue-multiselect
Vue.component('multiselect', () =>
  import(/* webpackChunkName: "vue-multiselect" */ 'vue-multiselect')
)
Vue.use(VTooltip)
Vue.use(vueDebounce)
Vue.config.productionTip = false
Vue.config.devtools = true
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Lightbox from 'vue-easy-lightbox'
Vue.use(Lightbox)

import PortalVue from 'portal-vue'
Vue.use(PortalVue)

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/fr'
Vue.use(DatePicker)
//
import { ColorPicker, ColorPanel } from 'one-colorpicker'
Vue.use(ColorPanel)
Vue.use(ColorPicker)

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    var vm = vnode.context
    var callback = binding.value

    el.clickOutsideEvent = function(event) {
      if (!(el == event.target || el.contains(event.target))) {
        return callback.call(vm, event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

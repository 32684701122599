import Entity from '../Entity'
import * as moment from 'moment'
moment.locale('fr')
export default class HistoryUsers extends Entity {
  constructor(data) {
    super(data.id)
    this.action = data.action
    this.modification_date = data.modification_date
      ? moment(data.modification_date, 'YYYY-MM-DD HH:mm:ss').format(
          'DD-MM-YYYY HH:mm'
        )
      : data.modification_date
    this.statut = 'Utilisateur'
    this.user = data.user
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('HistoryUsers::create: "data" is undefined')
    }
    return new HistoryUsers(data)
  }
}

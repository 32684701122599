import Entity from '../../Entity'

export default class SubCategorie extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.subtype = data.subtype
    this.order = data.order
    this.listSubType = []
    if (
      data.subtype &&
      data.subtype.data &&
      data.subtype.data.type &&
      data.subtype.data.type.data &&
      data.subtype.data.type.data.id
    ) {
      this.selectedType = data.subtype.data.type.data.id
    }
    if (!data.subtype) {
      this.subtype = { data: null }
      this.selectedType = null
    }
    this.fields = []
  }

  /**
   * @param { id, name, subtype,order,subtype } data
   */
  static create(data) {
    if (!data) {
      throw new Error('SubCategorie::create: "data" is undefined')
    }
    return new SubCategorie(data)
  }

  update(data) {
    this.name = data.name
    // this.subtype = data.subtype
    this.order = data.order
  }
}

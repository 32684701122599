import Entity from '../../Entity'
export default class CategoriePJ extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.order = data.order
    this.types = data.types
    this.subCategorie = []
    if (data.sousCategories) {
      this.subCategorie = data.sousCategories
    }
  }

  /**
   * @param { id, name, subCategorie } data
   */
  static create(data) {
    if (!data) {
      throw new Error('CategoriePJ::create: "data" is undefined')
    }
    return new CategoriePJ(data)
  }

  update(data) {
    this.name = data.name
    this.order = data.order
    this.types = data.types
  }
}

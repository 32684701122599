import Entity from '../Entity'

export default class TemplateEmail extends Entity {
  constructor(data) {
    super(data.id)
    this.body = data.body
    this.specific_field = data.specific_field
    this.subject = data.subject
    this.category_files =
      data.category_files &&
      data.category_files.data &&
      data.category_files.data.length
        ? data.category_files.data
        : []
    this.title = data.title
    this.email = data.email
    this.created_at = data.created_at
    this.created_by = data.created_by
    this.visible = false
  }
  /**
   * @param {*} data
   */
  static create(data) {
    if (!data) {
      throw new Error('TemplateEmail::create: "data" is undefined')
    }
    return new TemplateEmail(data)
  }
  update(data) {
    this.body = data.body
    this.specific_field = data.specific_field
    this.subject = data.subject
    this.category_files =
      data.category_files &&
      data.category_files.data &&
      data.category_files.data.length
        ? data.category_files.data
        : []
    this.title = data.title
    this.email = data.email
  }
}

import Entity from '../../Entity'

export default class CategorieFile extends Entity {
  constructor(data) {
    super(data.id)
    this.name = data.name
    this.order = data.order
  }
  /**
   * @param { id, name,order } data
   */
  static create(data) {
    if (!data) {
      throw new Error('CategorieFile::create: "data" is undefined')
    }
    return new CategorieFile(data)
  }
}
